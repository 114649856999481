export enum ServiceOrderActiveStatus {
  searching = 'Searching',
  examining = 'Examining',
  qualityReview = 'Quality Review',
  actionRequired = 'Action Required',
}

export enum ServiceOrderCompleteStatus {
  orderComplete = 'Order Complete',
}
