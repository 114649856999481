import { createStyles, List, makeStyles, Theme, Typography } from '@agentnet/components';
import { ArrowBack } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import useBaseRoute from '../../hooks/useBaseRoute';
import { Article, Topic } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    topicHeading: {
      alignSelf: 'stretch',
      '&:hover': {
        opacity: '0.9',
      },
    },
    h2: {
      marginBottom: theme.spacing(3),
      fontSize: '32px',
      fontWeight: 700,
    },
    li: {
      display: 'flex',
      paddingBottom: '0px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid #e5e5e5',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    body: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: '16px',
    },
    a: { textDecoration: 'none' },
    nav: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    mb2: {
      marginBottom: theme.spacing(2),
      justifyContent: 'flex-start',
    },
  }),
);
function TopicContentBody({ topic }: { topic: Topic }) {
  const classes = useStyles();
  const { url } = useBaseRoute();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <AgentNetButton
        className={classes.mb2}
        variant="text"
        size="medium"
        color="primary"
        startIcon={<ArrowBack htmlColor="primary" />}
        onClick={() => history.goBack()}
      >
        Back
      </AgentNetButton>
      <Typography className={classes.h2}>{topic?.topicName}</Typography>
      <List component={'nav'} className={classes.nav}>
        {topic?.linkedFrom?.kbArticleCollection?.items?.map((article: Article) => (
          <li key={article.articleTitle} data-testid={`${article.articleTitle}-list-item`} className={classes.li}>
            <Link key={article.articleTitle} to={`${url}/${article.slug}`} className={classes.a}>
              <Typography variant="h2" color="primary" className={classes.topicHeading}>
                {article.articleTitle}
              </Typography>
            </Link>
            <Typography variant="body1" color="textPrimary" className={classes.body}>
              {article.articleSummary}
            </Typography>
          </li>
        ))}
      </List>
    </div>
  );
}

export { TopicContentBody };
