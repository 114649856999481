import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Dialog, DialogContent, DialogTitle, IconButton, Modal, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Close, GetApp as DownloadIcon, Print } from '@material-ui/icons';
import { useEffect, useState } from 'react';
interface BackTitleDocPreviewProps {
  onClose: (value: any) => void;
  open: boolean;
  documentData: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    margin: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1, 4),
    borderTop: '1px solid #C4C4C4',
  },
  f18: {
    fontSize: pxToRem(18),
  },
  footerText: {
    textAlign: 'right',
  },
  flex: {
    display: 'flex',
  },
  txtButton: {
    padding: theme.spacing(2, 4),
    fontSize: pxToRem(14),
    display: 'flex',
    gap: '5px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  dialogWrap: {
    maxWidth: 900,
    minWidth: '50vw',
    height: '80vh',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    borderTop: '1px solid #C4C4C4',
    padding: 0,
  },
}));

const DocumentPreviewModal = ({ onClose, open, documentData }: BackTitleDocPreviewProps) => {
  const handlePrint = () => {
    const iframe = document.getElementById('back-title-pdf-iframe') as any;
    iframe?.contentWindow.print();
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = docUrl;
    link.setAttribute('download', `document.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [docUrl, setDocUrl] = useState<string>('');
  useEffect(() => {
    const byteCharacters = atob(documentData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    setDocUrl(fileURL);
  }, [documentData]);
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Dialog
        onClose={onClose}
        aria-labelledby="about-app"
        open={open}
        maxWidth={'xs'}
        PaperProps={{ className: classes.dialogWrap }}
      >
        <DialogTitle>
          <div className={classes.dialogTitle}>
            <Typography variant="h3" color="textPrimary">
              Back Title Document Preview
            </Typography>
            <IconButton className={classes.dialogClose} onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            <iframe
              src={`${docUrl}#toolbar=0`}
              id="back-title-pdf-iframe"
              title="PDF Viewer"
              width="100%"
              height="100%"
              data-testid="documents-view-open-document-pdf-iframe"
            />
          </div>
        </DialogContent>
        <div className={classes.footer}>
          <Typography variant="body2" className={classes.footerText}>
            <span className={classes.flex}>
              <a className={classes.txtButton} onClick={handleDownload}>
                <DownloadIcon className={classes.f18} color="primary" data-testid="back-title-starter-download" />
                Download
              </a>
              <a className={classes.txtButton} onClick={handlePrint}>
                <Print className={classes.f18} color="primary" data-testid="back-title-starter-print" />
                Print
              </a>
            </span>
          </Typography>
        </div>
      </Dialog>
    </Modal>
  );
};
export default DocumentPreviewModal;
