import { useState } from 'react';

function useSessionStorage(key: string) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  });

  const setValue = (value: object | null) => {
    sessionStorage.setItem(key, JSON.stringify(value));
    setStoredValue(value);
  };

  return [storedValue, setValue];
}

export default useSessionStorage;
