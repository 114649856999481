import { FormatDateUtils } from '@agentnet/utils';
import { createStyles, makeStyles, SvgIconProps, Theme, Typography } from '@material-ui/core';
import {
  AddLocationOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  EditOutlined,
  PersonAddOutlined,
  PersonOutline,
} from '@material-ui/icons';
import clsx from 'clsx';
import { ComponentType, FC } from 'react';
import { AuditLog } from '../../../../../../api/dashboards/service-orders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(3),

      '&:last-of-type': {
        '& .icon': {
          '&:after': {
            display: 'none',
          },
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 32,
      display: 'flex',
      flex: '0 0 32px',
      height: 32,
      justifyContent: 'center',
      position: 'relative',
      width: 32,
      zIndex: 1,

      '&:after': {
        backgroundColor: theme.palette.divider,
        content: '""',
        height: 'calc(100% + 24px)',
        position: 'absolute',
        top: 31,
        width: 1,
        zIndex: 0,
      },
    },
  }),
);

type AudiLogItemProps = {
  log: AuditLog;
};

type IconMapperComponentProps = {
  action: string;
  color?: SvgIconProps['color'];
  fontSize?: SvgIconProps['fontSize'];
};

const iconMapper: { [key: string]: ComponentType<SvgIconProps> } = {
  'Create Order': CheckOutlined,
  'Create order': CheckOutlined,
  'Upload Document': CloudUploadOutlined,
  Update: EditOutlined,
  'Property Add': AddLocationOutlined,
  'Property Delete': AddLocationOutlined,
  'Property Update': EditOutlined,
  'Loan Amount Change': EditOutlined,
  'Sale Amount Change': EditOutlined,
  'Order Update': EditOutlined,
  'Order Status': EditOutlined,
  'Party Add': PersonAddOutlined,
  'Party Delete': PersonOutline,
  'Party Update': EditOutlined,
  'File Status': EditOutlined,
};

const IconMapperComponent: FC<IconMapperComponentProps> = ({ action, color, fontSize }: IconMapperComponentProps) => {
  const IconComponent = iconMapper[action];
  return IconComponent ? <IconComponent color={color} fontSize={fontSize} /> : null;
};

export function AuditLogItem({ log }: AudiLogItemProps) {
  const classes = useStyles();
  const formattedDateTime = FormatDateUtils.MonthDateYearTime(log.CreatedDate);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.icon, 'icon')}>
        <IconMapperComponent action={log.Action} color="secondary" fontSize="large" />
      </div>

      <div className={classes.content}>
        <Typography color="textSecondary" variant="body2">
          {log.CreatedByUserName ? <>{log.CreatedByUserName} &bull;</> : null} {formattedDateTime}
        </Typography>
        <Typography color="textPrimary" variant="body1" dangerouslySetInnerHTML={{ __html: log.Message }} />
      </div>
    </div>
  );
}
