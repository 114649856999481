import { AgentNetButton, pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, Hidden, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, ExpandLess, ExpandMore, Remove } from '@material-ui/icons';
import clsx from 'clsx';
import { FileNumber } from 'features/files/files-summary/files-list/filesListConfigs';
import { formatDate } from 'features/Remittance/reportsListConfig';
import React, { useEffect, useState } from 'react';
import PageHeader from '../headers/PageHeader';
import OverflowTip from '../utility/OverflowTip';

interface ItemsProps {
  name?: string;
  credits?: number;
  amountDue?: number;
  additionalItems?: ItemsProps[];
  gross?: number;
  net?: number;
  serviceId?: number;
  sequenceNumber?: number;
}

interface InvoiceProps {
  invoiceNumber: string;
  fileOrderId: number;
  fileId: number;
  fileNumber: string;
  underwriter: string;
  property?: {
    address1?: string;
    address2?: string;
    city?: string;
    stateOrProvince?: string;
    postalCode?: string;
  };
  gross: number;
  net: number;
  credits: number;
  amountDue: number;
  items?: Array<ItemsProps>;
}

interface OrderProps {
  orderNumber: string;
  underwriter?: string;
  orderDate: string;
  isOrderInvoiced?: boolean;
  invoices?: Array<InvoiceProps>;
  nested?: boolean;
  isTitleRequired?: boolean;
  modalView?: boolean;
  showInvoiceNo?: boolean;
  isClosedStatus?: boolean;
  measure?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InvoiceSummaryTable: {
      width: '100%',
      paddingBottom: theme.spacing(3),
    },
    InvoiceSummaryTableHeader: {
      backgroundColor: '#F2F5F6',
      borderTop: `2px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontWeight: 600,
      fontSize: pxToRem(12),
      padding: theme.spacing(1.25),
    },
    InvoiceSummaryTableList: {},
    tableRow: {
      boxShadow: 'none',
      width: '100%',
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    tableRowGrid: {
      alignItems: 'center',
    },
    tableRowSummary: {
      minHeight: 'auto',
      height: 40,
      paddingLeft: 0,
      paddingRight: '0',
      '&.Mui-expanded': {
        minHeight: 'auto',
        height: 40,
        backgroundColor: theme.palette.primary.light, //#D0E3F5
      },
      '& .MuiAccordionSummary-content': {
        height: '100%',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
        height: '100%',
      },
    },
    tableRowDetails: {
      backgroundColor: theme.palette.brand.sky25, //#F6FBFD
      padding: 0,
    },
    tableRowDetailsItem: {
      borderTop: '1px solid #E0E0E0',
    },
    subRowP: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    currencyCol: {
      position: 'relative',
      textAlign: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '-5px 50%',
      padding: pxToRem(10, 20, 10, 10),
    },
    tableCellRight: {
      textAlign: 'right',
    },
    indented: {
      paddingLeft: theme.spacing(3),
    },
    indented2: {
      paddingLeft: theme.spacing(5),
    },
    level1: {
      paddingLeft: `calc(${pxToRem(20)} + ${pxToRem(12)})`,
    },
    level2: {
      paddingLeft: `calc(${pxToRem(20)} + ${pxToRem(24)})`,
    },
    level3: {
      paddingLeft: `calc(${pxToRem(20)} + ${pxToRem(36)})`,
    },
    totalsRow: {
      borderTop: `2px solid ${theme.palette.divider}`,
    },
    expanderCol: {
      width: '32px',
      padding: '6px',
      textAlign: 'center',
      backgroundColor: 'white',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRight: '1px solid #e0e0e0',
    },
    expanderColIcon: {
      transform: 'rotate(-90deg)',
    },
    expandedColIcon: {
      transform: 'rotate(180deg)',
    },
    expanderColHeader: {
      width: '20px',
    },
    expanderColEmpty: {
      width: '32px',
    },
    fileNumberCell: {
      paddingLeft: pxToRem(12),
    },
    amountDueCell: {
      textWrap: 'nowrap',
    },
    totalsCell: {
      padding: pxToRem(10, 20, 10, 10),
      '&:before': {
        fontWeight: 700,
      },
    },
    lastCol: {
      margin: '0 0 0 auto',
    },
    lastTotalCol: {
      margin: `0 ${pxToRem(12)} 0 auto`,
    },
    marginColSubRow: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.between('sm', 'md')]: {
        marginRight: `${theme.spacing(3)}px`,
      },
    },
    marginCol: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    weight700: {
      fontWeight: 700,
    },
    sequenceItem: {
      flexDirection: 'column',
    },
    textRight: {
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(1),
      },
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const InvoiceSummaryTable = ({
  orderDate,
  orderNumber,
  invoices = [],
  underwriter,
  nested,
  isTitleRequired = true,
  modalView,
  showInvoiceNo = false,
  isClosedStatus = false,
  measure,
}: OrderProps) => {
  const classes = useStyles();
  const [grandTotal, setGrandTotal] = useState({
    gross: 0,
    net: 0,
    credits: 0,
    amountDue: 0,
  });

  const [hasInvoiceNo, setHasInvoiceNo] = useState<boolean | undefined>(undefined);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  useEffect(() => {
    setGrandTotal(calculateTotals());
    setHasInvoiceNo(invoices?.some((inv) => inv.invoiceNumber !== undefined));
  }, [invoices]);

  const calculateTotals = () => {
    let grossTotal = 0;
    let netTotal = 0;
    let creditTotal = 0;
    let amountDue = 0;

    invoices &&
      invoices.forEach((prod) => {
        prod.gross && (grossTotal += prod.gross);
        prod.net && (netTotal += prod.net);
        prod.credits && (creditTotal += prod.credits);
        prod.amountDue && (amountDue += prod.amountDue);
      });

    return { gross: grossTotal, net: netTotal, credits: creditTotal, amountDue: amountDue };
  };

  const dollarize = (str: number, icon?: boolean) => {
    const zero = 0;
    if (str === 0 || str === undefined) return zero.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return str.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handleIconClick = (event: React.MouseEvent, index: number) => {
    event.stopPropagation();
    setExpandedItems((prev) => (prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]));
  };

  const handleExpandAll = () => {
    setExpandedItems(invoices.map((_, index) => index));
  };

  const handleCollapseAll = () => {
    setExpandedItems([]);
  };

  useEffect(() => {
    if (measure) {
      measure();
    }
  }, [expandedItems, measure]);

  const AccordionRow = ({ data, index }: any) => {
    const SubRow = ({ e, children, level, className }: any) => (
      <>
        {
          /* Note the children view (line 279 - 368) is for nested invoices only, which are currently not supported */
          children ? (
            <Grid container className={clsx(classes.tableRowDetailsItem, 'subRowNested')}>
              <Grid item className={classes.expanderColEmpty}></Grid>
              <Grid item md={modalView ? 9 : 6} lg={modalView ? 9 : 6} className={classes.subRowP}>
                <OverflowTip backgroundColor="#F6FBFD">
                  <Typography
                    variant="body2"
                    className={clsx(classes.indented, {
                      [classes.level1]: level && level === 0,
                      [classes.level2]: level && level === 1,
                      [classes.level3]: level && level === 2,
                    })}
                  >
                    {`${e.name} - ${e.type}`}
                  </Typography>
                </OverflowTip>
              </Grid>
              {!modalView ? (
                <>
                  <Hidden mdDown>
                    <Grid
                      item
                      sm={modalView ? 2 : 1}
                      className={clsx(classes.subRowP, {
                        [classes.currencyCol]: e.gross,
                      })}
                    >
                      <Typography variant="body2">{dollarize(e.gross)}</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid
                      item
                      sm={modalView ? 2 : 1}
                      className={clsx(classes.subRowP, {
                        [classes.currencyCol]: e.net,
                      })}
                    >
                      <Typography variant="body2">{dollarize(e.net)}</Typography>
                    </Grid>
                    {!modalView && (
                      <Grid
                        item
                        sm={1}
                        className={clsx(classes.subRowP, {
                          [classes.currencyCol]: e.credits,
                        })}
                      >
                        <Typography variant="body2">{e.credits ? `(${dollarize(e.credits)})` : ``}</Typography>
                      </Grid>
                    )}
                  </Hidden>
                </>
              ) : (
                <>
                  <Grid
                    item
                    sm={2}
                    className={clsx(classes.subRowP, {
                      [classes.currencyCol]: e.gross,
                    })}
                  >
                    <Typography variant="body2">{dollarize(e.gross)}</Typography>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    className={clsx(classes.subRowP, {
                      [classes.currencyCol]: e.net,
                    })}
                  >
                    <Typography variant="body2">{dollarize(e.net)}</Typography>
                  </Grid>
                </>
              )}
              {!modalView && (
                <Grid
                  item
                  sm={1}
                  className={clsx(classes.subRowP, {
                    [classes.currencyCol]: e.amountDue,
                  })}
                >
                  <Typography variant="body2">{dollarize(e.amountDue)}</Typography>
                </Grid>
              )}
              {children}
            </Grid>
          ) : (
            <Grid container className={clsx(classes.tableRowDetailsItem, className, 'subRow')}>
              <Grid item className={classes.expanderColEmpty}></Grid>
              <Grid
                item
                sm={modalView ? 7 : 8}
                md={modalView ? 7 : 5}
                lg={modalView ? 7 : 3}
                //lg={modalView ? 9 : 7}
                className={clsx(classes.subRowP, classes.marginCol, { [classes.marginColSubRow]: !modalView })}
              >
                <OverflowTip backgroundColor="#F6FBFD">
                  <Typography variant="body2" className={clsx(classes.indented)}>
                    {`${e.name}${e.type ? ' - ' + e.type : ''}`}
                  </Typography>
                </OverflowTip>
              </Grid>
              {!modalView ? (
                <>
                  <Hidden mdDown>
                    <Grid
                      item
                      sm={2}
                      className={clsx(classes.subRowP, {
                        [classes.currencyCol]: e.gross || e.gross === 0,
                      })}
                    >
                      <Typography variant="body2">{dollarize(e.gross)}</Typography>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid
                      item
                      sm={2}
                      className={clsx(classes.subRowP, {
                        [classes.currencyCol]: e.net || e.net === 0,
                      })}
                    >
                      <Typography variant="body2">{dollarize(e.net)}</Typography>
                    </Grid>
                    {!modalView && (
                      <Grid
                        item
                        sm={2}
                        className={clsx(classes.subRowP, {
                          [classes.currencyCol]: e.credits || e.credits === 0,
                        })}
                      >
                        <Typography variant="body2">{e.credits ? `(${dollarize(e.credits)})` : ``}</Typography>
                      </Grid>
                    )}
                  </Hidden>
                  <Grid
                    item
                    sm={2}
                    //xl={1}
                    className={clsx(classes.subRowP, {
                      [classes.currencyCol]: e.amountDue || e.amountDue === 0,
                    })}
                  >
                    <Typography variant="body2"></Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    sm={2}
                    className={clsx(classes.subRowP, {
                      [classes.currencyCol]: e.gross || e.gross === 0,
                    })}
                  >
                    <Typography variant="body2">{dollarize(e.gross)}</Typography>
                  </Grid>

                  <Grid
                    item
                    sm={2}
                    className={clsx(classes.subRowP, {
                      [classes.currencyCol]: e.net || e.net === 0,
                    })}
                  >
                    <Typography variant="body2">{dollarize(e.net)}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )
        }
      </>
    );

    const generateAddress = (property: any) => {
      if (!property) {
        return '';
      }
      return `${property.address1 ? property.address1 : ''}${property.address2 ? ' ' + property.address2 : ''}, ${
        property.city ? property.city + ', ' : ''
      }${property.stateOrProvince ? property.stateOrProvince : ''} ${property.postalCode ? property.postalCode : ''}`;
    };
    const isExpanded = expandedItems.includes(index);
    return (
      <Accordion key={data.id} className={classes.tableRow} square expanded={isExpanded}>
        <AccordionSummary
          onClick={(e) => e.stopPropagation()}
          className={classes.tableRowSummary}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Grid container key={data.id} className={clsx(classes.tableRowGrid, 'parentRow')}>
            <Grid item className={classes.expanderCol}>
              {!isExpanded ? (
                <ExpandMore
                  fontSize="large"
                  htmlColor="#757575"
                  className={classes.expanderColIcon}
                  data-testid={`PaySumOrder${orderNumber}File${data.fileNumber}Expand`}
                  onClick={(event) => handleIconClick(event, index)}
                />
              ) : (
                <ExpandLess
                  fontSize="large"
                  htmlColor="#757575"
                  className={classes.expandedColIcon}
                  data-testid={`PaySumOrder${orderNumber}File${data.fileNumber}Close`}
                  onClick={(event) => handleIconClick(event, index)}
                />
              )}
            </Grid>
            {showInvoiceNo && data.invoiceNumber ? (
              <>
                <Grid
                  item
                  sm={modalView ? 2 : 2}
                  md={modalView ? 2 : 2}
                  lg={modalView ? 3 : 1}
                  xl={modalView ? 3 : 1}
                  className={classes.marginCol}
                >
                  <OverflowTip backgroundColor={isExpanded ? '#D0E3F5' : undefined}>
                    <Typography variant="body2" className={clsx(classes.fileNumberCell)}>
                      <FileNumber
                        id={data.fileId}
                        fileNumber={data.fileNumber}
                        hideNavigation={!data.fileId}
                        data={{ dataTestId: modalView ? 'Order' : 'Payment' }}
                      />
                    </Typography>
                  </OverflowTip>
                </Grid>
                <Hidden mdDown>
                  <Grid item sm={1} md={2} className={classes.marginCol}>
                    <OverflowTip backgroundColor={isExpanded ? '#D0E3F5' : undefined}>
                      <Typography variant="body2">{data.invoiceNumber ?? 'Pending'}</Typography>
                    </OverflowTip>
                  </Grid>
                </Hidden>
              </>
            ) : (
              <>
                <Grid
                  item
                  sm={modalView ? 2 : 2}
                  md={modalView ? 2 : 3}
                  lg={modalView ? 2 : 3}
                  xl={1}
                  className={classes.marginCol}
                >
                  <OverflowTip backgroundColor={isExpanded ? '#D0E3F5' : undefined}>
                    <Typography variant="body2" className={clsx(classes.fileNumberCell)}>
                      <FileNumber
                        id={data.fileId}
                        fileNumber={data.fileNumber}
                        hideNavigation={!data.fileId}
                        data={{ dataTestId: modalView ? 'Order' : 'Payment' }}
                      />
                    </Typography>
                  </OverflowTip>
                </Grid>
              </>
            )}
            {!modalView ? (
              <>
                <Hidden lgDown>
                  <Grid
                    item
                    lg={showInvoiceNo ? 2 : modalView ? 3 : 2}
                    xl={showInvoiceNo && data.invoiceNumber ? 1 : 2}
                  >
                    <OverflowTip backgroundColor={isExpanded ? '#D0E3F5' : undefined}>
                      <Typography variant="body2">{generateAddress(data.property)}</Typography>
                    </OverflowTip>
                  </Grid>
                </Hidden>
                <Hidden mdDown>
                  <Grid item md={2} lg={2} xl={2} className={classes.currencyCol}>
                    <Typography variant="body2">{dollarize(data.gross)}</Typography>
                  </Grid>
                </Hidden>

                <Grid item md={2} lg={2} xl={2} className={classes.currencyCol}>
                  <Typography variant="body2">{dollarize(data.net)}</Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item md={2} lg={2} xl={2} className={classes.currencyCol}>
                    <Typography variant="body2">{data.credits ? `(${dollarize(data.credits)})` : `($0.00)`}</Typography>
                  </Grid>
                </Hidden>
                <Grid item md={2} lg={2} xl={2} className={clsx(classes.currencyCol)}>
                  <Typography variant="body2">{dollarize(data.amountDue)}</Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  lg={showInvoiceNo ? 2 : modalView ? 3 : 3}
                  md={showInvoiceNo ? 2 : modalView ? 5 : 3}
                  sm={showInvoiceNo ? 2 : modalView ? 5 : 3}
                  className={classes.marginCol}
                ></Grid>
                <Grid item sm={2} className={classes.currencyCol}>
                  <Typography variant="body2">{dollarize(data.gross)}</Typography>
                </Grid>
                <Grid item sm={2} className={classes.currencyCol}>
                  <Typography variant="body2">{dollarize(data.net)}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.tableRowDetails, { [classes.sequenceItem]: !nested })}>
          {
            //If Nested Only
            nested && data.items
              ? data.items.map((e: ItemsProps, i: any) => (
                  <SubRow key={i} e={e} level={0}>
                    {e.additionalItems &&
                      e.additionalItems.map((v: ItemsProps, idx: any) => (
                        <SubRow e={v} key={idx} level={1}>
                          {v.additionalItems &&
                            v.additionalItems.map((vx: ItemsProps, idx2: any) => (
                              <SubRow e={vx} key={idx2} level={2} />
                            ))}
                        </SubRow>
                      ))}
                  </SubRow>
                ))
              : //Default
                data.items &&
                data.items.map((e: ItemsProps, i: any) => (
                  <SubRow key={i} e={e} level={e.sequenceNumber ? e.sequenceNumber : 0} />
                ))
          }
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <PageHeader
          {...(isTitleRequired ? { title: `Order No: ${orderNumber}` } : {})}
          titleVariant="h3"
          subtitle={`${underwriter} | Reported: ${formatDate(orderDate)} | No of Files: ${invoices && invoices.length}`}
          divider={false}
          disableMargin="both"
          className={classes.mb2}
          contentRight={
            <>
              <AgentNetButton
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleCollapseAll()}
                data-testid={`PaySumOrder${orderNumber}Close`}
                endIcon={<Remove fontSize="small" />}
              >
                Close All
              </AgentNetButton>

              <AgentNetButton
                variant="text"
                color="primary"
                size="small"
                onClick={() => handleExpandAll()}
                data-testid={`PaySumOrder${orderNumber}Close`}
                endIcon={<Add fontSize="small" />}
              >
                Expand All
              </AgentNetButton>
            </>
          }
        />
      </Grid>
      {invoices && invoices.length > 0 && (
        <div className={classes.InvoiceSummaryTable}>
          <Grid container className={classes.InvoiceSummaryTableHeader}>
            <Grid item className={classes.expanderColHeader}></Grid>
            {showInvoiceNo && hasInvoiceNo ? (
              <>
                <Grid
                  item
                  sm={modalView ? 2 : 2}
                  md={modalView ? 2 : 2}
                  lg={modalView ? 3 : 1}
                  xl={modalView ? 3 : 1}
                  className={clsx(classes.fileNumberCell, classes.marginCol)}
                >
                  <OverflowTip backgroundColor="#F2F5F6">File No.</OverflowTip>
                </Grid>
                <Hidden mdDown>
                  <Grid item sm={modalView ? 4 : 1} className={clsx(classes.marginCol)}>
                    <OverflowTip backgroundColor="#F2F5F6">Invoice No.</OverflowTip>
                  </Grid>
                </Hidden>
              </>
            ) : (
              <Grid
                item
                sm={modalView ? 2 : 2}
                lg={modalView ? 2 : 2}
                xl={1}
                className={clsx(classes.fileNumberCell, classes.marginCol)}
              >
                <OverflowTip backgroundColor="#F2F5F6">File No.</OverflowTip>
              </Grid>
            )}
            {!modalView ? (
              <>
                <Hidden lgDown>
                  <Grid
                    item
                    lg={modalView ? 3 : 2}
                    xl={showInvoiceNo && hasInvoiceNo ? 1 : 2}
                    className={clsx(classes.amountDueCell)}
                  >
                    Property Address
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item sm={2} md={2} lg={2} className={clsx({ [classes.textRight]: true })}>
                    <OverflowTip backgroundColor="#F2F5F6">Gross</OverflowTip>
                  </Grid>
                </Hidden>
                <Grid item sm={2} md={2} lg={2} className={clsx({ [classes.textRight]: true })}>
                  <OverflowTip backgroundColor="#F2F5F6">Net</OverflowTip>
                </Grid>
                <Hidden mdDown>
                  <Grid item sm={2} md={2} lg={2} className={clsx({ [classes.textRight]: true })}>
                    <OverflowTip backgroundColor="#F2F5F6">Credits</OverflowTip>
                  </Grid>
                </Hidden>

                <Grid item sm={2} md={2} lg={2} className={clsx(classes.textRight, classes.amountDueCell)}>
                  Amount {isClosedStatus && grandTotal?.amountDue > 0 ? 'Paid' : 'Due'}
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={2} className={clsx({ [classes.textRight]: true })}>
                  <OverflowTip>Gross</OverflowTip>
                </Grid>
                <Grid item sm={2} className={clsx({ [classes.textRight]: true })}>
                  <OverflowTip>Net</OverflowTip>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container className={classes.InvoiceSummaryTableList}>
            {invoices.map((e: InvoiceProps, i) => (
              <AccordionRow data={e} key={i} index={i} />
            ))}
            <Grid container className={classes.totalsRow}>
              <Grid item className={classes.expanderColEmpty}></Grid>
              <Grid item lg={modalView ? 7 : 2} sm={modalView ? 7 : 2} className={classes.marginCol}></Grid>
              {!modalView ? (
                <>
                  <Hidden mdDown>
                    <Grid item md={2} lg={2} xl={2} className={clsx(classes.totalsCell, classes.currencyCol)}>
                      <Typography variant="body2" className={classes.weight700}>
                        {dollarize(grandTotal.gross)}
                      </Typography>
                    </Grid>
                  </Hidden>

                  <Grid
                    item
                    md={2}
                    lg={2}
                    xl={2}
                    className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
                  >
                    <Typography variant="body2" className={classes.weight700}>
                      {dollarize(grandTotal.net)}
                    </Typography>
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={2} lg={2} xl={2} className={clsx(classes.totalsCell, classes.currencyCol)}>
                      <Typography variant="body2" className={classes.weight700}>
                        {grandTotal.credits ? `(${dollarize(grandTotal.credits)})` : '($0.00)'}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid
                    item
                    md={2}
                    lg={2}
                    xl={2}
                    className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}
                  >
                    <Typography variant="body2" className={classes.weight700}>
                      {dollarize(grandTotal.amountDue)}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={2} className={clsx(classes.totalsCell, classes.currencyCol)}>
                    <Typography variant="body2" className={classes.weight700}>
                      {dollarize(grandTotal.gross)}
                    </Typography>
                  </Grid>
                  <Grid item md={2} className={clsx(classes.totalsCell, classes.currencyCol, classes.tableCellRight)}>
                    <Typography variant="body2" className={classes.weight700}>
                      {dollarize(grandTotal.net)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default React.memo(InvoiceSummaryTable);
