import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

import useSessionStorage from 'hooks/useSessionStorage';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
export interface ReportsListInterface {
  contentClassName?: string;
}

const Dashboard = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  );

  const classes = useStyles();
  const { addSnackbarMessage } = useSnackBars();
  const [sessionData, setSessionData] = useSessionStorage('bookOrderData');

  useEffect(() => {
    sessionData &&
      addSnackbarMessage({
        message: `Order Id ${sessionData.orderId} Booked`,
        type: 'success',
      });
    setSessionData(null);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <AgentNetDivider title="Order Management" typoVariant="h1" />
      </div>
    </div>
  );
};

export default Dashboard;
