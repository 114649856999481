import CalculatorIcon from 'ui-kit/icons/Calculator';

const CalculatorRenderer = (props: any) => (
  <div
    data-testid={`${props?.tabName}Calculator${props?.node?.id}Icon`}
    style={{ height: '20px' }}
    onClick={props?.onClick}
  >
    {props?.isRemitValid === false && (
      <div
        style={{
          position: 'absolute',
          top: '8px',
          left: '25px',
          width: '8px',
          height: '8px',
          backgroundColor: '#AC1209',
          borderRadius: '50%',
        }}
      ></div>
    )}
    {CalculatorIcon({ fontSize: 'small' })}
  </div>
);
export default CalculatorRenderer;
