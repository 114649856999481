/* eslint-disable react/prop-types */
import React from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import InvoiceSummaryTable from 'ui-kit/components/payment/InvoiceSummaryTable';

const InvoiceSummaryList: React.FC<{ orders: any; underwriter: any; hasPayment: boolean }> = React.memo(
  ({ orders = [], underwriter, hasPayment }) => {
    const cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    });

    const renderRow = ({ index, key, parent, style }: any) => {
      const order = orders[index];
      return (
        <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
          {({ measure }) => (
            <div style={style}>
              <InvoiceSummaryTable
                key={order.orderNumber}
                orderDate={order.orderDate}
                orderNumber={order.orderNumber}
                invoices={order.invoices}
                underwriter={underwriter}
                showInvoiceNo
                isClosedStatus={hasPayment}
                measure={measure}
              />
            </div>
          )}
        </CellMeasurer>
      );
    };

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <AutoSizer>
          {({ height, width }: any) => (
            <List
              height={height}
              width={width}
              rowCount={orders?.length}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={renderRow}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      </div>
    );
  },
);

InvoiceSummaryList.displayName = 'InvoiceSummaryList';
export default InvoiceSummaryList;
