function NoBacktitle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="109" height="105" fill="none">
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M52.3 102.6c28 0 50.7-22.7 50.7-50.8S80.2 1 52.3 1C24.3 1 1.6 23.7 1.6 51.8s22.7 50.8 50.7 50.8Z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m67 24.8 15.3 55c.5 1.9-.6 3.8-2.5 4.3L25.9 98.4c-1.9.5-3.8-.6-4.3-2.5l-18.8-71c-.5-1.9.6-3.8 2.5-4.3l39-10.3z"
      ></path>
      <path
        fill="#D6DCE8"
        d="m66.2 59.6-29.1 7.7-3.2.8-9.7 2.6c-.4.1-.7.6-.5 1.1.1.5.6.8 1 .7l9.7-2.6 3.2-.8 29.1-7.7c.4-.1.7-.6.5-1.1-.1-.5-.6-.8-1-.7m-1.9-6.8-10.8 2.8-3.7 1-27.3 7.3c-.4.1-.7.6-.5 1.1.1.5.6.8 1 .7l27.4-7.3 3.7-1 10.8-2.8c.4-.1.7-.6.5-1.1-.2-.4-.7-.8-1.1-.7m-1.6-6.9-2.8.7-2.8.7L20.6 57c-.4.1-.7.6-.5 1.1.1.5.6.8 1 .7l36.5-9.7 3.1-.8 2.5-.7c.4-.1.7-.6.5-1.1-.1-.4-.5-.7-1-.6M45.8 81.3l-2 .5c-.4.1-.9-.2-1-.7s.1-1 .5-1.1l2-.5c.4-.1.9.2 1 .7.1.6-.1 1-.5 1.1m-6.1 1.6-11.5 3c-.4.1-.9-.2-1-.7s.1-1 .5-1.1l11.5-3c.4-.1.9.2 1 .7.2.5 0 1-.5 1.1M61.3 39l-9.1 2.4-2.2.6-31.1 8.2c-.4.1-.7.6-.5 1.1.1.5.6.8 1 .7l31-8.1 2.2-.6 9-2.4c.5-.1.7-.6.6-1.2 0-.4-.5-.8-.9-.7M39.8 23.9 14 30.7c-.6.2-1.2-.2-1.4-.8s.2-1.2.8-1.4l25.8-6.8c.6-.2 1.2.2 1.4.8s-.2 1.2-.8 1.4M25.6 34.3l-9.9 2.6c-.6.2-1.2-.2-1.4-.8s.2-1.2.8-1.4l9.8-2.6c.6-.2 1.2.2 1.4.8s-.2 1.2-.7 1.4m18.7-24 4 15.2c.6 2.1 2.9 3.4 5 2.8L67 24.8"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="m107.399 40.3-9.2 56.4c-.3 1.9-2.2 3.2-4.1 2.8l-54.8-9.6c-1.9-.3-3.2-2.2-2.9-4.1l12.7-72.3c.3-1.9 2.2-3.2 4.1-2.8l39.8 7z"
      ></path>
      <path
        fill="#D6DCE8"
        d="m92.1 71.6-29.6-5.2-3.2-.6-9.9-1.7c-.4-.1-.9.3-.9.8-.1.5.2 1 .6 1.1l9.9 1.7 3.2.6 29.6 5.2c.4.1.9-.3.9-.8.1-.5-.1-1-.6-1.1m1.2-6.9-11-1.9-3.7-.7-27.9-4.9c-.4-.1-.9.3-.9.8-.1.5.2 1 .6 1.1L78.3 64l3.7.7 11 1.9c.4.1.9-.3.9-.8.1-.5-.2-1-.6-1.1m1.4-7-2.9-.5-2.9-.5-37.2-6.5c-.4-.1-.9.3-.9.8-.1.5.2 1 .6 1.1l37.2 6.5 3.2.6 2.5.4c.4.1.9-.3.9-.8.2-.5 0-1-.5-1.1M64.5 82.8l-2-.3c-.4-.1-.7-.6-.6-1.1s.5-.9.9-.8l2 .3c.4.1.7.6.6 1.1-.1.6-.5.8-.9.8m-6.1-1.1-11.7-2c-.4-.1-.7-.6-.6-1.1s.5-.9.9-.8l11.7 2c.4.1.7.6.6 1.1-.1.6-.5.9-.9.8m37.9-30.8L87 49.3l-2.3-.4L53 43.3c-.4-.1-.9.3-.9.8-.1.5.2 1 .6 1.1l31.7 5.6 2.3.4 9.2 1.6c.5.1.9-.3 1-.8s-.2-1-.6-1.1M83.2 28.2l-26.3-4.6c-.6-.1-1-.7-.9-1.3s.7-1 1.3-.9L83.6 26c.6.1 1 .7.9 1.3-.1.5-.7 1-1.3.9m-17.3 3.4-10.1-1.8c-.6-.1-1-.7-.9-1.3s.7-1 1.3-.9l10 1.8c.6.1 1 .7.9 1.3s-.7 1-1.2.9m27-13.9-2.7 15.5c-.4 2.2 1.2 4.3 3.4 4.7l13.799 2.4"
      ></path>
      <path
        fill="#fff"
        d="M69.7 91.4c12.315 0 22.3-9.984 22.3-22.3s-9.985-22.3-22.3-22.3c-12.317 0-22.3 9.984-22.3 22.3s9.983 22.3 22.3 22.3"
      ></path>
      <path
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M102.041 103.54c-.866 0-1.732-.346-2.424-1.125L85.162 87.96l-.52.346c-4.674 3.462-10.127 5.28-15.667 5.28-6.665 0-13.33-2.683-18.264-7.358-5.193-4.933-8.05-11.598-8.05-18.87 0-14.455 11.772-26.227 26.227-26.227 9.868 0 18.524 5.194 23.198 14.023 4.588 8.742 3.982 18.956-1.644 27.18l-.346.519 14.541 14.542c1.472 1.471 1.126 2.943.866 3.722-.692 1.385-2.077 2.423-3.462 2.423ZM68.888 48.316c-10.56 0-19.043 8.569-19.043 19.043 0 11.945 9.782 19.13 19.303 19.13 5.8 0 11.08-2.598 14.802-7.272 4.587-5.713 5.366-13.416 2.164-20.081-3.29-6.666-9.868-10.82-17.226-10.82Z"
      ></path>
      <path
        fill="#B2C0D1"
        d="M67.6 72.7c-.1-.4-.1-.8-.1-1.3 0-1.7.7-3.2 2.5-4.5l1.5-1.1c.9-.7 1.3-1.5 1.3-2.5 0-1.4-1-2.8-3.1-2.8-2.2 0-3.2 1.8-3.2 3.5 0 .4 0 .7.1 1l-4-.1c-.1-.4-.2-.9-.2-1.4 0-3.4 2.5-6.6 7.2-6.6 4.8 0 7.4 3.1 7.4 6.2 0 2.5-1.3 4.2-3.1 5.5l-1.3.9c-1.1.8-1.7 1.8-1.7 3v.2zm1.7 2c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c.1-1.3 1.1-2.5 2.5-2.5"
      ></path>
    </svg>
  );
}

export default NoBacktitle;
