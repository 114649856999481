import theme from '../../theme';

/**
 * Embeds a style-sheet that formats the content area of the knowledge-base articles.
 */

export function RenderStyles() {
  const primaryBlue = theme.palette.primary.main;
  const commentText = theme.palette.secondary.main;

  const linkColor = theme.palette.primary.main;
  const { h1, h2, h3, h4, h5, h6 } = theme.typography;

  // Styles should always be nested in .kbArticleBody to avoid conflicts with other styles.
  return (
    <style>{`

    body {
      margin-bottom: 0;
    }

    a, a:visited, a:hover, a:active, a:focus, a:link {
      color: ${linkColor};
      text-decoration: none;
    }


    .kb-l3 .kb-l3-topic *, .kb-l3 .kb-l3-subtopic * {
      line-height: 1.25rem;
    }
    .MuiTypography-h1,
    .MuiTypography-h2,
    .MuiTypography-h3,
    .MuiTypography-h4 {
      text-transform: none;
    }
   .MuiOutlinedInput-notchedOutline {
        border: none;
    }
  .kbArticleBody a {color: #0074CA;}
  .kbArticleBody a:hover {color: #265e92;}

    .kbArticleBody h1, .kbArticleBody h2, .kbArticleBody h3, .kbArticleBody h4, .kbArticleBody h5, .kbArticleBody h6 {
      color: ${primaryBlue};
      font-weight: normal;
      margin: 0;
    }
    h1.MuiTypography-h1,
    .MuiTypography-body1 {
    line-height: 1.5;
    }
    .kbArticleBody h1 { font-family: Inter; font-weight: ${h1.fontWeight}; line-height: ${h1.lineHeight}; font-size: ${h1.fontSize}; }
    .kbArticleBody h2 { font-family: Inter; font-weight: ${h2.fontWeight}; line-height: ${h2.lineHeight}; font-size: ${h2.fontSize}; }
    .kbArticleBody h3 { font-family: Inter; font-weight: ${h3.fontWeight}; line-height: ${h3.lineHeight}; font-size: ${h3.fontSize}; color: #013a6f; padding: 10px 0; }
    .kbArticleBody h4 { font-family: Inter; font-weight: ${h4.fontWeight}; line-height: ${h4.lineHeight}; font-size: ${h4.fontSize}; }
    .kbArticleBody h5 { font-family: Inter; font-weight: ${h5.fontWeight}; line-height: ${h5.lineHeight}; font-size: ${h5.fontSize}; }
    .kbArticleBody h6 { font-family: Inter; font-weight: ${h6.fontWeight}; line-height: ${h6.lineHeight}; font-size: ${h6.fontSize}; }
    .kbArticleBody td {
      padding-right: 50px;
    }
    .red a, .red a:-webkit-any-link {
        color: #B3150A;
    }
    .kbArticleBody .paragraph {
      margin-top: 5px;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .kbArticleBody p {
      font-size: 16px;
    }
    .kbArticleBody li {
      font-size: 16px;
    }
    .kbArticleBody ul .paragraph, .kbArticleBody ol .paragraph {
      margin-top: 0;
    }
    @media(min-width:1440px) {
      .MuiContainer-maxWidthLg {
        padding: 0 8vw;
      }
    }
    h1.MuiTypography-h1 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    li {
      margin-bottom: 5px;
    }
    .kbArticleBody .embedded-asset {
      display: inline-block;
      margin: 1rem;

    }

    .kbArticleBody .embedded-asset .asset-image {
    }

    .kbArticleBody .embedded-asset .asset-description {
      width: 100%;
      display: block;
      font-size: 0.8125rem;
      line-height: 1em;
      margin-bottom: 1rem;
      color: ${commentText};
    }

`}</style>
  );
}
