import { Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { useAuth } from '@agentnet/auth';
import { createCPLInfo, uploadCPLDocument } from 'api/cpl-api';
import { getDecryptedFileId } from 'api/underwriting-api';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import useAsync from 'hooks/useAsync';
import { FileWithPath } from 'react-dropzone';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { Notification } from 'ui-kit/components/notification/Notification';
import Uploader from 'ui-kit/components/uploader/Uploader';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import StateSelectField from 'ui-kit/inputs/StateSelectField';
import { CPLStatus } from './cpl-list/constants';
import { CPLDocument, CreateCPLType, ManualCPLDataType, savedCPL } from './types';

interface CPLProps {
  isDrawerOpen: boolean;
  closeDrawer: any;
  onCancel: () => void;
}
const CplDocumentUploaderFC: React.FC<CPLProps> = ({ isDrawerOpen, closeDrawer, onCancel }: CPLProps) => {
  const auth = useAuth();

  const [documents, setDocuments] = useState<CPLDocument[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [letterTypeValue, setLetterTypeValue] = useState<string>('');
  const [coveredPartyValue, setCoveredPartyValue] = useState<string>('');
  const [coveredPartyOption, setCoveredPartyOption] = useState<SelectOption[]>([]);
  const [letterTypeOption, setLetterTypeOption] = useState<SelectOption[]>([]);

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData, setActiveTab, activeTab } = fileDataCtx;
  if (fileData?.fileStatus !== CPLStatus.open && activeTab !== 'CPL') {
    onCancel();
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mb2: { marginBottom: theme.spacing(2) },
    }),
  );
  const styles = useStyles();

  const underwriters = [{ name: fileData?.underwriterCode, value: fileData?.underwriterCode }];

  const underwriterOptions: SelectOption[] = underwriters.map((underwriter) => ({
    name: underwriter.name,
    value: underwriter.value,
  }));

  const defaultCplData: ManualCPLDataType = {
    LetterTypeName: '',
    CoveredParties: [],
    Document: { Name: '', Content: '' },
  };
  const [cplData, setCPlData] = useState<ManualCPLDataType>(defaultCplData);

  const fetchDecryptedFileId = async (): Promise<number> => {
    const token = await auth.getAccessToken();
    return getDecryptedFileId(fileData?.fileId ?? '', token);
  };

  const postCPLDocument = async () => {
    const token = await auth.getAccessToken();
    return uploadCPLDocument(decryptedFileId ?? 0, fileData?.accountId ?? 0, cplData ?? defaultCplData, token);
  };

  const updateCpl = (field: string, value: string) => {
    if (field === 'LetterType') {
      cplData.LetterTypeName = value;
    }
    if (field === 'CoveredParty') {
      cplData.CoveredParties = [];
      cplData.CoveredParties.push(value);
    }
  };

  const { execute: executeFetchDecryptedFileId, value: decryptedFileId } = useAsync<number>(
    fetchDecryptedFileId,
    false,
  );

  const loadCreateCPLForm = async (): Promise<CreateCPLType> => {
    const token = await auth.getAccessToken();
    return createCPLInfo(fileData, token);
  };
  const {
    execute: executeLoadCPLForm,
    value: loadCPLValue,
    status: loadingStatus,
    errors: loadCplError,
  } = useAsync<CreateCPLType>(loadCreateCPLForm, false);

  useEffect(() => {
    if (isDrawerOpen) {
      if (!loadCPLValue) executeLoadCPLForm().then();
      const Options = letterTypes?.map((letterType: any) => {
        return { name: letterType.letterTypeName, value: letterType.letterTypeName };
      });
      setLetterTypeOption(Options ?? []);
    }
  }, [isDrawerOpen, loadCPLValue]);

  const letterTypes = loadCPLValue?.cplTypes;

  const {
    execute: executePostCPLDocument,
    value: postCPLDocumentValue,
    status: postCPLDocumentStatus,
    errors: postCPLDocumentErrors,
  } = useAsync<savedCPL>(postCPLDocument, false);

  useEffect(() => {
    executeFetchDecryptedFileId().then();
  }, []);

  useEffect(() => {
    if (isSubmitted && postCPLDocumentValue && (postCPLDocumentErrors?.length ?? 0) === 0) {
      setIsSubmitted(false);
      closeDrawer();
      setDocuments([]);
      if (setActiveTab) setActiveTab('CPLEdit');
      onCancel();
    }
  }, [postCPLDocumentValue]);

  useEffect(() => {
    if (postCPLDocumentErrors?.length > 0) {
      setIsSubmitted(false);
    }
  }, [postCPLDocumentErrors]);

  useEffect(() => {
    if (isDrawerOpen == false) {
      setIsSubmitted(false);
      closeDrawer();
      setCPlData(defaultCplData);
      setDocuments([]);
      onCancel();
    }
  }, [isDrawerOpen]);

  async function getAsByteArray(file: Blob) {
    const val: ArrayBuffer = (await readFile(file)) as ArrayBuffer;
    const base64 = btoa(new Uint8Array(val).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return base64;
  }

  function readFile(file: Blob) {
    return (
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => resolve(e?.target?.result));
        reader.addEventListener('error', reject);
        reader.readAsArrayBuffer(file);
      }) ?? []
    );
  }

  useEffect(() => {
    if (letterTypeValue !== '') {
      const coveredParties = letterTypes?.find((x) => x.letterTypeName === letterTypeValue)?.coveredParties;
      if (coveredParties) {
        const options: SelectOption[] =
          coveredParties?.length > 0
            ? coveredParties.map((coveredParty: any) => ({ name: coveredParty, value: coveredParty }))
            : [{ name: 'None', value: 'None' }];
        setCoveredPartyOption(options);
        if (options.length === 1) {
          setCoveredPartyValue(options[0].value);
          updateCpl('CoveredParty', options[0].value);
        }
      }
    }
  }, [letterTypeValue]);

  useEffect(() => {
    if (letterTypeOption.length === 1) {
      setLetterTypeValue(letterTypeOption[0].value);
      updateCpl('LetterType', letterTypeOption[0].value);
      const coveredParties = letterTypes?.find((x) => x.letterTypeName === letterTypeOption[0].value)?.coveredParties;
      if (coveredParties && coveredParties.length <= 1) {
        const options: SelectOption[] =
          coveredParties?.length === 1
            ? coveredParties.map((coveredParty: any) => ({ name: coveredParty, value: coveredParty }))
            : [{ name: 'None', value: 'None' }];
        setCoveredPartyOption(options);
        setCoveredPartyValue(options[0].value);
        updateCpl('CoveredParty', options[0].value);
      }
    }
  }, [letterTypeOption]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      const localDocumentList: CPLDocument[] = [];
      const filePathsPromises: any[] = [];
      uploadedFiles.forEach((file) => {
        const document: CPLDocument = {
          Name: file.name,
          Content: '',
        };

        localDocumentList.push(document);
        filePathsPromises.push(getAsByteArray(file));
      });
      Promise.all(filePathsPromises).then((filePaths) => {
        filePaths.forEach((e, i) => {
          localDocumentList[i].Content = e;
        });
        setDocuments([...documents, ...localDocumentList]);
        if (cplData.Document) {
          cplData.Document.Content = localDocumentList[0].Content;
          cplData.Document.Name = localDocumentList[0].Name;
        }
      });
    }
  }, [uploadedFiles]);

  const deleteDocument: any = async (file?: any) => {
    const documentList: CPLDocument[] = documents ?? [];

    const filteredDocuments = documentList.filter((uwrdocument: CPLDocument) => uwrdocument.Name != file.name);
    setDocuments(filteredDocuments);
    setUploadedFiles([]);
  };

  const renderErrorNotification = () => {
    return postCPLDocumentErrors.map((error, index) => (
      <Grid item sm={12} key={`CplDocumentNotification_${index}`} className={styles.mb2}>
        <Notification inline severity="warning" data-testid={'CplDocumentError' + index}>
          {error}
        </Notification>
      </Grid>
    ));
  };

  const renderDocumentsUploader = () => {
    return (
      <Grid container>
        <Grid item sm={12} style={{ paddingTop: '14px' }}>
          <Uploader
            handleFiles={(files) => setUploadedFiles(files)}
            onDeleteItem={deleteDocument}
            listTitle="Uploaded Document"
            hideInfo
            maxSize={45}
            acceptTypes={{
              'application/pdf': ['.pdf'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            }}
            drawerMode
            disabled={uploadedFiles?.length === 1 ? true : false}
            data-testid={'UnderwritingDocumentUpload'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isDrawerOpen ? (
        <>
          <LoadingSpinner status={postCPLDocumentStatus} variant="linear" />
          <FormDrawerComponent
            title={'Upload CPL'}
            open={isDrawerOpen}
            primaryActionProps={{
              loading: isSubmitted,
              disabled:
                (isSubmitted && postCPLDocumentErrors.length == 0) ||
                documents.length == 0 ||
                letterTypeValue === '' ||
                coveredPartyValue === ''
                  ? true
                  : false,
              'data-testid': 'UploadCpl',
              size: 'small',
            }}
            dismissActionProps={{
              disabled: false,
              'data-testid': 'UploadCplCancel',
              size: 'small',
            }}
            crossQAPrefix="UploadCpl"
            primaryActionLabel="Submit CPL"
            onPrimaryAction={() => {
              if (documents.length > 0) {
                setIsSubmitted(true);
                executePostCPLDocument().then();
              }
            }}
            onDismissAction={() => closeDrawer()}
            testId="scrollable-form-drawer"
            width={720}
          >
            <Container>
              <Grid container spacing={3}>
                <Grid container>{renderErrorNotification()}</Grid>
                <Grid item sm={6}>
                  <StateSelectField disabled={true} label="State" name="State" value={fileData?.propertyState} />
                </Grid>
                <Grid item sm={6}>
                  <AgentNetDropdownSelector
                    name="underwriterCode"
                    qaAttribute="UploadCplUnderwriterCode"
                    label="Underwriter"
                    options={underwriterOptions}
                    value={fileData?.underwriterCode}
                    defaultToFirst={true}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ paddingTop: '8px' }}>
                <Grid item sm={6}>
                  <AgentNetDropdownSelector
                    name="LetterType"
                    label="Letter Type"
                    options={letterTypeOption}
                    value={letterTypeValue}
                    required
                    menuOption={(value: string) => {
                      setLetterTypeValue(value);
                      setCoveredPartyValue('');
                      updateCpl('LetterType', value);
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <AgentNetDropdownSelector
                    name="CoveredParty"
                    label="Covered Party"
                    options={coveredPartyOption ?? []}
                    value={coveredPartyValue}
                    required
                    disabled={
                      letterTypeValue === '' && coveredPartyOption.length !== 1 && letterTypeOption.length !== 1
                    }
                    menuOption={(value: string) => {
                      setCoveredPartyValue(value);
                      updateCpl('CoveredParty', value);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                {renderDocumentsUploader()}
              </Grid>
            </Container>
          </FormDrawerComponent>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default CplDocumentUploaderFC;
