import { useAuth } from '@agentnet/auth';
import { FormControlLabel, Grid, GridSize, Switch } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getEndorsementsByPolicyDate, getJacketFields, getRateTypes } from 'api/jacket-api';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useState } from 'react';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetCheckBox, AgentNetTextInput, CurrencyField, TimeField } from 'ui-kit/inputs';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { JacketSchema } from 'utilities/validation/schemas/jacket-schema';
import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import { StatCodePdfWindowName, WindowFeatures, policyFieldsWithNameInLabel } from './constants';
import Endorsements from './Endorsements';
import {
  Endorsement,
  Jacket,
  JacketEndorsement,
  PolicyField,
  PolicyFieldOption,
  PolicyFieldsModel,
  PolicyType,
  RateTypes,
} from './types';

import { pxToRem } from '@fluentsms/agentnet-web-components';
import moment from 'moment';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import PolicyTypeAhead from 'ui-kit/inputs/PolicyTypeAhead/PolicyTypeAhead';
import NewMexicoStatCodePdf from '../../static/Stat_Code_New_Mexico_State.pdf';
import NewYorkStatCodePdf from '../../static/Stat_Code_New_York_State.pdf';
import TexasStatCodePdf from '../../static/Stat_Code_Texas_State.pdf';
import IncorporatedEndorsements from './IncorporatedEndorsement';

interface JacketFormProps {
  index?: number;
  jacket: Jacket;
  jacketSelectedEndorsements: JacketEndorsement[];
  jacketEndorsements: Endorsement[];
  jacketTypes?: PolicyType[];
  contentDisabled: boolean;
  updateJacket: (randomId: string, key: string, value: any, isFieldValue: boolean, isCheckBox?: boolean) => void;
  updateJacketEndorsement: (
    jacketId: string | null,
    randomId: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null,
  ) => void;
  updateIncorporatedEndorsement: (
    jacketId: string | null,
    endoId: string | null,
    starsNumber: string | null,
    type: string | null,
    value: string | null,
    fieldName: string | null,
  ) => void;
  account?: number;
  underwriter?: string;
  state?: string;
  county?: string;
  editingPendingJacket: number | null;
  addJacketFields: (
    randomId: string,
    formNumber: string,
    fields: PolicyField[],
    endorsements: JacketEndorsement[],
    isLenderPolicy: boolean,
    isOwnerPolicy: boolean,
    isLenderPolicyOrAll: boolean,
  ) => void;
  isPolicyTypeDisabled?: boolean;
  submitAttempted: boolean;
  isEditDisabled: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  lenders?: any[];
  setTriggerSaveCall?: React.Dispatch<React.SetStateAction<boolean>>;
  updateAttempted: boolean;
  setUpdateAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerUpdateCall?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPolicyNumber?: any;
  closeEndorsement?: boolean;
  disableCreate?: any;
  enableCancelJacket?: any;
}
const JacketForm: React.FC<JacketFormProps> = ({
  index,
  jacket,
  jacketSelectedEndorsements,
  jacketEndorsements,
  jacketTypes,
  contentDisabled,
  updateJacket,
  updateJacketEndorsement,
  updateIncorporatedEndorsement,
  account,
  underwriter,
  state,
  county,
  addJacketFields,
  isPolicyTypeDisabled,
  submitAttempted,
  isEditDisabled,
  setSubmitAttempted,
  lenders,
  setTriggerSaveCall,
  updateAttempted,
  setUpdateAttempted,
  setTriggerUpdateCall,
  selectedPolicyNumber,
  editingPendingJacket,
  closeEndorsement,
  disableCreate,
  enableCancelJacket,
}: JacketFormProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      jacketsWrap: {
        padding: pxToRem(0, 24, 48),
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      jacketEndorsement: {
        padding: pxToRem(15, 0, 0),
      },
      warningMsg: { marginBottom: theme.spacing(3) },
      jacketTypesRadioOptions: {
        padding: pxToRem(0, 0, 20),
      },
      fieldPadding: {
        marginTop: theme.spacing(6),
      },
    }),
  );

  const classes = useStyles();
  const { getAccessToken } = useAuth();

  const [jacketTypeOptions, setJacketTypeOptions] = useState<SelectOption[]>([]);
  const [lenderOptions, setLenderOptions] = useState<SelectOption[]>([]);
  const [jacketDetails, setJacketDetails] = useState<Jacket>(jacket);
  const [formNumber, setFormNumber] = useState('');

  const [shouldShowValidation, setShouldShowValidation] = useState(false);
  const [isJacketFieldsAvailable, setIsJacketFieldsAvailable] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [defaultFields, setDefaultFields] = useState<PolicyField[]>([]);
  const [additionalFields, setAdditionalFields] = useState<PolicyField[]>([]);
  const [isLenderPolicy, setIsLenderPolicy] = useState(false);
  const [rateTypeGap, setRateTypeGap] = useState(0);
  const [extendedCoverage, setExtendedCoverage] = useState(false);
  const [isReIssueSelected, setIsReIssueSelected] = useState(false);
  const [selectedRateType, setSelectedRateType] = useState('');
  const [includeAgentAddress, setIncludeAgentAddress] = useState(true);
  const [includeAgentAddressDisabled, setIncludeAgentAddressDisabled] = useState(false);
  const [isOwnerPolicy, setIsOwnerPolicy] = useState(false);
  const [defaultEndorsements, setDefaultEndorsements] = useState<JacketEndorsement[]>([]);
  const [filteredEndorsements, setFilteredEndorsements] = useState<Endorsement[]>([]);
  const { addSnackbarMessage } = useSnackBars();
  const [fieldSections, setFieldSections] = useState<any[]>([]);
  const [rateTypeOptions1, setRateTypeOptions] = useState<{ name: string; value: string }[]>([]);
  const [policyDate, setPolicyDate] = useState('');
  const [isLenderPolicyOrAll, setisLenderPolicyOrAll] = useState(false);
  const [endorsementType, setEndorsementType] = useState<Endorsement[]>([]);

  const radioOptions: RadioProps[] = [
    { label: 'is added', value: 'chkProcedRuleAdded' },
    { label: 'is not added', value: 'chkNoProcedRuleAdded' },
  ];
  const addedValue = radioOptions[0];
  const notAddedValue = radioOptions[1];
  const [selectedRadioValue, setSelectedRadioValue] = useState<RadioProps>(addedValue);

  const policyTypesWithOtherTypes: RadioProps[] = [
    { label: 'All', value: 'All' },
    { label: 'Loan', value: 'Loan' },
    { label: 'Owner', value: 'Owner' },
    { label: 'Other', value: 'Other' },
  ];
  const allPolicyTypes = policyTypesWithOtherTypes[0];
  const loanPolicyTypes = policyTypesWithOtherTypes[1];
  const ownerPolicyTypes = policyTypesWithOtherTypes[2];
  const otherPolicyTypes = policyTypesWithOtherTypes[3];

  const getCurrentJacketType = (): string => {
    let type = 'All';
    if (jacketTypes && jacketTypes.length > 0 && jacket?.formNumber !== '') {
      const selectedJacket = jacketTypes.find((y) => y.formNumber === jacket.formNumber);
      if (selectedJacket) {
        switch (selectedJacket.insuredName) {
          case 'Loan':
            type = 'Loan';
            break;
          case 'Owner':
            type = 'Owner';
            break;
          case 'OwnerLoan':
            type = 'All';
            break;
          default:
            type = 'Other';
            break;
        }
      }
    }
    return type;
  };

  const fetchJacketFields = async (): Promise<PolicyFieldsModel> => {
    const token = await getAccessToken();
    return getJacketFields(formNumber ?? '', account, state, county, token);
  };

  const {
    execute: executeGetJacketFields,
    value: getJacketFieldsValue,
    status: getJacketFieldsStatus,
  } = useAsync<PolicyFieldsModel>(fetchJacketFields, false);

  const fetchRateTypes = async (
    formNumber?: string,
    stateCode?: string,
    policyEffectiveDate?: string,
    isExtendedCoverage?: boolean,
    countyName?: string,
    accountNumber?: number,
  ) => {
    const token = await getAccessToken();
    if (formNumber != '') {
      const result = await getRateTypes(
        formNumber,
        stateCode,
        policyEffectiveDate,
        isExtendedCoverage,
        countyName,
        accountNumber,
        token,
      );

      const rateTypeOptions: { name: string; value: string }[] = [];
      result.forEach((item: RateTypes) => {
        const option = {
          name: item.rateTypeName,
          value: item.rateTypeId,
        };
        rateTypeOptions.push(option);
      });

      setRateTypeOptions(rateTypeOptions);
    }
  };

  const fetchEndorsements = async (stateCode?: string, underwriterCode?: string, effectiveDate?: string) => {
    const token = await getAccessToken();
    const endorsements = await getEndorsementsByPolicyDate(
      stateCode ?? '',
      underwriterCode ?? '',
      effectiveDate ?? '',
      token,
    );
    const miscEndorsement: Endorsement = {
      endorsementName: 'Miscellaneous Endorsement',
      endorsementFormNumber: 'Miscellaneous Endorsement',
      policyCategories: [],
      policyCoverages: [],
    };
    const allEndorsements = [...endorsements, miscEndorsement];
    setEndorsementType(allEndorsements);
  };

  useEffect(() => {
    if (policyDate == '' && formNumber != '') {
      fetchRateTypes(formNumber, state, moment(new Date()).format('MM-DD-YYYY'), extendedCoverage, county, account);
      fetchEndorsements(state, underwriter, moment(new Date()).format('MM-DD-YYYY'));
    } else if (policyDate != '' && formNumber == '') {
      fetchRateTypes(jacketDetails.formNumber, state, policyDate, extendedCoverage, county, account);
      fetchEndorsements(state, underwriter, policyDate);
    }
  }, [policyDate, formNumber]);

  useEffect(() => {
    if (getJacketFieldsValue) {
      updateRateTypeGap(getJacketFieldsValue?.policyFields);
      const rateTypeField = getJacketFieldsValue?.policyFields.find((x) => x.name === 'RateType');
      if (rateTypeField && rateTypeField.value) {
        setSelectedRateType(rateTypeField.value.toString());
      }
      const defaultsEndorsements = getJacketFieldsValue?.defaultEndorsements;
      if (defaultsEndorsements?.length > 0) {
        defaultsEndorsements.forEach((item) => {
          updateJacketEndorsement(
            null,
            null,
            null,
            item?.endorsementName ?? '',
            null,
            'add',
            'Endorsement',
            item?.endorsementFormNumber,
          );
        });
        setDefaultEndorsements(defaultsEndorsements);
      }
      addJacketFields(
        jacketDetails.randomId,
        jacketDetails.formNumber,
        getJacketFieldsValue.policyFields,
        getJacketFieldsValue.jacketEndorsements,
        isLenderPolicy,
        isOwnerPolicy,
        isLenderPolicyOrAll,
      );
      disableCreate(false);
    }
  }, [getJacketFieldsValue]);

  useEffect(() => {
    if (jacketTypes && jacketTypes.length > 0) {
      const jacketTypesWithDecommissioned = jacketTypes;
      jacketTypes = jacketTypes?.filter((x) => !x.isDecommissioned);
      // Add missing jacket
      if (jacket?.formNumber !== '') {
        const isJacketMissing = !jacketTypes?.some((y) => y.formNumber === jacket.formNumber);
        if (isJacketMissing) {
          const missedJacketType: PolicyType = {
            formNumber: jacket.formNumber,
            policyName: jacket.policyType,
            jacketFormType: '',
            insuredName: '',
            policyCategory: 'All',
            policyCoverage: 'All',
            hasExtendedCoverage: false,
            extendedPolicyCoverage: '',
            isDecommissioned: false,
          };
          const selectedJacketType = jacketTypesWithDecommissioned?.filter((x) => x.formNumber == jacket?.formNumber);
          missedJacketType.policyCategory =
            selectedJacketType.length > 0 ? selectedJacketType[0].policyCategory : 'All';
          missedJacketType.policyCoverage =
            selectedJacketType.length > 0 ? selectedJacketType[0].policyCoverage : 'All';
          missedJacketType.isDecommissioned =
            selectedJacketType.length > 0 ? selectedJacketType[0].isDecommissioned : false;
          jacketTypes.push(missedJacketType);
        }
      }
      const jacketTypeOptions = jacketTypes?.map((x, i) => {
        return { name: x.policyName, value: x.formNumber };
      });
      if (contentDisabled || isPolicyTypeDisabled || isEditDisabled) {
        setJacketTypeOptions(jacketTypeOptions);
      } else {
        const filterDecommissioned = jacketTypes.filter((x) => !x.isDecommissioned);
        const filteredJacketTypeOptions = filterDecommissioned?.map((x, i) => {
          return { name: x.policyName, value: x.formNumber };
        });
        setJacketTypeOptions(filteredJacketTypeOptions);
      }
    }
  }, [jacketTypes]);

  useEffect(() => {
    if (lenders && lenders?.length > 0 && (jacket.policyFields?.length > 0 || jacket.lenderId)) {
      const lenderOptions = lenders?.map((x) => {
        return { name: x.name, value: x.lenderId };
      });
      setLenderOptions(lenderOptions);
      const selectedLender = lenders.find((x) => x.lenderId === jacket.lenderId);
      const lenderClauseText = selectedLender?.lenderClauseText;
      if (jacket.policyNumber === '') {
        const trimmedClauseText = lenderClauseText ? `, ${lenderClauseText.replace(/^[^a-zA-Z0-9]+/, '')}` : '';
        jacket.policyFields
          .filter((field) => field.name === 'Insured')
          .forEach((field) => {
            jacket.lenderId === 0 ? (field.value = '') : (field.value = selectedLender?.name + trimmedClauseText);
          });
      }
    }
  }, [lenders, jacket.policyFields?.length, jacket.lenderId]);

  useEffect(() => {
    if (jacketSelectedEndorsements) {
      const miscEndorsement = jacketSelectedEndorsements.filter((x) => x.endorsementFormNumber === 'MISC. END.');
      if (miscEndorsement.length > 0) {
        miscEndorsement.map((item) => {
          validateErrors(jacket);
        });
      }
    }
  }, [jacketSelectedEndorsements]);

  useEffect(() => {
    if (jacket) {
      if (jacket.policyType) {
        setFormNumber(jacket.formNumber);
        validateErrors(jacket);
      }
      if (jacket.policyFields?.length > 0) {
        setIsJacketFieldsAvailable(true);
        const defaultFields = jacket.policyFields?.filter((x) => x.group === '');
        setDefaultFields(defaultFields);
        const additionalFields = jacket.policyFields?.filter(
          (x) => x.group === 'Additional Policy Fields' || x.group === undefined,
        );

        const fieldSections: any[] = [];
        const fieldSection = additionalFields?.reduce(function (r, a) {
          r[a.section] = r[a.section] || [];
          r[a.section].push(a);
          return r;
        }, Object.create(null));

        for (const [key, value] of Object.entries(fieldSection)) {
          fieldSections.push(value);
        }
        setFieldSections(fieldSections);
        setAdditionalFields(additionalFields);
        const isExtendedCoverage = jacket.policyFields?.find((x) => x.name === 'IsExtendedCoverage');
        if (isExtendedCoverage) {
          isExtendedCoverage.isChecked ? setExtendedCoverage(true) : setExtendedCoverage(false);
        }
        const isIncludeAgentAddress = jacket.policyFields?.find((x) => x.name === 'IsAgentAddressRequired');
        if (isIncludeAgentAddress) {
          isIncludeAgentAddress.isChecked ? setIncludeAgentAddress(true) : setIncludeAgentAddress(false);
          isIncludeAgentAddress.isDisabled
            ? setIncludeAgentAddressDisabled(true)
            : setIncludeAgentAddressDisabled(false);
          if (isIncludeAgentAddress.isDisabled) {
            jacket.policyFields = jacket.policyFields.filter((x) => x.name !== 'IsAgentAddressRequired');
          }
        }

        updateRateTypeGap(jacket?.policyFields);

        const rateTypeField = jacket?.policyFields.find((x) => x.name === 'RateType');
        if (rateTypeField?.value) {
          setSelectedRateType(rateTypeField.value);
          const selectedRateType = rateTypeField?.options.find((x) => x.value === rateTypeField?.value);
          selectedRateType?.text.toLowerCase().includes('reissue')
            ? setIsReIssueSelected(true)
            : setIsReIssueSelected(false);
        }

        if (endorsementType?.length == 0) {
          const policyDateField = jacket?.policyFields.find((x) => x.name === 'PolicyDate');
          if (policyDateField?.value && policyDateField?.value != '') {
            fetchEndorsements(state, underwriter, policyDateField?.value);
          } else {
            setEndorsementType(jacketEndorsements);
          }
        }
        filterEndorsements();
      }
      setJacketDetails(jacket);
    }
  }, [jacket]);

  useEffect(() => {
    const selectedJacketType = jacketTypes?.find((x) => x.formNumber === formNumber);
    if (formNumber && selectedJacketType) {
      const insuredName = selectedJacketType?.insuredName ?? '';
      setIsLenderPolicy(insuredName === 'Loan');
      setisLenderPolicyOrAll(insuredName === 'Loan' || insuredName === 'OwnerLoan');
      setIsOwnerPolicy(
        insuredName === 'Owner' || insuredName === 'Preliminary Judicial' || insuredName === 'Foreclosure Guarantee',
      );
      executeGetJacketFields().then();
    }
  }, [formNumber]);

  useEffect(() => {
    if (jacket.policyType && submitAttempted) {
      setShouldShowValidation(true);
      const hasEditingPendingJacket = editingPendingJacket !== 0 ? true : false;
      if (validationErrors?.length > 0 && !hasEditingPendingJacket) {
        addSnackbarMessage({
          message: `Create Jacket Failed`,
          type: 'error',
        });
        setTriggerSaveCall && setTriggerSaveCall(false);
      }
      if (validationErrors?.length > 0 && hasEditingPendingJacket && jacket.jacketId === editingPendingJacket) {
        addSnackbarMessage({
          message: `Create Jacket Failed`,
          type: 'error',
        });
        setTriggerSaveCall && setTriggerSaveCall(false);
      } else {
        setTriggerSaveCall && setTriggerSaveCall(true);
      }
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  useEffect(() => {
    if (jacket.policyNumber === selectedPolicyNumber && updateAttempted) {
      setShouldShowValidation(true);
      if (validationErrors?.length > 0) {
        addSnackbarMessage({
          message: `Update Jacket Failed`,
          type: 'error',
        });
        setTriggerUpdateCall && setTriggerUpdateCall(false);
      } else {
        setTriggerUpdateCall && setTriggerUpdateCall(true);
      }
      setUpdateAttempted(false);
    }
  }, [updateAttempted]);

  const validateErrors = async (jacket: Jacket) => {
    let errs: FieldValidationError[] = (await doValidate(jacket, JacketSchema)) || [];
    errs = errs.map((x) => {
      if (policyFieldsWithNameInLabel.includes(x.field)) {
        x.message = `${jacket.policyFields.find((y) => y.name === x.field)?.label} ${x.message.split(' ').pop()}`;
      }
      return x;
    });
    setValidationErrors(errs);
  };

  const filterEndorsements = (): void => {
    const selectedPolicy = jacketTypes?.find((x) => x.formNumber === jacket.formNumber);
    const policyCategory = selectedPolicy?.policyCategory ?? 'All';
    const policyCoverage = extendedCoverage
      ? selectedPolicy?.extendedPolicyCoverage ?? 'All'
      : selectedPolicy?.policyCoverage ?? 'All';
    const filteredEndorsements = endorsementType?.filter(
      (x) =>
        x.endorsementFormNumber === 'Miscellaneous Endorsement' ||
        ((x.policyCategories.includes('All') || x.policyCategories.includes(policyCategory)) &&
          (x.policyCoverages.includes('All') || x.policyCoverages.includes(policyCoverage))),
    );
    setFilteredEndorsements(filteredEndorsements);
  };

  const rateTypeOptionsList = (field: PolicyField) => {
    let rateTypeOptions: { name: string; value: string }[] = [];

    if (policyDate != '') {
      rateTypeOptions = rateTypeOptions1;
      if (rateTypeOptions.length == 0) {
        return rateTypeOptions;
      }
    } else {
      field?.options.forEach((item) => {
        if (item.isExtendedCoverage === extendedCoverage) {
          const option = {
            name: item.text,
            value: item.value,
          };
          rateTypeOptions.push(option);
        }
      });
    }
    const isRateTypeFound = rateTypeOptions.some((option) => {
      if (option.value == field.value) {
        return true;
      }
    });
    if (!isRateTypeFound) {
      field.value = rateTypeOptions[0]?.value;
    }

    return rateTypeOptions;
  };

  const statCodeOptionList = (field: PolicyField) => {
    const statCodeOptions = field?.options;
    let selectedOptions: PolicyFieldOption[] = [];
    statCodeOptions.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    if (selectedRateType !== '') {
      selectedOptions = statCodeOptions?.filter((x) => x.rateTypeValue === selectedRateType);
      if (selectedOptions.length == 0) {
        selectedOptions = statCodeOptions;
      }
      field.value =
        field.value === ''
          ? selectedOptions[0]?.value
          : selectedOptions.find((x) => x.text === field.value)
          ? field.value
          : selectedOptions[0]?.value;
    }
    const result = selectedOptions?.map((x, i) => {
      return { name: x.text, value: x.value };
    });
    return result;
  };

  const updateRateTypeGap = (policyFields: PolicyField[]) => {
    if (policyFields && policyFields.length > 0) {
      const hasTimeField = policyFields.find((x) => x.name === 'PolicyTime');
      const hasLoanField = policyFields.find((x) => x.name === 'LoanNumber');
      const hasAmountField = policyFields.find((x) => x.name === 'PolicyAmount');
      const hasDateField = policyFields.find((x) => x.name === 'PolicyDate');

      let gap = 0;
      if (!hasLoanField) {
        gap += 3;
      }
      if (!hasAmountField) {
        gap += 3;
      }
      if (!hasDateField) {
        gap += 3;
      }
      if (!hasTimeField) {
        gap += 3;
      }
      setRateTypeGap(gap === 12 ? 0 : gap);
    }
  };

  const openStatCodePDF = () => {
    switch (state) {
      case 'NM':
        return window.open(NewMexicoStatCodePdf, StatCodePdfWindowName, WindowFeatures);
      case 'NY':
        return window.open(NewYorkStatCodePdf, StatCodePdfWindowName, WindowFeatures);
      case 'TX':
        return window.open(TexasStatCodePdf, StatCodePdfWindowName, WindowFeatures);
    }
  };

  useEffect(() => {
    if (jacket?.jacketIncorporatedEndorsements) {
      const endorsement = jacket.jacketIncorporatedEndorsements?.find(
        (x) => x.endorsementName === 'T-42' || x.endorsementFormNumber === 'T-42',
      );
      if (endorsement) {
        const fields = endorsement.endorsementInputFields;
        const isAddedField = fields?.find((y: any) => y.name === 'chkProcedRuleAdded');
        if (isAddedField) {
          if (isAddedField.value && (isAddedField.value === 'true' || isAddedField.value === 'false')) {
            isAddedField.isChecked = isAddedField.value === 'true';
          }
          isAddedField.isChecked ? setSelectedRadioValue(addedValue) : setSelectedRadioValue(notAddedValue);
        }
        const isNotAddedField = fields?.find((y: any) => y.name === 'chkNoProcedRuleAdded');
        if (isNotAddedField) {
          if (isNotAddedField.value && (isNotAddedField.value === 'true' || isNotAddedField.value === 'false')) {
            isNotAddedField.isChecked = isNotAddedField.value === 'true';
          }
        }
      }
    }
  }, []);

  const convertTime12to24 = (time12h: string) => {
    if (time12h) {
      const [time, modifier] = [time12h.substring(0, 5), time12h.substring(5)];
      const timeSplit = time.split(':');
      let hours = timeSplit[0];
      const minutes = timeSplit[1];

      if (hours === '12') {
        hours = '00';
      }

      if (modifier.trim().toString() === 'PM') {
        const calcHours = parseInt(hours, 10) + 12;
        hours = calcHours.toString();
      }
      return `${hours}:${minutes}`;
    }
  };

  const renderFieldComponent = (field: PolicyField) => {
    switch (field.dataType) {
      case 'text':
        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label={field.label}
              name={field.name}
              data-testid={'Jacket' + field.name + [index]}
              value={field.value ?? ''}
              onChange={(e) => {
                updateJacket(jacketDetails.randomId, field.name, e.target.value, true);
              }}
              disabled={contentDisabled || field.isDisabled}
              required={field.isRequired}
              errs={validationErrors}
              showValidation={shouldShowValidation}
            />
          </Grid>
        );
      case 'currency':
        return (
          <>
            {(field.name !== 'PriorLiabilityAmount' ||
              (field.name === 'PriorLiabilityAmount' && isReIssueSelected)) && (
              <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
                <CurrencyField
                  variant="outlined"
                  fullWidth
                  label={field.label}
                  name={field.name}
                  id={field.name}
                  data-testid={'Jacket' + field.name + [index]}
                  value={field.value ?? '0'}
                  max={100000000000}
                  allowNegative={false}
                  min={0}
                  onChange={(e) => {
                    updateJacket(jacketDetails.randomId, field.name, e.target.value, true);
                  }}
                  disabled={contentDisabled || field.isDisabled}
                  required={field.isRequired}
                  errs={validationErrors}
                  alwaysDisplayError={shouldShowValidation}
                  showValidationOnBlur={shouldShowValidation}
                />
              </Grid>
            )}
          </>
        );
      case 'select':
        if (!field.value && field.name !== 'StatCode') {
          field.value = field.options[0]?.value;
          if (field.name === 'RateType' && field.options.length > 0) {
            setSelectedRateType(field.options[0]?.value);
            field?.options[0]?.text.toLowerCase().includes('reissue')
              ? setIsReIssueSelected(true)
              : setIsReIssueSelected(false);
          }
          if (field.name === 'SignatoryName' && field.options.length > 0) {
            field.value = '';
          }
        }
        if (field.name === 'SignatoryName') {
          const validSignatoryValues = field.options.map((x) => x.value);
          if (!validSignatoryValues.includes(field.value)) {
            field.value = '';
          }
        }
        return (
          <>
            {field.name === 'RateType' && rateTypeGap > 0 && <Grid item sm={rateTypeGap as GridSize}></Grid>}
            <>
              {field.precondition && (includeAgentAddress || includeAgentAddressDisabled) && (
                <Grid
                  item
                  sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}
                >
                  <AgentNetDropdownSelector
                    label={field.label}
                    name={field.name}
                    qaAttribute={'Jacket' + field.name + [index]}
                    options={field?.options?.map((x, i) => {
                      return { name: x.text, value: x.value };
                    })}
                    value={field.value ?? '-1'}
                    menuOption={(val: string, name: any) => {
                      updateJacket(jacketDetails.randomId, name, val, true);
                    }}
                    dropdowntype="outlined"
                    disabled={contentDisabled || field.isDisabled}
                    required={field.isRequired}
                    errs={validationErrors}
                    showValidation={shouldShowValidation}
                    pdfQAAttribute={'Jacket' + field.name + 'PDF' + [index]}
                  />
                </Grid>
              )}
              {!field.precondition && (
                <Grid
                  item
                  sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}
                >
                  <AgentNetDropdownSelector
                    label={field.label}
                    name={field.name}
                    qaAttribute={'Jacket' + field.name + [index]}
                    options={
                      field.name === 'RateType'
                        ? rateTypeOptionsList(field)
                        : field.name === 'StatCode'
                        ? statCodeOptionList(field)
                        : field?.options?.map((x, i) => {
                            return { name: x.text, value: x.value };
                          })
                    }
                    value={field.value}
                    menuOption={(val: string, name: any) => {
                      if (field.name === 'RateType') {
                        setSelectedRateType(val);
                        const rateType = field?.options.find((x) => x.value === val);
                        if (rateType) {
                          rateType.text.toLowerCase().includes('reissue')
                            ? setIsReIssueSelected(true)
                            : setIsReIssueSelected(false);
                        }
                      }
                      updateJacket(jacketDetails.randomId, name, val.toString(), true);
                    }}
                    dropdowntype="outlined"
                    disabled={contentDisabled || field.isDisabled}
                    required={field.isRequired}
                    errs={validationErrors}
                    showValidation={shouldShowValidation}
                    pdfIcon={field.name === 'StatCode'}
                    onPdfClick={openStatCodePDF}
                    pdfQAAttribute={'Jacket' + field.name + 'PDF' + [index]}
                  />
                </Grid>
              )}
            </>
          </>
        );
      case 'checkbox':
        return (
          <>
            {field.name !== 'IsAgentAddressRequired' && (
              <Grid item sm={12}>
                <AgentNetCheckBox
                  label={field.label}
                  name={field.name}
                  data-testid={'Jacket' + field.name + [index]}
                  value={field.isChecked}
                  checkHandler={(val: any, name?: any) => {
                    updateJacket(jacketDetails.randomId, field.name, '', true, true);
                    field.name === 'IsExtendedCoverage' && field.isChecked === true
                      ? setExtendedCoverage(false)
                      : setExtendedCoverage(true);
                    if (policyDate != '') {
                      fetchRateTypes(formNumber, state, policyDate, extendedCoverage, county, account);
                    }
                  }}
                  disabled={contentDisabled || field.isDisabled}
                  required={field.isRequired}
                  // errs={validationErrors}
                  // showValidation={shouldShowValidation}
                />
              </Grid>
            )}
            {field.name === 'IsAgentAddressRequired' && field.isDisabled === false && (
              <Grid sm={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.isChecked}
                      disabled={contentDisabled}
                      name={field.name}
                      onChange={(e) => {
                        setIncludeAgentAddress(!field.isChecked);
                        updateJacket(jacketDetails.randomId, field.name, '', true, true);
                      }}
                      required={field.isRequired}
                    />
                  }
                  label={field.label}
                  value={field.isChecked}
                  data-testid={'Jacket' + field.name + [index]}
                />
              </Grid>
            )}
          </>
        );
      case 'radio': {
        const options = field.options.map((x) => {
          return { label: x.text, value: x.value, dataTestId: 'Jacket' + x.value + [index] };
        });
        field.value = field.value ?? options[0].value;
        const selectedOption = options?.find((option) => option.value === field.value);
        return (
          <Grid item sm={12}>
            <RadioGroup
              options={options}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateJacket(jacketDetails.randomId, field.name, e.target.value, true);
              }}
              selected={selectedOption}
              disabled={contentDisabled || field.isDisabled}
              data-testid={'Jacket' + field.name + [index]}
              // errs={validationErrors}
              // showValidation={shouldShowValidation}
            />
          </Grid>
        );
      }
      case 'textarea':
        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <AgentNetTextInput
              fullWidth
              multiline
              variant="outlined"
              label={field.label}
              name={field.name}
              data-testid={'Jacket' + field.name + [index]}
              value={field.value ?? ''}
              onChange={(e) => {
                updateJacket(jacketDetails.randomId, field.name, e.target.value, true);
              }}
              disabled={contentDisabled || field.isDisabled}
              required={field.isRequired}
              errs={validationErrors}
              showValidation={shouldShowValidation}
            />
          </Grid>
        );
      case 'date':
        return (
          <>
            {(field.name !== 'PriorPolicyDate' || (field.name === 'PriorPolicyDate' && isReIssueSelected)) && (
              <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
                <DateField
                  label={field.label}
                  name={field.name}
                  qaAttribute={'Jacket' + field.name + [index]}
                  value={field.value ?? ''}
                  onChange={(e) => {
                    updateJacket(jacketDetails.randomId, field.name, e, true);
                    if (field.name == 'PolicyDate') {
                      setPolicyDate('');
                      if (e) {
                        const date = new Date(e);
                        const minDate = new Date('12/21/1900');
                        const maxDate = new Date('12/21/2100');
                        if (date >= minDate && date <= maxDate) {
                          const localdate = moment(e).format('MM-DD-YYYY');
                          setPolicyDate(localdate);
                          fetchRateTypes(formNumber, state, localdate, extendedCoverage, county, account);
                          fetchEndorsements(state, underwriter, localdate);
                        }
                      }
                    }
                  }}
                  disabled={contentDisabled || field.isDisabled}
                  required={field.isRequired}
                  errs={validationErrors}
                  showValidation={shouldShowValidation}
                />
              </Grid>
            )}
          </>
        );
      case 'time':
        return (
          <Grid item sm={field.size == '1 of 4' ? 3 : field.size == '2 of 4' ? 6 : field.size == '3 of 4' ? 9 : 12}>
            <TimeField
              label={field.label}
              name={field.name}
              data-testid={'Jacket' + field.name + [index]}
              value={convertTime12to24(field.value)}
              onChange={(e) => {
                if (e) {
                  const time = e.toString();
                  const [hour, minute] = time.split(':').map(Number);
                  const period = hour >= 12 ? 'PM' : 'AM';
                  const hour12 = hour % 12 || 12;
                  const time12 = `${hour12.toString().padStart(2, '0')}:${minute
                    .toString()
                    .padStart(2, '0')} ${period}`;
                  updateJacket(jacketDetails.randomId, field.name, time12, true);
                }
              }}
              disabled={contentDisabled || field.isDisabled}
              required={field.isRequired}
              qaAttribute={'Jacket' + field.name + [index]}
              errs={validationErrors}
              showValidation={shouldShowValidation}
            />
          </Grid>
        );
      default:
        return '';
    }
  };
  const actionStatus = getJacketFieldsStatus === 'pending' ? 'pending' : 'idle';
  const renderJacketEndorsement = () => {
    return (
      <IncorporatedEndorsements
        index={index}
        onChangeIncorporatedEndorsement={updateIncorporatedEndorsement}
        endorsements={jacketDetails.jacketIncorporatedEndorsements}
        contentDisabled={contentDisabled}
        radioOptions={radioOptions}
        selectedRadioOption={selectedRadioValue}
      />
    );
  };

  const policyTypesOptions: RadioProps[] = [
    { label: 'All', value: 'All' },
    { label: 'Loan', value: 'Loan' },
    { label: 'Owner', value: 'Owner' },
  ];

  const updateJacketTypesOptions = (policyTypes: PolicyType[], selectedOption: RadioProps) => {
    const jacketOptions = policyTypes?.map((x, i) => {
      return { name: x.policyName, value: x.formNumber };
    });
    setJacketTypeOptions(jacketOptions ?? []);
    setIsJacketFieldsAvailable(false);
    updateJacket(jacketDetails.randomId, 'jacketTypeChange', '', false);
  };

  const onJacketTypesOptionChange = (jacketType: string) => {
    setFormNumber('');
    setShouldShowValidation(false);
    setExtendedCoverage(false);
    updateJacket(jacketDetails.randomId, 'formNumber', '', false);
    const filteredJacketTypes = jacketTypes?.filter((x) => !x.isDecommissioned);
    switch (jacketType) {
      case 'All': {
        updateJacketTypesOptions(filteredJacketTypes ? filteredJacketTypes : [], allPolicyTypes);
        break;
      }
      case 'Loan': {
        const loanJackets = filteredJacketTypes?.filter(
          (jacketType) => jacketType.insuredName === 'Loan' || jacketType.insuredName === 'OwnerLoan',
        );
        updateJacketTypesOptions(loanJackets ? loanJackets : [], loanPolicyTypes);
        break;
      }
      case 'Owner': {
        const ownerJackets = filteredJacketTypes?.filter(
          (jacketType) => jacketType.insuredName === 'Owner' || jacketType.insuredName === 'OwnerLoan',
        );
        updateJacketTypesOptions(ownerJackets ? ownerJackets : [], ownerPolicyTypes);
        break;
      }
      case 'Other': {
        const otherJackets = filteredJacketTypes?.filter(
          (jacketType) =>
            jacketType.insuredName !== 'Owner' &&
            jacketType.insuredName !== 'OwnerLoan' &&
            jacketType.insuredName !== 'Loan',
        );
        updateJacketTypesOptions(otherJackets ? otherJackets : [], otherPolicyTypes);
        break;
      }
    }
  };

  const renderPolicyTypeComponent = () => {
    if (jacketTypes === null || jacketTypes === undefined || jacketTypes?.length <= 0) {
      return <></>;
    }
    const otherJacketTypes = jacketTypes?.filter(
      (jacketType) =>
        jacketType.insuredName !== 'Owner' &&
        jacketType.insuredName !== 'OwnerLoan' &&
        jacketType.insuredName !== 'Loan',
    );
    const options = otherJacketTypes?.length > 0 ? [...policyTypesWithOtherTypes] : [...policyTypesOptions];
    const selectedOption = options?.find((option) => option.value === getCurrentJacketType());
    return (
      <div className={classes.jacketTypesRadioOptions}>
        <RadioGroup
          options={options}
          onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
            const fieldName = el.target.value;
            onJacketTypesOptionChange(fieldName);
          }}
          selected={selectedOption}
          compact
          disabled={contentDisabled || isPolicyTypeDisabled || isEditDisabled}
        />
      </div>
    );
  };

  return (
    <>
      <LoadingSpinner status={actionStatus} variant="linear" />
      <Grid className={classes.root}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            {renderPolicyTypeComponent()}
            <PolicyTypeAhead
              name="policyType"
              required
              label="Policy Type"
              value={jacketDetails?.formNumber}
              dataTestId={'JacketPolicyType' + [index]}
              options={jacketTypeOptions ?? null}
              onChange={(val: any) => {
                setFormNumber(val);
                setShouldShowValidation(false);
                setExtendedCoverage(false);
                setRateTypeOptions([]);
                disableCreate(true);
                enableCancelJacket(true);
                setPolicyDate('');
                updateJacket(jacketDetails.randomId, 'formNumber', val, false);
                if (val === '') {
                  updateJacket(jacketDetails.randomId, 'jacketTypeChange', '', false);
                  setIsJacketFieldsAvailable(false);
                  disableCreate(false);
                }
              }}
              disabled={contentDisabled || isPolicyTypeDisabled || isEditDisabled}
            />
          </Grid>
          {isJacketFieldsAvailable && (
            <>
              {lenderOptions?.length > 0 && isLenderPolicy && (
                <Grid item sm={6} className={classes.fieldPadding}>
                  <AgentNetDropdownSelector
                    name="associateToLender"
                    label="Associate to Lender"
                    qaAttribute={'JacketAssociateToLender' + [index]}
                    dropdowntype="outlined"
                    options={lenderOptions ?? null}
                    value={jacketDetails?.lenderId}
                    menuOption={(val: string) => {
                      updateJacket(jacketDetails.randomId, 'lenderId', val, false);
                    }}
                    disabled={contentDisabled || isPolicyTypeDisabled}
                  />
                </Grid>
              )}

              {!(lenderOptions?.length > 0 && isLenderPolicy) && <Grid item sm={6}></Grid>}

              {defaultFields?.length > 0 && defaultFields?.map((field) => <>{renderFieldComponent(field)}</>)}
              {additionalFields?.length > 0 && (
                <Grid item sm={12}>
                  <AgentNetDivider typoVariant="h3" title={'Additional Policy Fields'} disablePadding />
                </Grid>
              )}
              {fieldSections?.length > 0 &&
                fieldSections.map((fields) => {
                  if (fields && fields.length > 0) {
                    const containsDBA = fields.find((x: any) => x.name === 'AlternateDBA');
                    const containsAgentAddress = fields.find((x: any) => x.name === 'IsAgentAddressRequired');
                    if (containsDBA && includeAgentAddress === false && includeAgentAddressDisabled === false) {
                      return <></>;
                    }
                    if (containsAgentAddress && includeAgentAddressDisabled) {
                      return <></>;
                    }
                  }
                  return (
                    <>
                      <Grid className="jacketSection">
                        <Grid container spacing={3}>
                          {fields?.length > 0 &&
                            fields?.map((field: PolicyField) => <>{renderFieldComponent(field)}</>)}
                        </Grid>
                      </Grid>
                    </>
                  );
                })}

              {jacketDetails.jacketIncorporatedEndorsements?.length > 0 && renderJacketEndorsement()}
            </>
          )}
        </Grid>
        {isJacketFieldsAvailable && (
          <div className={classes.jacketEndorsement}>
            <Grid>
              <Endorsements
                index={index}
                onChangeEndorsement={updateJacketEndorsement}
                endorsements={jacketSelectedEndorsements}
                filterEndorsements={filterEndorsements}
                endorsementOptions={filteredEndorsements}
                isEditDisabled={contentDisabled}
                validationErrors={validationErrors}
                shouldShowValidation={shouldShowValidation}
                defaultEndorsements={defaultEndorsements}
                closeEndorsement={closeEndorsement}
              />
            </Grid>
          </div>
        )}
      </Grid>
    </>
  );
};
export default JacketForm;
