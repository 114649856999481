import { pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

interface OverflowTipProps {
  children: React.ReactNode;
  backgroundColor?: string;
  darkTheme?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  multiline?: boolean;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiTooltip-arrow::before': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
}))(Tooltip);

const DarkTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#616161',
    color: '#FFFFFF',
    border: '1px solid #616161',
    '& .MuiTooltip-arrow::before': {
      backgroundColor: '#616161',
      border: `1px solid #616161`,
    },
  },
}))(Tooltip);

const OverflowTip = ({ children, backgroundColor, darkTheme, placement, multiline }: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      base: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      isOverflowed: {
        position: 'relative',
        '&::after': {
          content: '"..."',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          background: backgroundColor ? backgroundColor : 'white',
          height: '100%',
          textAlign: 'right',
          fontSize: pxToRem(14),
          lineHeight: pxToRem(20),
          color: theme.palette.text.primary,
          fontWeight: 400,
        },
      },
      multiline: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        whiteSpace: 'normal',
        '&::after': {
          content: '""',
        },
      },
    }),
  );
  const classes = useStyles();
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  const checkOverflow = () => {
    if (textElementRef.current) {
      const hasOverflow =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth ||
        textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
      setIsOverflow(hasOverflow);
    }
  };
  useEffect(() => {
    //check initial overflow and watch for changes
    checkOverflow();
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });
    const mutationObserver = new MutationObserver(() => {
      checkOverflow();
    });
    if (textElementRef.current) {
      resizeObserver.observe(textElementRef.current);
      mutationObserver.observe(textElementRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);
  return darkTheme ? (
    <DarkTooltip
      title={children}
      disableHoverListener={!isOverflowed}
      interactive
      onClick={(e) => e.preventDefault()}
      placement={placement || 'right'}
      arrow
    >
      <div
        ref={textElementRef}
        className={clsx(classes.base, { [classes.isOverflowed]: isOverflowed, [classes.multiline]: multiline })}
      >
        {children}
      </div>
    </DarkTooltip>
  ) : (
    <LightTooltip
      title={children}
      disableHoverListener={!isOverflowed}
      interactive
      onClick={(e) => e.preventDefault()}
      placement={placement || 'right'}
      arrow
    >
      <div
        ref={textElementRef}
        className={clsx(classes.base, { [classes.isOverflowed]: isOverflowed, [classes.multiline]: multiline })}
      >
        {children}
      </div>
    </LightTooltip>
  );
};

export default OverflowTip;
