import { ArrowDropDown } from '@material-ui/icons';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { FieldValidationError } from 'utilities/validation/validation';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';
import states from './states.json';

export type stateType = {
  name: string;
  abbreviation: string;
  north?: number;
  south?: number;
  east?: number;
  west?: number;
};

export type StateSelectFieldProps = Partial<AgentTextInputProps> & {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  shouldDisplayAbbreviation?: boolean;
  shouldDisableClearable?: boolean;
  showValidation?: boolean;
  errs?: FieldValidationError[];
  disableTypeahead?: boolean;
  firstLoad?: boolean;
  stateOptions?: stateType[] | false;
  removeOption?: any[];
};

export type DomesticAddressFields = {
  [key: string]: string | boolean | undefined;
  address1?: string | undefined;
  address2?: string | undefined;
  city?: string | undefined;
  postalCode?: string | undefined;
  state?: string | undefined;
  county?: string | undefined;
};

export function StateSelectField({
  value: valueProp = '',
  onBlur = () => undefined,
  onChange = () => undefined,
  id = '',
  shouldDisplayAbbreviation = true,
  shouldDisableClearable = false,
  showValidation,
  disabled = false,
  errs,
  disableTypeahead,
  stateOptions,
  removeOption = [],
  tabIndex = 0,
  ...props
}: StateSelectFieldProps): JSX.Element {
  const getStates = useMemo(() => states.filter((item) => !removeOption.includes(item.abbreviation)), [removeOption]);

  const statesByAbbr: { [key: string]: stateType } = useMemo(
    () => getStates.reduce((prev, current) => ({ ...prev, [current.abbreviation]: current }), {}),
    [],
  );
  const [displayErrors, setDisplayError] = useState(showValidation);
  const [value, setValue] = useState<stateType | null>(null);

  useEffect(() => {
    if (value !== statesByAbbr[valueProp]) {
      setValue(statesByAbbr[valueProp] || null);
    }
  }, [valueProp]);
  useEffect(() => {
    if (showValidation) {
      setDisplayError(true);
    }
  }, [showValidation]);

  const filterStates = (options: stateType[], searchValue: FilterOptionsState<stateType>) => {
    const input = searchValue.inputValue.toLowerCase();

    if (input == '') {
      return options;
    }

    // Filter down state results that matches the first letter of the input
    const filteredOptions = options.filter((state: stateType) => {
      const stateResult = state.abbreviation.toLowerCase();
      return stateResult.includes(input) && stateResult[0] == input[0];
    });

    // Sort alphabetically
    return filteredOptions.sort((stateA, stateB) => {
      return stateA.abbreviation.localeCompare(stateB.abbreviation);
    });
  };

  const dropdownOptions = getStates.map((e: stateType) => {
    return { name: e.name, value: e.abbreviation };
  });

  return (
    <>
      {!disableTypeahead ? (
        <Autocomplete
          autoComplete
          blurOnSelect
          disableClearable={shouldDisableClearable}
          clearOnEscape
          disabled={disabled}
          autoSelect
          autoHighlight
          filterOptions={filterStates}
          onBlur={onBlur}
          options={stateOptions && stateOptions?.length > 0 ? stateOptions : getStates}
          getOptionLabel={(option: stateType) => (shouldDisplayAbbreviation ? option.abbreviation : option.name)}
          getOptionSelected={(option, state) => option.abbreviation === state.abbreviation}
          value={value}
          popupIcon={<ArrowDropDown fontSize="large" />}
          onChange={(_, value) => {
            onChange(value?.abbreviation || '');
            setValue(value);
          }}
          renderInput={(param) => (
            <AgentNetTextInput
              showValidation={showValidation}
              errs={errs}
              variant="outlined"
              label="State"
              onChange={(value) => {
                onChange(value);
              }}
              {...param}
              {...props}
              onBlur={(e) => {
                const container = stateOptions && stateOptions?.length > 0 ? stateOptions : getStates;
                const isFound = container.filter(
                  (item: { abbreviation: string }) =>
                    item.abbreviation.toLowerCase().search(e.target.value.toLowerCase()) === 0,
                )[0];
                onChange(isFound ? e.target.value : '');
                if (onBlur) onBlur(e);
              }}
              inputProps={{
                ...param.inputProps,
                'aria-label': 'state-field-input-select',
                maxLength: 2,
                title: value ? value.abbreviation : 'State',
              }}
              title={value ? value.abbreviation : 'State'}
              InputLabelProps={{ shrink: true }}
            />
          )}
          id={id}
          data-testid={id}
        />
      ) : (
        <AgentNetDropdownSelector
          label="State"
          options={dropdownOptions}
          value={value}
          dropdowntype="outlined"
          disabled={disabled}
          title={value ? value.abbreviation : 'State'}
          inputProps={{
            title: value ? value.abbreviation : 'State',
          }}
          menuOption={(option: string) => {
            onChange(option);
          }}
          errs={errs}
          showValidation={showValidation}
        />
      )}
    </>
  );
}
export default StateSelectField;
