import moment from 'moment-timezone';

class FormatDateUtils {
  public static MonthDateYear(date: string): string {
    if (!date) return '';
    return moment(date).format('MM/DD/YYYY');
  }

  public static MonthDateYearTime(date: string): string {
    if (!date) return '';
    return moment(date).format('MM/DD/YYYY hh:mm A');
  }
}

export default FormatDateUtils;
