import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useOrderInfo } from 'hooks/OrderInfoContext';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { AccountDetailRow } from './AccountInfoDetailRow';
import { DetailRow } from './DetailRow';
import { numberValueFormatter } from './fileListConfig';
import { OrderType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  orderDetailsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
  },
  subtitle: {
    whiteSpace: 'nowrap',
    width: 93,
  },
  sectionTitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const OrderInfoPanel: React.FC = () => {
  const noInfo = '-';
  const classes = useStyles();
  const location = useLocation<{ order: OrderType }>();
  const orderData = location.state?.order;
  const { selectedFilesAmount, accountInfoList } = useOrderInfo();

  const reportedNet = orderData?.reportedAmount || 0;
  const calculatedNet = selectedFilesAmount?.calculatedNet;
  const netDifference = parseFloat((calculatedNet - reportedNet).toFixed(2));
  const calculatedGross = selectedFilesAmount?.calculatedGross;
  const netDifferenceColor = netDifference !== 0 ? 'red' : 'green';

  return (
    <div>
      <Grid
        container
        className={`${classes.orderDetailsContainer} ${accountInfoList.length > 0 ? classes.borderBottom : ''}`}
      >
        <Grid item sm={12}>
          {/* Order Info Title */}
          <div className={classes.title}>
            <Typography variant="h4" className={classes.subtitle} color="textSecondary">
              Order Info
            </Typography>
          </div>
          <DetailRow label="Firm Name:" value={orderData?.firm?.firmName || noInfo} />
          <DetailRow
            label="Order Date:"
            value={orderData?.orderDate ? moment(orderData.orderDate).format('MM/DD/YYYY') : noInfo}
          />
          <DetailRow label="Lockbox Number:" value={orderData?.lockboxNumber?.name || noInfo} />
          <DetailRow label="Batch/Ticket Reference No:" value={orderData?.referenceNumber || noInfo} />
          <DetailRow label="No. of Checks:" value={orderData?.checkCount || 0} isLast />

          {/* Summary Title */}
          <div className={classes.title}>
            <Typography variant="h4" className={classes.subtitle} color="textSecondary">
              Summary
            </Typography>
          </div>

          <DetailRow label="Calculated Gross:" value={numberValueFormatter(calculatedGross, true)} isAmount />
          <DetailRow label="Reported Net:" value={numberValueFormatter(reportedNet, true)} isAmount />
          <DetailRow label="Calculated Net:" value={numberValueFormatter(calculatedNet, true)} isAmount />
          <DetailRow
            label="Net Difference:"
            value={numberValueFormatter(netDifference, true)}
            isAmount
            isEnd
            valueStyle={{ color: netDifferenceColor }}
            isLast
          />

          {accountInfoList.length > 0 && (
            <>
              {/* Account Info Title */}
              <div className={classes.sectionTitle}>
                <Typography variant="h4" className={classes.subtitle} color="textSecondary">
                  Account Info
                </Typography>
              </div>
              {accountInfoList.map((accountInfo, index) => (
                <AccountDetailRow
                  key={index}
                  accountNumber={accountInfo.accountNumber}
                  state={accountInfo.jurisdictionStateCode}
                  costCenter={accountInfo.costCenter}
                  split={accountInfo.contractualSplit}
                  underwriter={accountInfo.underwriterCode}
                  isNationalAgentFlag={accountInfo.isNationalAgentFlag}
                />
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderInfoPanel;
