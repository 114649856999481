import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import ErrorSection from '../../../features/files/file-create/ErrorSection';
import { FieldValidationError } from '../../../utilities/validation/validation';

export type AgentTextInputProps = TextFieldProps & {
  error?: boolean;
  helperText?: string | React.ReactNode;
  control?: Control;
  label?: string | React.ReactNode;
  rows?: string | number;
  errorMap?: Record<string, string[]>;
  startAdornment?: React.ReactNode;
  endAdornment?: JSX.Element;
  errs?: FieldValidationError[];
  id?: string;
  alwaysDisplayError?: boolean;
  value?: string;
  showValidationOnBlur?: boolean;
  showValidationOnFocus?: boolean;
  showValidation?: boolean;
  firstLoad?: boolean;
};

export function USPostalCodeField({
  endAdornment,
  startAdornment,
  InputProps,
  errs,
  variant,
  label,
  id,
  name,
  showValidationOnBlur = false,
  showValidationOnFocus = false,
  alwaysDisplayError,
  onFocus,
  onBlur,
  onChange,
  showValidation,
  value,
  firstLoad,
  ...rest
}: AgentTextInputProps): JSX.Element {
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(alwaysDisplayError);
  const cursorPos = useRef(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const displayErrorOnCallback = (callback: () => void) => {
    setDisplayError(true);
    callback();
  };
  useEffect(() => {
    const hasError = errs?.find((err) => err.field === id);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);
  const keyPress = (e: React.KeyboardEvent) => {
    const regex = new RegExp('^[0-9-]+$');
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };

  const formatPostalCode = (postalCode: string) => {
    if (postalCode && postalCode?.length > 5) {
      postalCode = postalCode.replace('-', '');
      postalCode = postalCode.substring(0, 5) + '-' + postalCode?.substring(5, postalCode?.length);
    }
    return postalCode;
  };

  const zipValue = useMemo(() => {
    return formatPostalCode(value as string);
  }, [value]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  const val = value as string;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const cursorPosition = e.target.selectionStart;

    // Check if "-" is already inserted
    const isUserTypingBeforeHyphen = newValue.length > 6 && newValue[6] === '-';

    let newCursorPos = cursorPosition ?? 0;

    if (cursorPosition === 6 && (!newValue.includes('-') || isUserTypingBeforeHyphen)) {
      newCursorPos += 1;
    }
    cursorPos.current = newCursorPos;

    const customEvent = Object.create(e);
    Object.defineProperties(customEvent, {
      target: {
        value: {
          value: formatPostalCode(newValue),
        },
      },
    });

    onChange?.(customEvent);
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.querySelector('input')?.setSelectionRange(cursorPos.current, cursorPos.current);
    }
  }, [zipValue]);

  return (
    <>
      <TextField
        ref={inputRef}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : undefined,
          endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
          onKeyPress: keyPress,
          ...InputProps,
        }}
        inputProps={{
          title: val ? val : label ? label.toString() : '',
        }}
        InputLabelProps={{ shrink: true }}
        error={errors && displayErrors}
        variant="outlined"
        label={label}
        onBlur={(e) => {
          showValidationOnBlur && displayErrorOnCallback(() => onBlur && onBlur(e));
        }}
        onFocus={(e) => {
          showValidationOnFocus && displayErrorOnCallback(() => onFocus && onFocus(e));
        }}
        onChange={handleChange}
        type="text"
        value={zipValue}
        {...rest}
      />
      {!firstLoad && errs && name && displayErrors && <ErrorSection errs={errs} field={name} />}
    </>
  );
}

export default USPostalCodeField;
