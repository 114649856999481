import { useAuth } from '@agentnet/auth';
import {
  DrawerComponent,
  DrawerContent,
  DrawerExit,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@fluentsms/agentnet-web-components';
import { Button, createStyles, IconButton, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import {
  AuditLog,
  getActionLogs,
  GetActionLogs,
  GetActionLogsResult,
  PostNotes,
  postNotes,
} from 'api/dashboards/service-orders';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { NoMessages } from 'ui-kit/components/NoMessages';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { AuditLogItem } from './AuditLogItem/AuditLogItem';
import { ConversationItem } from './ConversationItem/ConversationItem';
import { TabPanel } from './TabPanel/TabPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .ag-center-cols-viewport': {
        minHeight: 220,
      },
    },
    inputContainer: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      display: 'flex',
    },
    inputMessage: {
      background: 'none',
      border: 0,
      padding: theme.spacing(0, 2),
      width: '100%',
    },
    inputSubmit: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      color: theme.palette.actionSecondary.active,
      padding: theme.spacing(0.8, 1),
    },
    tabContent: {
      height: 'calc(100% - 64px)',
      overflow: 'hidden',
      overflowY: 'auto',
    },
  }),
);

interface ServiceOrderDrawerProps {
  serviceOrderPayload: GetActionLogs | undefined;
  serviceOrderNumber: string;
  onClose: () => void;
}

export function ServiceOrderDrawer({ serviceOrderPayload, serviceOrderNumber, onClose }: ServiceOrderDrawerProps) {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [tabValue, setTabValue] = useState<number>(0);
  const messageRef = useRef<HTMLInputElement>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [auditAndConversationsState, setAuditAndConversationsState] = useState<GetActionLogsResult>();
  const { addGlobalMsg } = useGlobalMessages();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getActionLogsData = async ({
    FileId,
    ServiceOrderReferenceId,
  }: GetActionLogs): Promise<GetActionLogsResult> => {
    const token = await getAccessToken();
    const response = await getActionLogs(
      {
        FileId,
        ServiceOrderReferenceId,
      },
      token,
    );
    setAuditAndConversationsState(response);
    return response;
  };

  const postNotesData = async ({ fileId, serviceOrderReferenceId, notes }: PostNotes): Promise<boolean> => {
    const token = await getAccessToken();
    const response = await postNotes(
      {
        fileId,
        serviceOrderReferenceId,
        notes,
      },
      token,
    );

    return response;
  };

  useEffect(() => {
    if (serviceOrderPayload) {
      getActionLogsData(serviceOrderPayload);
    }
  }, [serviceOrderPayload]);

  useEffect(() => {
    scrollToBottom();
  }, [auditAndConversationsState?.Notes, serviceOrderPayload]);

  const onTabChange = (e: ChangeEvent<unknown>, newValue: number): void => {
    setTabValue(newValue);
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (messageRef.current && messageRef.current.value.length > 0) {
      setIsFormSubmitting(true);

      if (auditAndConversationsState) {
        const { FileId, ServiceOrderReferenceId } = auditAndConversationsState;

        try {
          const payload: PostNotes = {
            fileId: FileId,
            serviceOrderReferenceId: ServiceOrderReferenceId,
            notes: messageRef.current.value,
          };
          const response = await postNotesData(payload);

          if (response) {
            const getPayload: GetActionLogs = {
              FileId,
              ServiceOrderReferenceId,
            };
            const logResponse = await getActionLogsData(getPayload);
            setAuditAndConversationsState(logResponse);
          }

          messageRef.current.value = '';
          setIsFormSubmitting(false);
        } catch (e) {
          let message = 'An unknown error occurred';

          if (e instanceof Error) {
            message = e.message;
          }

          addGlobalMsg({
            message,
            type: 'error',
          });

          setIsFormSubmitting(false);
        }
      }
    }
  };

  return (
    <>
      <DrawerComponent isDrawerOpen={serviceOrderPayload ? true : false}>
        <DrawerHeader
          content={<DrawerTitle title={`Order No: ${serviceOrderNumber}`} />}
          contentRight={
            <DrawerExit
              onClick={() => {
                serviceOrderPayload == undefined;
                onClose();
              }}
            />
          }
          divider
          subHeader={
            <Tabs
              aria-label="Audit and converation logs"
              indicatorColor="primary"
              value={tabValue}
              onChange={onTabChange}
            >
              <Tab aria-controls="conversation-panel" id="conversation-tab" label="Conversation" />
              <Tab aria-controls="audit-panel" id="audit-tab" label="Audit Logs" />
            </Tabs>
          }
        />
        <DrawerContent>
          <TabPanel
            aria-labelledby="conversation-tab"
            id="conversation-panel"
            index={0}
            value={tabValue}
            style={{ height: '100%' }}
          >
            <div className={classes.tabContent}>
              {auditAndConversationsState && auditAndConversationsState?.FileId > 0 ? (
                <>
                  {auditAndConversationsState?.Notes.length ? (
                    auditAndConversationsState?.Notes.map((note, index: number) => {
                      return <ConversationItem conversation={note} key={index} />;
                    })
                  ) : (
                    <NoMessages />
                  )}
                  <div ref={messagesEndRef} />
                </>
              ) : (
                ''
              )}
            </div>
            <form onSubmit={onFormSubmit}>
              <div className={classes.inputContainer}>
                <input
                  className={classes.inputMessage}
                  disabled={isFormSubmitting}
                  placeholder="Type a message..."
                  ref={messageRef}
                />
                <IconButton
                  className={classes.inputSubmit}
                  disabled={isFormSubmitting}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  type="submit"
                >
                  <SendOutlined fontSize="large" />
                </IconButton>
              </div>
            </form>
          </TabPanel>
          <TabPanel aria-labelledby="audit-tab" id="audit-panel" index={1} value={tabValue}>
            {auditAndConversationsState?.AuditLogs.map((log: AuditLog, index: number) => {
              return <AuditLogItem key={index} log={log} />;
            })}
          </TabPanel>
        </DrawerContent>
        <DrawerFooter>
          <Button color="primary" variant="contained" onClick={onClose}>
            Done
          </Button>
        </DrawerFooter>
      </DrawerComponent>
    </>
  );
}
