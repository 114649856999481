import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface AccountDetailRowProps {
  accountNumber: number;
  state: string;
  costCenter: string;
  split: number;
  underwriter: string;
  isNationalAgentFlag: boolean;
}

export const AccountDetailRow: FC<AccountDetailRowProps> = ({
  accountNumber,
  state,
  costCenter,
  split,
  underwriter,
  isNationalAgentFlag,
}) => {
  return (
    <Grid container direction="column" spacing={1} style={{ marginBottom: 15 }}>
      <Grid container item spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6" color="textSecondary" style={{ textTransform: 'none' }}>
            ACCOUNT NO. {accountNumber}
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {isNationalAgentFlag && 'NATIONAL'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6" color="textSecondary" style={{ textTransform: 'none' }}>
            State:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {state}
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <Typography variant="h6" color="textSecondary" style={{ textTransform: 'none' }}>
            Cost Center:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {costCenter}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6" color="textSecondary" style={{ textTransform: 'none' }}>
            Split:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {split}%
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <Typography variant="h6" color="textSecondary" style={{ textTransform: 'none' }}>
            Underwriter:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {underwriter}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
