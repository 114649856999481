import { useAuth } from '@agentnet/auth';
import { Alert } from '@agentnet/components';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { ButtonGroup, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  CellClickedEvent,
  ColDef,
  Column,
  GridReadyEvent,
  ICellRendererParams,
  ITooltipParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { getTransmittalSheet } from 'api/payment/api';
import {
  ReportFiles,
  addRemoveQueue,
  exportReports,
  getAdvancedSearchOrders,
  getPayOrdersWithRange,
  searchReports,
} from 'api/reports/api';
import clsx from 'clsx';
import PayDrawer from 'core/layout-core/core-content/PayDrawer';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import { format } from 'date-fns';
import { DocumentType, WindowFeatures, excelDocType, newWindow } from 'features/constants';
import {
  FileNumber as FileNumberRenderer,
  StatusRenderer,
} from 'features/files/files-summary/files-list/filesListConfigs';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import NoResults from 'ui-kit/components/noResults/NoResults';
import { NoResultsPayFiles } from 'ui-kit/components/noResults/NoResultsPayFiles';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import AutoReportingIcon from 'ui-kit/icons/AutoReportingIcon';
import ManageSearchIcon from 'ui-kit/icons/ManageSearchIcon';
import { AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { Pay_Manual_Only, Pay_Rights, View_Pay } from 'utilities/constants/activity-rights';
import { dateTooltipValue, errorTooltipValue, openDocument } from 'utilities/utilities';
import { searchFirmSchema } from 'utilities/validation/schemas/on-behalf-schema';
import { FieldValidationError, doValidate } from 'utilities/validation/validation';
import '../files/files-summary/files-list/FilesList.scss';
import CalculatorRenderer from './CalculatorRenderer';
import InvoiceSummary from './InvoiceSummary';
import PdfCellRenderer from './PdfCellRenderer';
import PolicyDateRenderer from './PolicyDateRenderer';
import './Remittance.scss';
import ReopenedFileIcon from './ReopenedFileIcon';
import {
  ErrorRenderer,
  advancedSearchFilters,
  advancedSearchViewText,
  cplFilters,
  filterParams,
  formatDate,
  noFilesText,
  numberValueFormatter,
  orderStatuses,
  pay,
  queueFilters,
  report,
  reportQueueButtonAttributes,
  restrictLimitReportingText,
  restrictReportingText,
  review,
  tabKBLinks,
  tabSubtitle,
} from './reportsListConfig';
import { AdvancedSearchRequest, AdvancedSearchResponse } from './types';
import useRatesAndFee from './useRatesAndFee';

export interface ReportsListInterface {
  contentClassName?: string;
}

type RouteParams = {
  action: string;
  firmId: string;
};

const ReportsList = ({ contentClassName }: ReportsListInterface) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [advancedSearchToggle, setAdvancedSearchToggle] = useState<boolean>(true);
  const [showAdvancedSearchMsg, setShowAdvancedSearchMsg] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { action, firmId } = useParams<RouteParams>();
  const tabName = action?.charAt(0).toUpperCase() + action?.slice(1);

  const getTableHeight = () => {
    let height;
    if (tabName === pay) {
      //pay table height value to subtract
      if (showAdvancedSearchMsg && advancedSearchToggle) {
        // both advanced search message and toggle are showing
        height = selectedRows?.length ? 522 : 479;
      } else if (showAdvancedSearchMsg) {
        // only advanced search message is showing
        height = selectedRows?.length ? 410 : 367;
      } else if (advancedSearchToggle) {
        // only advanced search toggle is showing
        height = selectedRows?.length ? 450 : 407;
      } else {
        // neither advanced search message nor toggle are showing
        height = selectedRows?.length ? 338 : 295;
      }
    }
    if (tabName === review) {
      //review table height value to subtract
      // 338 if rows are selected, 309 if not
      height = selectedRows?.length ? 338 : 295;
    }

    if (tabName === report) {
      //report table height value to subtract
      // 338 if rows are selected, 309 if not
      height = selectedRows?.length ? 353 : 309;
    }
    return `${height}px`;
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      advancedFiltersWrap: {
        padding: theme.spacing(0, 3),
      },
      advancedFiltersActions: {
        margin: theme.spacing(2, 0, 3),
      },
      paddingY: {
        paddingTop: theme.spacing(1),
      },
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - ${getTableHeight()}) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      selectedRows: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100% !important',
        fontSize: pxToRem(14),
        padding: theme.spacing(2, 3),
      },
      selectedRowsActive: {
        marginBottom: '59px',
        paddingBottom: 0,
      },
      mt0: {
        marginTop: 0,
      },
      mb0: {
        marginBottom: 0,
      },
      ml2: {
        marginLeft: theme.spacing(2),
      },
      advancedSwitchControl: {
        marginRight: theme.spacing(2),
      },
      cplFilters: {
        height: 32,
      },
      clearSearchButton: {
        fontWeight: 600,
        cursor: 'pointer',
      },
      advancedSearchText: {
        width: '100%',
        padding: theme.spacing(0, 3, 3),
      },
    }),
  );
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm, profile, preferredOffice } = profileCtx;
  const { addSnackbarMessage } = useSnackBars();
  const gridRef = useRef<any>(null);
  const gridReadyTimeout = useRef<any>(null);

  const [rowData, setRowData] = useState<any>([]);
  const queueFilesCount: any = {
    eligibleFiles: rowData?.filter((item: any) => !item.isMyQueue && !item.isFirmQueue).length,
    myQueueOnlyFiles: rowData?.filter((item: any) => item.isMyQueue).length,
    firmQueueOnlyFiles: rowData?.filter((item: any) => item.isFirmQueue).length,
  };
  const keyToSum = tabName === report ? 'net' : tabName === pay ? 'amountDue' : '';
  const sumWithReduce = selectedRows?.reduce(
    (accumulator: any, obj: { [x: string]: any }) => accumulator + obj[keyToSum],
    0,
  );
  const columnToHide = 'underwriter';
  const isNYStateFile = selectedRows?.some((row: any) => row['propertyState'] === 'NY');
  const isAllunderwritersSame = selectedRows?.every(
    (row: any) => row[columnToHide] === selectedRows[0]?.[columnToHide],
  );
  const isAllFirmsSame = selectedRows?.every((row: any) => row['firmId'] === selectedRows[0]?.['firmId']);
  const isFilesReportable = isAllunderwritersSame && isAllFirmsSame;
  const [isAllOpenStatus, setIsAllOpenStatus] = useState<boolean>(true);
  const displayFileReopenedIcon = rowData?.length > 0 && rowData.some((row: any) => row['isReopened']);
  const isReopened = selectedRows?.length > 0 && selectedRows.some((row: any) => row['isReopened']);
  const [showOnlyOpenStatusMsg, setShowOnlyOpenStatusMsg] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const [reportFirmId, setReportFirmId] = useState<string | null>(null);
  const [reportFileOrderIds, setReportFileOrderIds] = useState<number[]>([]);
  const [payGridReady, setPayGridReady] = useState(false);
  const [userFirmPreferences, setUserFirmPreferences] = useState(userFirm);
  const filterModel = useRef({});
  const sortModel = useRef({});
  const [notifyEmail, setNotifyEmail] = useState<string>(profile?.emailAddress ?? '');
  const [openQueueModal, setOpenQueueModal] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [openUnableToProcessModal, setOpenUnableToProcessModal] = useState<boolean>(false);
  const [openInvoiceSummary, setOpenInvoiceSummary] = useState<boolean>(false);
  const [reportCLicked, setReportCLicked] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [resetData, setReset] = useState<boolean>(false);
  const [showGridLoader, setGridLoader] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [cplFilter, setCplFilter] = useState<string>('excludeCplOnlyFiles');
  const [advancedFilter, setAdvancedFilter] = useState<string>('orderNumber');
  const [advancedFilterInput, setAdvancedFilterInput] = useState<string>('');
  const advancedFilterLabel = advancedSearchFilters.find((filter) => filter.value === advancedFilter)?.name;

  const [clearSearchData, setClearSearchData] = useState<boolean>(false);

  const [queueFilter, setQueueFilter] = useState<string>('eligibleFiles');
  const totalFileCount = tabName === report ? queueFilesCount[queueFilter] : rowData?.length;
  let externalCplFilter = 'excludeCplOnlyFiles';
  let externalQueueFilter = 'eligibleFiles';
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const today = moment().startOf('day').format();
  const ninetyDaysAgo = moment().subtract(90, 'days').endOf('day').format();
  const [dateRange, setDateRange] = useState<{
    fromDate: string | null;
    toDate: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });
  const [paymentId, setPaymentId] = useState<number>(0);
  const [orderNumber, setOrderNumber] = useState<number>(0);

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    tabName,
  );

  const exportedFiles = { files: selectedRows?.map((data: any) => data?.fileId), email: notifyEmail };
  const reportAndPayLimit = 49;
  const exportedOrders = {
    orders: selectedRows?.map((order: any) => {
      return { orderNumber: order?.orderNumber, invoiceType: order?.invoiceType ?? 'Remittance' };
    }),
    firmId: reportFirmId ?? userFirm?.firmId,
  };
  const payFiles: any = {
    firmId: reportFirmId ?? userFirm?.firmId,
    action: isAllOpenStatus ? 'Pay' : 'View',
  };

  if (selectedRows && selectedRows.length > 0) {
    const orders = selectedRows
      .map((order: any) => {
        return { orderNumber: order?.orderNumber, invoiceType: order?.invoiceType ?? 'Remittance' };
      })
      .filter((order: any) => order?.orderNumber != null);
    const fileOrderIds = Array.from(
      new Set(
        selectedRows
          .map((data: any) => data?.fileOrderIds)
          .flat()
          .filter((fileOrderId: any) => fileOrderId),
      ),
    );

    if (orders?.length > 0) {
      payFiles.orders = orders;
    }
    if (fileOrderIds?.length > 0) {
      payFiles.fileOrderIds = fileOrderIds;
    } else if (reportFileOrderIds?.length > 0) {
      payFiles.fileOrderIds = reportFileOrderIds;
    }
  }

  const advancedSearchPayload: AdvancedSearchRequest = {
    firmId: userFirm?.firmId || '',
    ...(advancedFilter == 'dateRange' && {
      fromDate: moment(dateRange.fromDate).format('YYYY-MM-DD'),
      toDate: moment(dateRange.toDate).format('YYYY-MM-DD'),
    }),
    ...(advancedFilter == 'orderNumber' && { orderNumber: advancedFilterInput }),
    ...(advancedFilter == 'invoiceNumber' && { invoiceNumber: advancedFilterInput }),
    ...(advancedFilter == 'fileNumber' && { fileNumber: advancedFilterInput }),
    ...(advancedFilter == 'policyNumber' && { policyNumber: advancedFilterInput }),
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, [tabName]);

  const getReportData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await searchReports(token, userFirm?.firmId ?? '', tabName);
  };
  const {
    execute,
    status: reportListStatus,
    value: reportResults,
    errors: listErrors,
  } = useAsync<any>(getReportData, false);

  const getPayOrders = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload = {
      firmId: userFirm?.firmId,
      fromDate: moment(dateRange.fromDate ?? ninetyDaysAgo).format('YYYY-MM-DD'),
      toDate: moment(dateRange.toDate ?? today).format('YYYY-MM-DD'),
    };
    return await getPayOrdersWithRange(token, payload);
  };
  const {
    execute: executePayOrdersWithRange,
    status: payOrdersWithRangeStatus,
    value: payOrdersWithRangeResults,
    errors: payOrdersWithRangeErrors,
  } = useAsync<any>(getPayOrders, false);

  const getExportData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await exportReports(tabName === pay ? exportedOrders : exportedFiles, token, tabName);
  };
  const {
    execute: executeExportData,
    status: executeExportDataStatus,
    value: exportResults,
    errors: exportErrors,
  } = useAsync<any>(getExportData, false);

  const getQueueData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const type = queueFilter === 'eligibleFiles' ? 'add' : 'remove';
    return await addRemoveQueue(token, exportedFiles, type);
  };
  const {
    execute: executeQueueData,
    status: executeQueueDataStatus,
    value: queueResults,
    errors: queueErrors,
  } = useAsync<any>(getQueueData, false);

  const reportSelectedFiles = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await ReportFiles(exportedFiles, token, tabName);
  };
  const {
    execute: executeReportData,
    status: executeReportDataStatus,
    value: reportedFiles,
    errors: ReportErrors,
  } = useAsync<any>(reportSelectedFiles, false);

  const getTransmittalSheetDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getTransmittalSheet(token, paymentId);
  };
  const {
    execute: executeTransmittalSheet,
    status: executeTransmittalSheetStatus,
    value: transmittalSheetResults,
    errors: transmittalSheetErrors,
  } = useAsync<any>(getTransmittalSheetDetails, false);

  const getAdvancedSearchDetails = async (): Promise<AdvancedSearchResponse[]> => {
    const token = await getAccessToken();
    return await getAdvancedSearchOrders(token, advancedSearchPayload);
  };
  const {
    execute: executeAdvancedSearch,
    status: executeAdvancedSearchStatus,
    value: executeAdvancedSearchResults,
    errors: advancedSearchErrors,
  } = useAsync<any>(getAdvancedSearchDetails, false);

  const getUpdatedData = () => {
    if (!resetData && userFirm?.firmId) execute().then();
  };

  useEffect(() => {
    if (firmId !== 'invoice-summary') {
      const openStatus =
        tabName !== pay ||
        !selectedRows?.length ||
        selectedRows.every(
          (row: any) =>
            row['orderStatus'] === orderStatuses['open'] ||
            row['orderStatus'] === orderStatuses['manualPay'] ||
            row['orderStatus'] === orderStatuses['wirePay'],
        ) ||
        selectedRows?.[0]?.getPaymentData;
      setIsAllOpenStatus(openStatus);
    }
  }, [selectedRows, firmId]);

  useEffect(() => {
    resetFiltersAndSelection();
    tabName === pay ? executePayOrdersWithRange().then() : getUpdatedData();
  }, [tabName]);

  useEffect(() => {
    setNotifyEmail(profile?.emailAddress ?? '');
  }, [profile?.emailAddress]);

  useEffect(() => {
    if (selectedRows?.[0]?.initiateExport) {
      executeExportData().then();
      setSelectedRows([]);
    }
  }, [selectedRows?.[0]?.initiateExport]);

  useEffect(() => {
    if (selectedRows?.[0]?.initiateInvoiceAndPay) {
      history.push({
        pathname: `/accounting/remittance/pay/invoice-summary`,
        state: {
          selectedRows,
        },
      });
    }
  }, [selectedRows?.[0]?.initiateInvoiceAndPay]);

  useEffect(() => {
    const currentState: any = location?.state;
    const isInvoiceSummary = location.pathname.includes('/invoice-summary');
    if (currentState?.selectedRows) {
      switchToSummaryPage(
        currentState?.selectedRows,
        true,
        currentState?.localFirmID,
        currentState?.reportedFileOrderids,
      );
    } else if (isInvoiceSummary) {
      history.push('/accounting/remittance/pay');
    }
  }, [location?.state]);

  useEffect(() => {
    if (showOnlyOpenStatusMsg) {
      addSnackbarMessage({
        message: 'Only Open and pending payment orders with same underwriter can be submitted for payment',
        type: 'warning',
        onClose: () => setShowOnlyOpenStatusMsg(false),
      });
    }
  }, [showOnlyOpenStatusMsg]);

  const switchToSummaryPage = (
    currentSelectedRows: any,
    showSummary: boolean,
    localFirmID?: string | null,
    reportedFileOrderids?: number[],
  ) => {
    if (localFirmID) {
      setReportFirmId(localFirmID);
    }
    if (reportedFileOrderids) {
      setReportFileOrderIds(reportedFileOrderids);
    }
    setSelectedRows(currentSelectedRows);
    setOpenInvoiceSummary(showSummary);
    setReset(true);
    const resetTimer = setTimeout(() => {
      // immediately reset back to false to allow to navigate to different route
      setReset(false);
      clearTimeout(resetTimer);
    }, 0);
    setRowData([]);
  };

  const [selectedOfficesState, setSelectedOfficesState] = useState<any>([]);
  const [officeNames, setOfficeNames] = useState<any>([]);
  useEffect(() => {
    let selectedPreferredOffices = preferredOffice?.filter(
      (ele: any) => ele.FirmID == userFirm?.firmId && ele.IsSelected == 1,
    );
    if (preferredOffice?.length > 0) {
      if (selectedPreferredOffices?.length === 0) {
        selectedPreferredOffices = preferredOffice?.filter((ele: any) => ele.FirmID == userFirm?.firmId);
      }
      const selectedoffices = userFirmPreferences?.offices
        ?.map((office: any) => ({
          ...office,
          accounts: office.accounts.filter((account: any) =>
            selectedPreferredOffices?.some(
              (preferredOffice: any) => preferredOffice.FirmLocationJurisdictionID === account.accountId,
            ),
          ),
        }))
        .filter((office: any) => office.accounts.length > 0);
      setSelectedOfficesState(selectedoffices);

      setUserFirmPreferences(userFirm);
    }
  }, []);
  useEffect(() => {
    setOfficeNames(selectedOfficesState.map((office: { name: any }) => office.name));
  }, [selectedOfficesState]);

  useEffect(() => {
    if (tabName === pay) {
      setRowData(payOrdersWithRangeResults);
    } else if (tabName === review) {
      const filteredReportResults =
        officeNames && officeNames.length > 0
          ? reportResults?.filter((report: any) => officeNames.includes(report.officeName))
          : reportResults;
      setRowData(filteredReportResults);
    } else {
      const filteredReportResults =
        officeNames && officeNames.length > 0 && !(officeNames.length === 1 && officeNames[0] === '0')
          ? reportResults?.filter(
              (report: any) => officeNames.includes(report.officeName) || report.isMyQueue || report.isFirmQueue,
            )
          : reportResults;
      setRowData(filteredReportResults);
    }
  }, [reportResults, payOrdersWithRangeResults, tabName, officeNames]);

  useEffect(() => {
    const hideColumnIfNeeded = () => {
      const columnApi = gridRef.current?.api;

      if (columnApi && (tabName === 'Report' || tabName === 'Pay')) {
        const isAllUnderwritersSame =
          (tabName === 'Report' &&
            reportResults?.every((row: any) => row[columnToHide] === reportResults?.[0]?.[columnToHide])) ||
          (tabName === 'Pay' &&
            payOrdersWithRangeResults?.every(
              (row: any) => row[columnToHide] === payOrdersWithRangeResults?.[0]?.[columnToHide],
            ));

        columnApi.setColumnsVisible([columnToHide], !isAllUnderwritersSame);
      }
    };

    if (gridRef.current?.api && (Array.isArray(reportResults) || Array.isArray(payOrdersWithRangeResults))) {
      setDisplayedRowCount(gridRef.current.api.getDisplayedRowCount());
      hideColumnIfNeeded();
    }

    if (gridRef?.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api?.showNoRowsOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [gridRef.current?.api, tabName, columnToHide, reportResults, payOrdersWithRangeResults]);

  const hasViewPayRight = useMemo(() => {
    return Boolean(
      profile?.activityRights?.find(
        (rights) => rights.ActivityRightId === Pay_Rights || rights.ActivityRightId === View_Pay,
      ),
    );
  }, [profile?.activityRights]);

  const hasPayRight = useMemo(() => {
    return Boolean(
      profile?.activityRights?.find(
        (rights) => rights.ActivityRightId === Pay_Rights || rights.ActivityRightId === Pay_Manual_Only,
      ),
    );
  }, [profile?.activityRights]);

  useEffect(() => {
    if (paymentId) executeTransmittalSheet().then();
  }, [paymentId]);

  useEffect(() => {
    if (executeReportDataStatus === 'success') {
      setReportFileOrderIds(reportedFiles?.fileOrderIds);
      setOpenReportModal(false);
      let localFirmID;
      if (selectedRows[0]?.['firmId']?.toString() !== userFirm?.firmId) {
        localFirmID = selectedRows[0]?.['firmId']?.toString();
        setReportFirmId(localFirmID);
      }
      if (reportCLicked) {
        setReportCLicked(false);
        if (hasViewPayRight) {
          history.push('/accounting/remittance/pay');
        } else {
          getUpdatedData();
        }
        addSnackbarMessage({
          message: `${selectedRows.length} Reporting Successful`,
          type: 'success',
        });
      } else {
        history.push({
          pathname: `/accounting/remittance/pay/invoice-summary`,
          state: {
            localFirmID,
            selectedRows,
            reportedFileOrderids: reportedFiles?.fileOrderIds,
          },
        });
      }
    }
  }, [executeReportDataStatus]);

  useEffect(() => {
    if (executeTransmittalSheetStatus === 'success') {
      if (transmittalSheetResults?.transmittalSheet)
        openDocument(transmittalSheetResults?.transmittalSheet, DocumentType, newWindow, WindowFeatures);
      setPaymentId(0);
    }
  }, [executeTransmittalSheetStatus]);

  useEffect(() => {
    if (executeAdvancedSearchStatus === 'success') {
      setShowAdvancedSearchMsg(true);
      setRowData(executeAdvancedSearchResults);
    }
  }, [executeAdvancedSearchStatus]);

  useEffect(() => {
    if (executeExportDataStatus === 'success') {
      if (exportResults?.excelData) {
        openDocument(exportResults?.excelData, excelDocType, newWindow, WindowFeatures);
      }
    }
  }, [executeExportDataStatus]);

  useEffect(() => {
    if (executeQueueDataStatus === 'success') {
      addSnackbarMessage({
        message: `File(s) were ${queueFilter === 'eligibleFiles' ? 'queued' : 'removed from queue'}`,
        type: 'success',
      });
      resetFiltersAndSelection(queueFilter, cplFilter);
      getUpdatedData();
      setOpenQueueModal(false);
    }
  }, [executeQueueDataStatus]);

  useEffect(() => {
    const isError =
      reportListStatus === 'error' ||
      executeExportDataStatus === 'error' ||
      executeQueueDataStatus === 'error' ||
      executeReportDataStatus === 'error' ||
      payOrdersWithRangeStatus === 'error' ||
      executeTransmittalSheetStatus === 'error' ||
      executeAdvancedSearchStatus === 'error';
    setDisplayError(isError);
  }, [
    reportListStatus,
    executeExportDataStatus,
    executeQueueDataStatus,
    executeReportDataStatus,
    payOrdersWithRangeStatus,
    executeTransmittalSheetStatus,
    executeAdvancedSearchStatus,
  ]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(listErrors ?? []),
        ...(exportErrors ?? []),
        ...(queueErrors ?? []),
        ...(ReportErrors ?? []),
        ...(payOrdersWithRangeErrors ?? []),
        ...(transmittalSheetErrors ?? []),
        ...(advancedSearchErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    doValidate(
      { notifyEmail, advancedFilterInput, fromDate: dateRange.fromDate, toDate: dateRange.toDate },
      searchFirmSchema(false),
    ).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
    if (tabName === pay) gridRef?.current?.api?.onFilterChanged();
  }, [notifyEmail, advancedFilterInput, dateRange.fromDate, dateRange.toDate]);

  const resetFiltersAndSelection = (qfilter = '', cplFilter = '') => {
    if (!resetData) {
      setSelectedRows([]);
      setOpenInvoiceSummary(false);
      setReportFirmId(null);
    }
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setFilterModel(null);
    externalFilterChanged(cplFilter || 'excludeCplOnlyFiles');
    externalFilterChanged(qfilter || 'eligibleFiles', true);
    setReset(false);
    setAdvancedSearchToggle(false);
    setAdvancedFilterInput('');
    setDateRange({
      fromDate: null,
      toDate: null,
    });
    setShowAdvancedSearchMsg(false);
    setAdvancedFilter('orderNumber');
    setShowValidationError(false);
  };

  const hasFutureDate = (val: any) => {
    return val?.policyDate && +new Date(val.policyDate) > +new Date();
  };
  const isRowSelectable = (params: any) => {
    return !hasFutureDate(params.data);
  };

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={`${tabName}AllCheckbox`} />;
  };

  const rowCheckboxRenderer = (params: any) => {
    const isDisabled = tabName === 'Report' && hasFutureDate(params.node.data);
    return <input type="checkbox" disabled={isDisabled} data-testid={`${tabName}File${params.node.id}Checkbox`} />;
  };

  const reviewColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 48,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? <ReopenedFileIcon iconOnly={true} /> : '';
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Error Category',
      field: 'errorCategory',
      filter: 'agSetColumnFilter',
      filterParams: {
        qaAttribute: 'ReviewErrorCategory',
        disabled: true,
        loseOnApply: true,
        suppressAndOrCondition: true,
      },
      cellRenderer: ErrorRenderer,
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'review');
      },
      tooltipValueGetter: errorTooltipValue,
    },
    {
      headerName: 'File Number',
      field: 'fileNumber',
      sortable: true,
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellRenderer: FileNumberRenderer,
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
        accountNumber: row.data.accountNumber,
        hideNavigation: !!row.data.sharedFileNumber,
      }),
      resizable: false,
    },
    {
      headerName: 'Office',
      field: 'officeName',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeName',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Policy Date',
      field: 'policyDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      colId: 'policyDateColumn',
      sort: 'asc',
      tooltipValueGetter: dateTooltipValue,
      maxWidth: 200,
    },
    {
      headerName: 'Buyer',
      field: 'buyerName',
      filter: 'agTextColumnFilter',
      tooltipField: 'buyerName',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Created Date',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      field: 'createdDate',
      cellRenderer: ({ value }: ICellRendererParams) => formatDate(value),
      tooltipValueGetter: dateTooltipValue,
      maxWidth: 200,
    },
    {
      field: '',
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: { tabName: tabName },
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'review');
      },
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
  ];

  const reportColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 48,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      checkboxSelection: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? <ReopenedFileIcon iconOnly={true} /> : '';
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'File Number',
      field: 'fileNumber',
      filter: 'agTextColumnFilter',
      tooltipField: 'fileNumber',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellRenderer: FileNumberRenderer,
      cellRendererParams: (row: ValueFormatterParams) => ({
        id: row.data.fileId,
        fileNumber: row.data.fileNumber,
        accountNumber: row.data.accountNumber,
        firmId: row.data.firmId?.toString(),
        hideNavigation: !!row.data.sharedFileNumber,
      }),
    },
    {
      headerName: 'Office',
      field: 'officeName',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeName',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    { headerName: 'Underwriter', field: 'underwriter', filter: 'agSetColumnFilter', tooltipField: 'underwriter' },
    {
      headerName: 'Policy Date',
      field: 'policyDate',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      colId: 'policyDateColumn',
      sort: 'asc',
      tooltipValueGetter: (params: ITooltipParams) => {
        return hasFutureDate(params.data)
          ? 'Future dated policies cannot be Reported'
          : moment(params.value).format('MM/DD/YYYY');
      },
      maxWidth: 200,
    },
    {
      headerName: 'Gross',
      field: 'gross',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Net',
      field: 'net',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      field: '',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      floatingFilter: false,
      sortable: false,
      cellRenderer: CalculatorRenderer,
      cellRendererParams: { tabName: tabName },
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'report');
      },
      maxWidth: 50,
      cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
      tooltipValueGetter: () => 'Open Rates and Fees',
      pinned: 'right',
    },
  ];

  const payColumnsConfig: ColDef[] = [
    {
      field: '',
      maxWidth: 48,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponent: headerCheckboxRenderer,
      cellRenderer: rowCheckboxRenderer,
      resizable: false,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isReopened ? (
          <ReopenedFileIcon iconOnly={true} />
        ) : params?.data?.isOrderAutoReport ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title="Auto-Reported Order" enterDelay={300} placement="right" arrow>
              <span style={{ display: 'flex' }}>
                <AutoReportingIcon fontSize="small" />
              </span>
            </Tooltip>
          </div>
        ) : null;
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      tooltipField: 'orderNumber',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Order Date',
      field: 'orderDate',
      filterParams: filterParams,
      cellRenderer: PolicyDateRenderer,
      sort: 'desc',
      filter: 'agDateColumnFilter',
      tooltipValueGetter: dateTooltipValue,
      minWidth: 190,
      maxWidth: 190,
    },
    {
      headerName: 'Order Status',
      field: 'orderStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultOption: 'Open',
      },
      tooltipField: 'orderStatus',
      cellRenderer: StatusRenderer,
    },
    {
      headerName: 'Invoice Type',
      field: 'invoiceType',
      tooltipField: 'invoiceType',
      filter: 'agSetColumnFilter',
    },
    { headerName: 'Underwriter', field: 'underwriter', filter: 'agSetColumnFilter', tooltipField: 'underwriter' },
    {
      headerName: 'Gross',
      field: 'gross',
      tooltipField: 'gross',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Net',
      field: 'net',
      tooltipField: 'net',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Payments/Credits',
      field: 'paymentsCredits',
      tooltipField: 'paymentsCredits',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
    },
    {
      headerName: 'Amount Due',
      field: 'amountDue',
      tooltipField: 'amountDue',
      filter: 'agTextColumnFilter',
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressHeaderFilterButton: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      maxWidth: 130,
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: PdfCellRenderer,
      cellRendererParams: {
        paymentId: paymentId,
        orderNumber: orderNumber,
        onClick: (paymentId: number, orderNumber: number) => {
          setPaymentId(paymentId);
          setOrderNumber(orderNumber);
        },
        executeTransmittalSheetStatus: executeTransmittalSheetStatus,
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
    {
      field: '',
      maxWidth: 50,
      filter: false,
      floatingFilter: false,
      cellRenderer: () => {
        return <span style={{ position: 'relative', top: '10px' }}>{ManageSearchIcon({ fontSize: 'small' })}</span>;
      },
      onCellClicked: (event: CellClickedEvent) => {
        handleDrawerOpen(event, 'pay');
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const columnDefs = useMemo(() => {
    return tabName === review ? reviewColumnsConfig : tabName === report ? reportColumnsConfig : payColumnsConfig;
  }, [tabName, executeTransmittalSheetStatus]);

  const getDisplayedRowCount = () => {
    if (gridRef.current?.api) return gridRef.current.api.getDisplayedRowCount();
    return 0;
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current?.api?.getSelectedRows();
    setSelectedRows(selectedRows);
  }, []);

  const onFilterChanged = () => {
    if (gridRef.current?.api) {
      const filterModel = gridRef.current.api.getFilterModel() ?? {};
      if (filterModel.errorCategory) {
        let errorCode = filterModel.errorCategory?.type;
        errorCode = errorCode === 'Stat Code(s)' ? 'Stat' : errorCode;
        errorCode === 'All' ? gridRef.current.api.setQuickFilter('') : gridRef.current.api.setQuickFilter(errorCode);
      }
      if (gridRef.current.api.getDisplayedRowCount() === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const externalFilterChanged = useCallback((newValue: string, isQueueFilter = false) => {
    if (!isQueueFilter) {
      setCplFilter(newValue);
      externalCplFilter = newValue;
    } else {
      setQueueFilter(newValue);
      externalQueueFilter = newValue;
    }
    gridRef.current?.api?.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback((): boolean => {
    return tabName === report || externalCplFilter !== 'allFiles' || tabName === pay;
  }, []);

  const doesExternalFilterPassReview = useCallback(
    (node: any): boolean => {
      if (node.data) {
        switch (externalCplFilter) {
          case 'cplOnlyFiles':
            return node.data.isCplOnly;
          case 'excludeCplOnlyFiles':
            return !node.data.isCplOnly;
          default:
            return true;
        }
      }
      return true;
    },
    [externalCplFilter],
  );

  const doesExternalFilterPassReport = useCallback(
    (node: any): boolean => {
      if (node.data) {
        let isCplFilterPassed = true;
        let isQueueFilterPassed = true;

        if (externalCplFilter === 'cplOnlyFiles') {
          isCplFilterPassed = node.data.isCplOnly;
        } else if (externalCplFilter === 'excludeCplOnlyFiles') {
          isCplFilterPassed = !node.data.isCplOnly;
        }

        if (externalQueueFilter === 'firmQueueOnlyFiles') {
          isQueueFilterPassed = node.data.isFirmQueue;
        } else if (externalQueueFilter === 'myQueueOnlyFiles') {
          isQueueFilterPassed = node.data.isMyQueue;
        } else if (externalQueueFilter === 'eligibleFiles') {
          isQueueFilterPassed = !node.data.isMyQueue && !node.data.isFirmQueue;
        }
        return isCplFilterPassed && isQueueFilterPassed;
      }
      return true;
    },
    [externalCplFilter, externalQueueFilter],
  );

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  const tabText: any = {
    review: `Selected ${selectedRows?.length} Files to export`,
    report: `Selecting ${selectedRows?.length} file(s) for ${numberValueFormatter(sumWithReduce, true)}`,
    pay: `Selecting ${selectedRows?.length} order(s) for ${numberValueFormatter(sumWithReduce, true)}`,
  };

  const selectedFilesText: string = tabText[action];

  const handleCloseQueueDialog = () => {
    if (executeQueueDataStatus === 'pending') return;
    setOpenQueueModal(false);
  };

  const handleUnableToProcessDialog = () => {
    setOpenUnableToProcessModal(false);
  };

  const handleCloseReportDialog = () => {
    setOpenReportModal(false);
    setReportCLicked(false);
    setShowValidationError(false);
    setNotifyEmail(profile?.emailAddress ?? '');
  };

  const handlePrimaryAction = () => {
    if (tabName === review) {
      executeExportData().then();
    } else if (tabName === report) {
      if (isReopened) {
        setOpenUnableToProcessModal(true);
      } else {
        setOpenReportModal(true);
      }
    } else {
      if (isReopened) {
        setOpenUnableToProcessModal(true);
      } else if (isAllOpenStatus && isAllunderwritersSame) {
        history.push({
          pathname: `/accounting/remittance/pay/invoice-summary`,
          state: {
            selectedRows,
          },
        });
      } else {
        setShowOnlyOpenStatusMsg(true);
      }
    }
  };

  const goBackAction = () => {
    setReportFirmId(null);
    setReset(false);
    setSelectedRows([]);
    setReportFileOrderIds([]);
    setOpenInvoiceSummary(false);
    history.replace('/accounting/remittance/pay');
    executePayOrdersWithRange().then();
  };

  const handleOnSearch = () => {
    if (
      (advancedFilter !== 'dateRange' && validationErrors?.some((err) => err.field === 'advancedFilterInput')) ||
      (advancedFilter === 'dateRange' &&
        validationErrors?.some((err) => err.field === 'fromDate' || err.field === 'toDate'))
    ) {
      setShowValidationError(true);
    } else {
      executeAdvancedSearch().then(() => {
        if (gridRef.current?.api) {
          const tempFilter: { [key: string]: string | null } = {};
          const allColumns: Column[] = gridRef.current.api.getAllGridColumns?.() ?? [];
          allColumns.forEach((column) => {
            const colId = column.getColId();
            const colDef = column.getColDef(); // Check if the column is using a set filter (dropdown filter)
            if (colDef.filter === 'agSetColumnFilter') {
              // Set the filter to 'Select All' (empty value means all selected)
              tempFilter[colId] = null;
            }
          }); // Apply the updated filter model
          gridRef.current.api.setFilterModel?.(tempFilter);

          //reset selected rows
          setSelectedRows([]);
          setDisplayedRowCount(gridRef.current.api?.getDisplayedRowCount());
        }
      });
    }
  };

  const handleClearSearch = () => {
    setDateRange({
      fromDate: null,
      toDate: null,
    });
    setAdvancedFilter('orderNumber');
    setAdvancedFilterInput('');
    setShowValidationError(false);
    if (showAdvancedSearchMsg) {
      setClearSearchData(true);
    }
  };

  useEffect(() => {
    if (clearSearchData) {
      setShowAdvancedSearchMsg(false);
      executePayOrdersWithRange().then(() => {
        filterModel.current = {};
        sortModel.current = {};
      });
      setSelectedRows([]);
      setClearSearchData(false);
    }
  }, [clearSearchData]);

  useLayoutEffect(() => {
    if (gridRef?.current?.api && payGridReady) {
      gridRef?.current?.api?.setFilterModel?.({
        orderStatus: {
          values: ['Manual Pay in Process', 'Open', 'Wire in Process', 'In Process', 'ePay In Process'],
          filterType: 'set',
        },
      });
      setPayGridReady(false);
    }
  }, [gridRef?.current?.api, payGridReady]);

  const onGridReady = (params: GridReadyEvent) => {
    if (gridReadyTimeout?.current) {
      clearTimeout(gridReadyTimeout.current);
    }
    // apply filter after a delay
    gridReadyTimeout.current = setTimeout(() => {
      onGridReadyFilter(params);
      clearTimeout(gridReadyTimeout.current);
    }, 0);
  };

  const onGridReadyFilter = (params: any) => {
    if (filterModel.current) {
      if (tabName === pay && Object.keys(filterModel.current).length === 0) {
        setPayGridReady(true);
      } else {
        params?.api?.setFilterModel?.(filterModel.current);
      }
      filterModel.current = {};
    }

    if (sortModel.current) {
      params?.api?.applyColumnState?.({
        state: sortModel.current,
        applyOrder: true,
      });
      sortModel.current = {};
    }

    if (selectedRows?.length > 0 && tabName !== pay) {
      const tempSelectedRows: any[] = [];
      const selectedRowIds = selectedRows.map((row: any) => row.fileId);
      const filterCallback = tabName === report ? doesExternalFilterPassReport : doesExternalFilterPassReview;
      params?.api?.forEachNode((node: any) => {
        if (selectedRowIds.includes(node.data.fileId) && filterCallback(node)) {
          node.setSelected(true);
          tempSelectedRows.push(node.data);
        }
      });
      setSelectedRows(tempSelectedRows);
    }
    gridRef?.current?.api?.onFilterChanged();
    setDisplayedRowCount(params?.api?.getDisplayedRowCount());
  };

  useEffect(() => {
    if (reportListStatus === 'pending' || payOrdersWithRangeStatus === 'pending') {
      filterModel.current = gridRef?.current?.api?.getFilterModel?.() ?? {};
      sortModel.current = gridRef?.current?.api?.getColumnState?.() ?? {};
      setGridLoader(true);
      setDisplayedRowCount(0);
    } else {
      setGridLoader(false);
    }
  }, [reportListStatus, payOrdersWithRangeStatus]);

  const notifyReporting: ReactNode = (
    <>
      <Typography>{tabText[1]}</Typography>
      &nbsp;&nbsp;
      <Typography>
        We will notify you at the email address below when your orders are available for you to pay.
      </Typography>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <AgentNetTextInput
        variant="outlined"
        label="Email"
        name="notifyEmail"
        value={notifyEmail}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          setNotifyEmail(e.target.value);
        }}
        fullWidth
        helperText={
          (validationErrors ?? [])?.some((err) => err.field === 'notifyEmail') && showValidationError
            ? null
            : 'To add multiple email addresses, please separate email addresses with a semi-colon.'
        }
        disabled={false}
        showValidation={showValidationError}
        errs={validationErrors}
        required
      />
      &nbsp;&nbsp;
      {isNYStateFile ? (
        <Typography>
          I, {profile?.firstName} {profile?.lastName}, of {userFirm?.name} hereby certify that the Title Premium(s) were
          properly calculated and found to be accurate. I further certify that only permitted, proper fees and other
          charges have been made. I further certify that I have provided clear notice to the insured and/or borrower, as
          required, that the lender or other party may require the performance of additional services which are not
          necessary services in connection with the issuance of the title policy, and the issuance of the title policy
          is not dependent upon the performance of such additional services.
        </Typography>
      ) : null}
    </>
  );

  if (showGridLoader) {
    return <>{CustomOverlayLoading()}</>;
  }

  if (openInvoiceSummary) {
    return (
      <InvoiceSummary
        payFiles={payFiles}
        goBackAction={goBackAction}
        selectedRows={selectedRows}
        isAllOpenStatus={isAllOpenStatus}
      />
    );
  }

  function renderPayFilters() {
    return (
      <Grid container>
        <Grid item sm={3}>
          <AgentNetDropdownSelector
            name="advancedFilter"
            label="Search By"
            qaAttribute={`${tabName}Filter`}
            options={advancedSearchFilters ?? []}
            value={advancedFilter}
            id="cplFilter"
            dropdowntype="outlined"
            menuOption={(value: string) => {
              setAdvancedFilter(value);
              return false;
            }}
          />
        </Grid>
        {advancedFilter !== 'dateRange' ? (
          <Grid item sm={3}>
            <AgentNetTextInput
              className={classes.ml2}
              variant="outlined"
              label={advancedFilterLabel}
              name="advancedFilterInput"
              value={advancedFilterInput}
              onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setAdvancedFilterInput(e.target.value);
              }}
              showValidation={showValidationError}
              errs={validationErrors}
              required
              fullWidth
            />
          </Grid>
        ) : (
          <>
            <Grid item sm={3} className={classes.ml2}>
              <DateField
                fullWidth
                variant="outlined"
                label="Order Date - From"
                name="fromDate"
                qaAttribute={'MyReportFromDate'}
                required
                value={(() => {
                  const dateParsed = Date.parse(dateRange?.fromDate || '');
                  return dateParsed ? format(dateParsed, 'MM/dd/yyyy') : dateRange?.fromDate;
                })()}
                onChange={(e: string | null) =>
                  setDateRange((prevState) => ({
                    ...prevState,
                    fromDate: e !== '' ? moment(e).startOf('day').format() : null,
                  }))
                }
                disableFuture
                showValidation={showValidationError}
                errs={validationErrors}
              />
            </Grid>
            <Grid item sm={3} className={classes.ml2}>
              <DateField
                fullWidth
                variant="outlined"
                label="Order Date - To"
                name="toDate"
                qaAttribute="MyReportToDate"
                required
                value={(() => {
                  const dateParsed = Date.parse(dateRange?.toDate || '');
                  return dateParsed ? format(dateParsed, 'MM/dd/yyyy') : dateRange?.toDate;
                })()}
                onChange={(e: string | null) =>
                  setDateRange((prevState) => ({
                    ...prevState,
                    toDate: e !== '' ? moment(e).endOf('day').format() : null,
                  }))
                }
                disableFuture
                showValidation={showValidationError}
                errs={validationErrors}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  const actionButton: ReactNode = (
    <div onClick={handleClearSearch} className={classes.clearSearchButton}>
      Clear Search
    </div>
  );

  if (tabName !== pay && (!rowData || rowData.length === 0) && !advancedSearchToggle) {
    const pText =
      tabName === review ? noFilesText['review'] : tabName === report ? noFilesText['report'] : noFilesText['pay'];
    return (
      <div className={contentClassName}>
        <NoResults showButton={false} type="files" titleText={`No Files to ${tabName}`} pText={pText} />
      </div>
    );
  } else {
    return (
      <>
        <PageHeader
          title={`${tabName} Files`}
          subtitle={tabSubtitle[tabName as 'Review' | 'Report' | 'Pay']}
          menuItems={[
            {
              label: 'Knowledge Base',
              link: tabKBLinks[tabName as keyof typeof tabKBLinks],
            },
          ]}
          divider={false}
          contentRight={
            <>
              <Grid container>
                {tabName === pay ? (
                  <>
                    <Grid item sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={advancedSearchToggle}
                            onChange={() => {
                              setAdvancedSearchToggle((prev) => !prev);
                            }}
                            name="Advanced Search"
                            size="small"
                          />
                        }
                        label="Advanced Search"
                        labelPlacement="start"
                        className={classes.advancedSwitchControl}
                      />
                    </Grid>
                  </>
                ) : tabName !== pay ? (
                  <AgentNetDropdownSelector
                    name="reviewFilter"
                    data-testid={`${tabName}Filter`}
                    options={cplFilters ?? []}
                    value={cplFilter}
                    id="cplFilter"
                    dropdowntype="outlined"
                    menuOption={(value: string) => {
                      externalFilterChanged(value);
                      return false;
                    }}
                    className={classes.cplFilters}
                    primaryColor
                  />
                ) : null}
                {tabName === review ? (
                  displayFileReopenedIcon ? (
                    <ReopenedFileIcon />
                  ) : null
                ) : tabName === report ? (
                  <Grid item style={{ margin: pxToRem(0, 0, 16, 24), display: 'flex' }}>
                    <ButtonGroup>
                      {queueFilters.map((obj, index) => (
                        <AgentNetButton
                          key={obj?.value}
                          variant={queueFilter === obj?.value ? 'contained' : 'outlined'}
                          onClick={() => {
                            resetFiltersAndSelection(obj?.value, cplFilter);
                          }}
                          data-testid={reportQueueButtonAttributes[obj?.value]}
                          color="primary"
                          size="small"
                        >
                          {`${obj?.name} (${queueFilesCount[obj?.value]})`}
                        </AgentNetButton>
                      ))}
                    </ButtonGroup>
                  </Grid>
                ) : null}
              </Grid>
            </>
          }
        />

        {tabName === pay && advancedSearchToggle ? (
          <Grid container className={classes.advancedFiltersWrap}>
            {renderPayFilters()}

            <Grid container alignItems="center" className={classes.advancedFiltersActions}>
              <Grid item>
                <AgentNetButton color="primary" variant="outlined" size="small" onClick={handleClearSearch}>
                  Clear Search
                </AgentNetButton>
              </Grid>
              <Grid item>
                <AgentNetButton
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleOnSearch}
                  className={classes.ml2}
                  disabled={executeAdvancedSearchStatus === 'pending'}
                  loading={executeAdvancedSearchStatus === 'pending'}
                >
                  Search
                </AgentNetButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {tabName === pay && showAdvancedSearchMsg ? (
          <Grid item className={classes.advancedSearchText}>
            <Alert action={actionButton} severity="warning">
              {advancedSearchViewText}
            </Alert>
          </Grid>
        ) : null}
        <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
          <DataTable
            loading={showGridLoader}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChanged}
            animateRows={true}
            onGridReady={onGridReady}
            {...(tabName === 'Report' && { isRowSelectable })}
            isExternalFilterPresent={isExternalFilterPresent as any}
            doesExternalFilterPass={tabName === report ? doesExternalFilterPassReport : doesExternalFilterPassReview}
            components={{
              customDropdownFilter: FloatingFilterComponent,
              customOverlayLoading: CustomOverlayLoading,
            }}
            {...(tabName === pay && {
              noRowsOverlayComponent: NoResultsPayFiles,
              noRowsOverlayComponentParams: { handleOnClick: () => setAdvancedSearchToggle(true) },
            })}
          />
        </div>
        <div
          className={clsx(classes.paddingX, classes.selectedRows, {
            [classes.selectedRowsActive]: exportedFiles?.files?.length,
          })}
        >
          <span>
            Showing {displayedRowCount ?? getDisplayedRowCount()} of {totalFileCount}{' '}
            {tabName === 'Pay' ? 'orders' : 'files'}
          </span>
          {!selectedRows?.length && tabName === review ? null : selectedFilesText}
        </div>
        {selectedRows?.length ? (
          <BottomAppBar
            cancelLabel="Clear All"
            onCancel={() => resetFiltersAndSelection()}
            cancelDataQa={`${tabName}ClearAll`}
            showPrimary={tabName === review || hasPayRight}
            disablePrimary={
              (tabName === report &&
                (selectedRows.length > reportAndPayLimit || !isFilesReportable || sumWithReduce === 0)) ||
              (tabName === review && executeExportDataStatus === 'pending')
            }
            disabledPrimaryHoverText={
              sumWithReduce === 0
                ? 'You can Report $0.00 but you cannot pay $0.00.'
                : 'You need to report your selected files before you can pay. Once the orders are processed you will be able to pay via the Pay tab.'
            }
            primaryActionLabel={
              tabName === review ? 'Export' : tabName === report ? 'Report & Pay' : 'View Invoice & Pay'
            }
            onPrimaryClick={handlePrimaryAction}
            primaryActionDataQa={
              tabName === review ? `${tabName}Export` : tabName === report ? 'ReportReport&Pay' : 'ViewInvoice&Pay'
            }
            secondaryActionLabel={tabName === review ? '' : tabName === report ? 'Report' : 'Export'}
            onSecondaryClick={
              tabName === report
                ? () => {
                    setReportCLicked(true);
                    setOpenReportModal(true);
                  }
                : () => executeExportData().then()
            }
            secondaryActionDataQa={tabName === review ? '' : tabName === report ? 'ReportReport' : 'PayExport'}
            thirdActionLabel={
              tabName === report
                ? queueFilter === 'firmQueueOnlyFiles' || queueFilter === 'myQueueOnlyFiles'
                  ? 'Remove from Queue'
                  : 'Queue'
                : ''
            }
            {...(tabName === review && { primaryButtonLoader: executeExportDataStatus === 'pending' })}
            {...(tabName === pay && {
              secondButtonLoader: executeExportDataStatus === 'pending',
              disableSecondary: executeExportDataStatus === 'pending',
            })}
            {...(tabName === report && {
              thirdButtonLoader: executeQueueDataStatus === 'pending',
              disableThird: executeQueueDataStatus === 'pending',
              fourthButtonLoader: executeExportDataStatus === 'pending',
              disableFourth: executeExportDataStatus === 'pending',
            })}
            thirdActionDataQa={
              tabName === report
                ? queueFilter === 'firmQueueOnlyFiles' || queueFilter === 'myQueueOnlyFiles'
                  ? 'ReportRemoveFromQueue'
                  : 'ReportQueue'
                : ''
            }
            onThirdClick={
              queueFilter === 'eligibleFiles' ? () => executeQueueData().then() : () => setOpenQueueModal(true)
            }
            fourthActionLabel={tabName === report ? 'Export' : ''}
            onFourthClick={() => executeExportData().then()}
            fourthActionDataQa={`${tabName}Export`}
          />
        ) : null}
        {tabName === pay ? (
          <PayDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            setSelectedRows={setSelectedRows}
            hasPayRight={hasPayRight}
          />
        ) : (
          <ReportsAndPayDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            getUpdatedData={getUpdatedData}
            rateAndFeeExecute={rateAndFeeExecute}
            hasViewPayRight={hasViewPayRight}
          />
        )}
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationQueue"
          disabled={executeQueueDataStatus === 'pending'}
          onConfirm={() => executeQueueData().then()}
          open={openQueueModal}
          onDismissAction={handleCloseQueueDialog}
          dialogTitle="Remove From Queue"
          dialogBtnContent="Yes, Continue"
          dialogText="Removing file(s) from Queue.  Do you want to continue?"
        />
        <AgentNetConfirmationDialog
          qaAttrPrefix="UnableToProcessRequestReopen"
          onConfirm={() => handleUnableToProcessDialog()}
          open={openUnableToProcessModal}
          onDismissAction={handleUnableToProcessDialog}
          dialogTitle="Unable to Process Request"
          dialogTextHTML={
            <>
              <Typography variant="body1">
                <p className={classes.mt0}>
                  Resubmitted files will not be eligible for payment until an invoice is generated, to ensure proper
                  credits are applied.
                </p>
                <p className={classes.mb0}> Please uncheck any files marked as reopened and try again.</p>
              </Typography>
            </>
          }
          dialogBtnContent="Got it!"
          dialogActionBtnJustifyContent="flex-end"
          showSecondaryActionBtn={false}
        />
        <AgentNetConfirmationDialog
          qaAttrPrefix={isFilesReportable || reportCLicked ? 'ReportData' : 'ReportUnableProcessRequest'}
          disabled={executeReportDataStatus === 'pending'}
          onConfirm={() => {
            if (validationErrors.some((err) => err.field === 'notifyEmail')) {
              setShowValidationError(true);
            } else {
              (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
                ? executeReportData().then()
                : handleCloseReportDialog();
            }
          }}
          open={openReportModal}
          onDismissAction={handleCloseReportDialog}
          dialogTitle={
            selectedRows.length > reportAndPayLimit && reportCLicked
              ? 'Get Notified'
              : (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
              ? isNYStateFile
                ? 'NY Certification'
                : 'Reporting file(s)'
              : 'Unable to Process Request'
          }
          dialogBtnContent={
            selectedRows.length > reportAndPayLimit && reportCLicked
              ? 'Notify Me'
              : (isFilesReportable && selectedRows.length <= reportAndPayLimit) || reportCLicked
              ? 'Yes, Continue'
              : 'Got it!'
          }
          dialogText={
            !reportCLicked && selectedRows.length > reportAndPayLimit
              ? restrictLimitReportingText
              : isFilesReportable || reportCLicked
              ? isNYStateFile
                ? `I, ${profile?.firstName} ${profile?.lastName}, of ${userFirm?.name} hereby certify that the Title Premium(s) were properly calculated and found to be accurate. I further certify that only permitted, proper fees and other charges have been made. I further certify that I have provided clear notice to the insured and/or borrower, as required, that the lender or other party may require the performance of additional services which are not necessary services in connection with the issuance of the title policy, and the issuance of the title policy is not dependent upon the performance of such additional services.`
                : `Reporting ${selectedRows?.length} file(s) for ${numberValueFormatter(
                    sumWithReduce,
                    true,
                  )}. Do you want to continue?`
              : restrictReportingText
          }
          dialogTextHTML={
            !reportCLicked || (selectedRows.length <= reportAndPayLimit && reportCLicked) ? null : notifyReporting
          }
        />
      </>
    );
  }
};

export default React.memo(ReportsList);
