import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { useContext } from 'react';
import OverflowTip from 'ui-kit/components/utility/OverflowTip';
import { PartyTypeValue } from '../../api/file/constants';
import { FileDataContext } from '../../hooks/FileDataContext';
import { IGetBuyerSellerResponse } from './parties/interfaces/CreateBuyerSellerRequest';

const useStyles = makeStyles((theme) => ({
  fileDetailsWrap: {},
  fileDetailsContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  detailLast: {
    marginBottom: 0,
  },
  subtitle: {
    width: 93,
    whiteSpace: 'nowrap',
  },
  info: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(2),
  },
}));

const getPartyName = (
  party: IGetBuyerSellerResponse | undefined,
  partyType: PartyTypeValue | undefined,
): string | undefined => {
  if (!party) return '';
  if (partyType === PartyTypeValue.married) {
    const { spouse1, spouse2 } = party.couple || {};
    return spouse1?.lastName === spouse2?.lastName
      ? `${spouse1?.firstName} and ${spouse2?.firstName} ${spouse2?.lastName}`
      : `${spouse1?.firstName} ${spouse1?.lastName} and ${spouse2?.firstName} ${spouse2?.lastName}`;
  }

  if (party.individual?.firstName && party.individual?.lastName) {
    if (partyType === PartyTypeValue.individual) return `${party.individual?.firstName} ${party.individual?.lastName}`;
  }

  if (partyType === PartyTypeValue.entity) return party.entity?.name;
  if (partyType === PartyTypeValue.trust) return party.trustEstate?.name;

  return undefined;
};

const NavFileInfo = () => {
  const classes = useStyles();
  const noInfo = 'N/A';
  const { fileData } = useContext(FileDataContext) || {};
  const { fileNumber, properties, createdDate } = fileData || {};
  const buyerName = getPartyName(fileData?.buyers?.[0], fileData?.buyers?.[0]?.partyType);
  const sellerName = getPartyName(fileData?.sellers?.[0], fileData?.sellers?.[0]?.partyType);
  const property = properties?.[0] ? (
    <span>
      {properties[0].address1}
      {properties[0].address2 && <>, {properties[0].address2}, </>}
      <br /> {properties[0].city && `${properties[0].city}, `}
      {properties[0].state && `${properties[0].state} `}
      {properties[0].postalCode && `${properties[0].postalCode}`}
    </span>
  ) : (
    <span></span>
  );

  return (
    <div className={classes.fileDetailsWrap}>
      <Grid container className={classes.fileDetailsContainer}>
        <Grid item sm={12}>
          <div className={classes.detail}>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">
              File No:
            </Typography>
            <div className={classes.info}>
              <OverflowTip backgroundColor="#FAFAFA" darkTheme multiline>
                <Typography variant="body2" color="inherit">
                  {fileNumber}
                </Typography>
              </OverflowTip>
            </div>
          </div>
          <div className={classes.detail}>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">
              Property:
            </Typography>
            <div className={classes.info}>
              <Typography variant="body2" color="textPrimary">
                {property}
              </Typography>
            </div>
          </div>
          <div className={classes.detail}>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">
              Buyer:
            </Typography>
            <div className={classes.info}>
              <Typography variant="body2" color="textPrimary">
                {buyerName || noInfo}
              </Typography>
            </div>
          </div>
          <div className={classes.detail}>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">
              Seller:
            </Typography>
            <div className={classes.info}>
              <Typography variant="body2" color="textPrimary">
                {sellerName || noInfo}
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.detail, classes.detailLast)}>
            <Typography variant="h6" className={classes.subtitle} color="textSecondary">
              Created:
            </Typography>
            <div className={classes.info}>
              <Typography variant="body2" color="textPrimary">
                {createdDate ? moment(createdDate).format('MM/DD/YYYY') : noInfo}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NavFileInfo;
