import { createStyles, makeStyles, Theme } from '@agentnet/components';
import NestedRouteLayout from 'core/layout-core/NestedRouteLayout';
import HomeContainer from './components/Home/HomeContainer';
import { HELP_ROUTE } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      minHeight: 'fit-content',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    contents: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
    },
  }),
);

const HelpHomePage = ({ routes }: any): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.contents}>
      {(location.pathname === `${HELP_ROUTE}` || location.pathname === `${HELP_ROUTE}/`) && <HomeContainer />}
      <NestedRouteLayout {...{ routes }} />
    </div>
  );
};

export default HelpHomePage;
