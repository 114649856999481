import { useContext, useEffect, useMemo, useState } from 'react';
import { Pay_Manual_Only, Pay_Rights, Remit_Rights, View_Pay } from 'utilities/constants/activity-rights';
import { FileDataContext, FileDataContextInterface } from './FileDataContext';
import { ProfileContext, ProfileContextInterface } from './ProfileContext';

export const useNavPermissions = () => {
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm, prismUserProfile } = profileCtx;
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const {
    fileData,
    setFileData,
    setOpenRatesAndFeeDrawer,
    openRatesAndFeeDrawer,
    setOpenSaveModal,
    setRatesAndFeeDrawerClicked,
    isFormDirty,
  } = fileDataCtx;

  const [isApprovedAttorneyAccount, setIsApprovedAttorneyAccount] = useState<boolean>(false);

  useEffect(() => {
    const accountNumber = parseInt(window.sessionStorage.getItem('accountNumber') || '0', 10);
    if (fileData && userFirm?.offices)
      for (const office of userFirm.offices)
        for (const account of office.accounts)
          if (accountNumber === account.accountId) {
            setIsApprovedAttorneyAccount(account.clientType === 'Approved Attorney');
            break;
          }
  }, [fileData, userFirm]);

  const hasPermission = useMemo(() => {
    return {
      remit: Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === Remit_Rights)),
      pay: Boolean(
        profile?.activityRights?.find(
          (rights: any) =>
            rights.ActivityRightId === Pay_Rights ||
            rights.ActivityRightId === Pay_Manual_Only ||
            rights.ActivityRightId === View_Pay,
        ),
      ),
      payOnly: Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === Pay_Manual_Only)),
      billing: Boolean(
        profile?.activityRights?.find(
          (rights: any) => rights.ActivityRightId === Pay_Rights || rights.ActivityRightId === Pay_Manual_Only,
        ),
      ),
      documents: Boolean(
        !(
          profile?.roleId === 2 &&
          profile?.roleTypeId === 101 &&
          profile?.activityRights?.some((right) => right.ActivityRightId === 125)
        ),
      ),
      cpl: Boolean(profile?.roleId !== 2 && profile?.roleId !== 13),
      jackets: !isApprovedAttorneyAccount,
      manageUsers: Boolean(profile?.activityRights?.some((right) => right.ActivityRightId === 36)),
      behalfOfUser: Boolean(profile?.roleId === 3 || profile?.roleId === 5),
      notBehalfOfUser: Boolean(profile?.roleId === 2 || profile?.roleId === 13),
      hasFirmId: Boolean(userFirm?.firmId),
      systemAdmin: Boolean(profile?.roleId === 3),
      staffAdmin: Boolean(profile?.roleId === 13),
      agentNetAdmin: Boolean(profile?.activityRights?.find((right) => right.ActivityRightId === 36)),
      backtitle: Boolean(
        profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 22 || rights.ActivityRightId === 32),
      ),
      canRunBackTitleAdHoc: Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 22)),
      canRunBackTitleFiles: Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 32)),
      canRunPolicyUpload: Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 49)),
      filesList: Boolean(profile?.roleId !== 2 && profile?.roleId !== 13),
      orderManagement: Boolean(profile?.roleId === 14),
    };
  }, [profile, isApprovedAttorneyAccount, userFirm]);

  return {
    hasPermission,
    profile,
    userFirm,
    prismUserProfile,
    fileData,
    setFileData,
    setRatesAndFeeDrawerClicked,
    isFormDirty,
    setOpenRatesAndFeeDrawer,
    openRatesAndFeeDrawer,
    setOpenSaveModal,
  };
};
