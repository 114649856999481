import { cplData } from '../types';

export enum CPLStatus {
  open = 'Open',
  pending = 'Pending',
  void = 'Void',
  closed = 'Closed',
  pending_remit = 'Pending Remit',
  remitted = 'Remitted',
}

export enum CPLActions {
  edit = 'Edit',
  delete = 'Delete',
  void = 'Void',
  view = 'View',
  unvoid = 'Unvoid',
  expandCollapseActive = 'ExpandCollapseActive',
  expandCollapseInActive = 'ExpandCollapseInActive',
  onInactiveToggleChange = 'OnInactiveToggleChange',
}

export enum CoveredParties {
  lender = 'Lender',
  lenderBorrower = 'Lender / Borrower(s)',
  buyer = 'Buyer(s)',
  borrrower = 'Borrower(s)',
  buyerBorrower = 'Borrower(s) / Buyer(s)',
  seller = 'Seller(s)',
}

export type cplActiveActionProps = {
  cpl: cplData;
  rowId: number;
};

export const openCplsLimit =
  '5 active CPLs limit reached. Please void or delete a CPL if you would like to add a new one.';

export const noCplsFound = 'No Active CPLs found';

export const msgStandard =
  " The Standard Agent's Authorization Letter may be issued when requested by the parties involved in a proposed insured real estate title closing. The Standard Agent's Authorization Letter describes the scope of the title insurance agent's authority to prepare title reports, clear title exceptions and issue title insurance policies.";

export const msgLetterType =
  "The New York State Department of Insurance prohibits Title Underwriters from issuing Closing Protection Letters. The Closing Protection Denial Letter provides a cover letter and a copy of the Insurance Department's 1992 Memorandum which clearly forbids the issuance of Closing Protection Letter";

export enum CPLErrorCodes {
  badRequest = '400',
  notFound = '404',
  forbidden = '403',
}

export const MAX_OPEN_OR_PENDING_CPLS = 5;
export const MANUAL_CPL_ACTIVITY_ID = 124;

export const createNewCpl = 'Would you like to create one now?';

export const noCplSupport = 'No CPLs Found.  File Status does not support CPL creation';
