import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { getEpayAccount, getPaymentIframe, getTransmittalSheet, onSubmitPayment } from 'api/payment/api';
import { orderDetails } from 'api/reports/api';
import clsx from 'clsx';
import { DocumentType, KBLinks, newWindow, WindowFeatures } from 'features/constants';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useViewState } from 'hooks/ViewStateContext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import PageHeader from 'ui-kit/components/headers/PageHeader';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import PaymentComponent from 'ui-kit/components/payment/PaymentComponent';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import PDFIcon from 'ui-kit/icons/PDF';
import { Pay_Manual_Only } from 'utilities/constants/activity-rights';
import { openDocument } from 'utilities/utilities';
import InvoiceSummaryList from './InvoiceSummaryList';
import { PaymentDetailsType } from './types';

interface InvoiceSummaryProps {
  fileView?: boolean;
  hideBottomBar?: boolean;
  noResults?: boolean;
  hideFooter?: boolean;
  showResults?: boolean;
  payFiles?: any;
  selectedRows?: any;
  goBackAction: () => void;
  isAllOpenStatus: boolean;
}

const InvoiceSummary = ({
  payFiles,
  goBackAction,
  selectedRows,
  isAllOpenStatus,
}: InvoiceSummaryProps): JSX.Element => {
  const { menuCollapsed } = useViewState();
  const [drawerWidth, setDrawerWidth] = React.useState(menuCollapsed ? 508 : 743);
  React.useEffect(() => {
    setDrawerWidth(menuCollapsed ? 508 : 743);
  }, [menuCollapsed]);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
    },
    invoiceSummaryWrap: {
      flexGrow: 1,
    },
    invoiceSummaryHeader: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    invoiceSummaryPageHeader: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    invoiceSummaryContent: {
      padding: theme.spacing(0, 3, 6, 0),
      maxWidth: `calc(100vw - ${drawerWidth}px + 50px)`,
      [theme.breakpoints.up('lg')]: {
        maxWidth: `calc(100vw - ${drawerWidth}px + 20px)`,
      },
      height: '100%',
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    tabWrap: {
      padding: `0 ${theme.spacing(2)}px`,
      borderBottom: `1px solid #e5e5e5`,
    },
    invoiceSummaryCard: {
      padding: theme.spacing(3),
      border: `1px solid #c4c4c4`,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
    },
    invoiceSummaryCardTopRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    invoiceSummaryCardContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    mainWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: pxToRem(0, 24, 0),
      height: '75vh',
    },
    firmText: {
      padding: pxToRem(0, 24, 15),
    },
    paymentComponent: {
      position: 'fixed',
      right: '20px',
      overflowY: 'auto',
      maxHeight: '70vh',
    },
    iframe: {
      border: '0px #ffffff none',
      position: 'absolute',
      left: '8px',
      width: '100%',
      maxWidth: '370px',
      minHeight: '800px',
      marginTop: '16px',
    },
    heading: {
      marginBottom: '24px',
    },
    backBtn: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
  }));

  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const paymentMode = [
    { label: 'Credit or Debit Card', value: 'CD' },
    { label: 'ACH Transfer/Bank Account', value: 'ACH' },
  ];
  const { userFirm, profile, getFirmNameByFirmId } = profileCtx;
  const [orderData, setOrderData] = useState<any>([]);
  const [ePayAccounts, setEPayAccounts] = useState<any>([]);
  const [showValidation, setShowValidation] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [disableRadioOptions, setDisableRadioOptions] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>('ePay');
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState(false);
  const [isAch, setAch] = useState(true);
  const [selectedPaymentMode, setselectedPaymentMode] = useState<RadioProps>(paymentMode[1]);
  const [paymentTypes, setPaymentTypes] = useState<string[]>([]);
  const [defaultEpayAccount, setDefaultEpayAccount] = React.useState<any>(null);
  const totalAmountDueOpenFiles = useMemo(() => {
    return orderData?.orders?.reduce((acc: number, order: any) => {
      const orderTotal = order?.invoices?.reduce((sum: number, invoice: any) => sum + invoice?.amountDue, 0);
      return acc + orderTotal;
    }, 0);
  }, [orderData]);
  const isAmountPaid = orderData?.payment && orderData?.payment?.amount > 0;

  const underwritersSet = new Set();
  const invoiceTypesSet = new Set();

  selectedRows?.forEach((obj: any) => {
    underwritersSet.add(obj?.underwriter);
    invoiceTypesSet.add(obj?.invoiceType);
  });

  if (invoiceTypesSet.size === 0 || (invoiceTypesSet.size === 1 && invoiceTypesSet.has(undefined))) {
    invoiceTypesSet.clear();
    invoiceTypesSet.add('Remittance');
  }

  const ePayAccountsPayload = {
    underwriter: Array.from(underwritersSet).join(', '),
    invoiceTypes: Array.from(invoiceTypesSet).join(', '),
  };

  const paymentInfo = useRef<PaymentDetailsType>({
    firmId: payFiles?.firmId ?? userFirm?.firmId ?? '',
    amount: parseFloat(totalAmountDueOpenFiles?.toFixed(2) ?? 0),
    orders: [],
    paymentMethod: '',
    ePayType: undefined,
    ePayId: '',
    ePayName: '',
    checkNumber: '',
    wireNumber: '',
    email: '',
    memo: '',
  });

  const getOrderDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await orderDetails(payFiles, token);
  };
  const {
    execute: executeOrderDetails,
    status: executeOrderDetailsStatus,
    value: orderResults,
    errors: orderErrors,
  } = useAsync<any>(getOrderDetails, false);

  // get e-pay accounts only for open orders
  const getEPaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getEpayAccount(token, payFiles?.firmId ?? userFirm?.firmId ?? '', ePayAccountsPayload);
  };
  const {
    execute: executeEPaymentAccounts,
    status: executeEPaymentDetailsStatus,
    value: ePaymentResults,
    errors: ePaymentAccountsErrors,
  } = useAsync<any>(getEPaymentDetails, false);

  const onSubmitPaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await onSubmitPayment(token, paymentInfo.current);
  };
  const {
    execute: executeSubmitPayment,
    status: executeSubmitPaymentDetailsStatus,
    value: submitPaymentResults,
    errors: submitPaymentErrors,
  } = useAsync<any>(onSubmitPaymentDetails, false);

  const getTransmittalSheetDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getTransmittalSheet(token, orderData?.payment?.paymentId);
  };
  const {
    execute: executeTransmittalSheet,
    status: executeTransmittalSheetStatus,
    value: transmittalSheetResults,
    errors: transmittalSheetErrors,
  } = useAsync<any>(getTransmittalSheetDetails, false);

  const getIframeData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getPaymentIframe(
      token,
      payFiles?.firmId ?? userFirm?.firmId ?? '',
      isAch,
      Array.from(underwritersSet)?.[0] as string,
    );
  };

  const {
    execute: executeframeData,
    status: executeframeDataStatus,
    value: frameDataResults,
    errors: frameDataErrors,
  } = useAsync<any>(getIframeData, false);

  useEffect(() => {
    executeOrderDetails().then();
  }, []);

  const totalNoOfFiles = useMemo(() => {
    return (orderData?.orders ?? []).reduce(
      (count: number, currentOrder: any) => count + (currentOrder?.invoices?.length ?? 0),
      0,
    );
  }, [orderData?.orders]);

  useEffect(() => {
    if (isAllOpenStatus) {
      executeEPaymentAccounts().then();
    }
  }, [isAllOpenStatus, defaultEpayAccount?.value]);

  useEffect(() => {
    if (openPaymentDrawer) {
      executeframeData().then();
    }
  }, [openPaymentDrawer, isAch]);

  function processApiResponse(account: any) {
    if (account) {
      setDefaultEpayAccount({
        name: account?.counterPartyAccountName,
        value: account?.counterPartyAccountId,
        type: account?.counterPartyAccountType?.toUpperCase(),
      });
      setOpenPaymentDrawer(false);
    }
  }

  useEffect(() => {
    if (
      profile?.activityRights?.find((rights: any) => rights.ActivityRightId === Pay_Manual_Only) &&
      !orderData?.payment?.paymentStatus
    ) {
      setPaymentType('Check');
      setDisableRadioOptions(true);
    }
  }, [profile?.activityRights]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event?.data?.account) {
        processApiResponse(event.data.account);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const totalAmountDueOpenFiles = orderResults?.orders?.reduce((acc: number, order: any) => {
      const orderTotal = order?.invoices?.reduce((sum: number, invoice: any) => sum + invoice?.amountDue, 0);
      return acc + orderTotal;
    }, 0);
    paymentInfo.current = {
      ...paymentInfo.current,
      orders: orderResults?.orders?.map((order: any) => {
        return { orderNumber: order?.orderNumber, invoiceType: order?.invoices[0]?.invoiceType ?? 'Remittance' };
      }),
      amount: parseFloat(totalAmountDueOpenFiles?.toFixed(2) ?? 0),
    };

    setOrderData(orderResults);

    if (orderResults?.payment?.paymentMethod === 'ePay') {
      setEPayAccounts([
        {
          name: orderResults?.payment?.ePayName,
          value: orderResults?.payment?.ePayConfirmationNumber,
          type: orderResults?.payment?.isCCPayment ? 'CC' : 'ACH',
        },
      ]);
    }
  }, [orderResults]);

  const handlePaymentMethod = (val: string) => {
    const paymentMethod = paymentMode?.find((option) => option.value === val) ?? selectedPaymentMode;
    setAch(paymentMethod.value === `ACH`);
    setselectedPaymentMode(paymentMethod);
  };

  useEffect(() => {
    if (ePaymentResults?.paymentTypes?.length === 1) handlePaymentMethod(ePaymentResults?.paymentTypes?.[0]);
    setPaymentTypes(ePaymentResults?.paymentTypes);
    const epayAccountNames = ePaymentResults?.accounts
      ?.map((account: any) => ({
        name: account.nameOnAccount,
        value: account.accountAliasId,
        type: account.paymentType,
      }))
      ?.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setEPayAccounts(epayAccountNames ?? []);
  }, [ePaymentResults]);

  useEffect(() => {
    const isError =
      executeOrderDetailsStatus === 'error' ||
      executeEPaymentDetailsStatus === 'error' ||
      executeSubmitPaymentDetailsStatus === 'error' ||
      executeTransmittalSheetStatus === 'error';
    setDisplayError(isError);

    if (executeSubmitPaymentDetailsStatus === 'success') {
      if (submitPaymentResults?.transmittalSheet)
        openDocument(submitPaymentResults?.transmittalSheet, DocumentType, newWindow, WindowFeatures);

      addSnackbarMessage({
        message: `Payment successful`,
        type: 'success',
      });
      goBackAction();
    }

    if (executeTransmittalSheetStatus === 'success') {
      if (transmittalSheetResults?.transmittalSheet)
        openDocument(transmittalSheetResults?.transmittalSheet, DocumentType, newWindow, WindowFeatures);
    }
  }, [
    executeOrderDetailsStatus,
    executeEPaymentDetailsStatus,
    executeSubmitPaymentDetailsStatus,
    executeTransmittalSheetStatus,
  ]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(orderErrors ?? []),
        ...(ePaymentAccountsErrors ?? []),
        ...(submitPaymentErrors ?? []),
        ...(transmittalSheetErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  if (executeOrderDetailsStatus === 'pending') {
    return <> {CustomOverlayLoading()}</>;
  }

  const handleOnChangePaymentDetails = (data: any) => {
    paymentInfo.current = {
      ...paymentInfo.current,
      ...data,
    };
  };

  const handleSubmitPayment = (doNotExecutePayment: boolean) => {
    if (doNotExecutePayment) {
      setShowValidation(true);
    } else {
      executeSubmitPayment().then();
    }
  };

  const closeDrawer = () => {
    setAch(true);
    setselectedPaymentMode(paymentMode[1]);
    setOpenPaymentDrawer(false);
  };

  const disablePayment =
    (orderData?.payment?.paymentMethod ? true : false) ||
    orderData?.orders?.[0]?.orderNumber?.toLowerCase().includes('order in process');

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.invoiceSummaryHeader}>
        <AgentNetButton
          variant="text"
          size="small"
          color="primary"
          startIcon={<ArrowBack />}
          onClick={goBackAction}
          data-testid="PaySumBack"
          className={classes.backBtn}
        >
          Pay Files
        </AgentNetButton>

        <PageHeader
          title="Invoice Summary"
          divider={false}
          disableMargin="y"
          className={classes.invoiceSummaryPageHeader}
          menuItems={[
            {
              label: 'Knowledge Base',
              link: KBLinks.reportPayPay,
            },
          ]}
          contentRight={
            orderData?.payment?.paymentId &&
            (orderData?.payment?.paymentMethod === 'Check' || orderData?.payment?.paymentMethod === 'Wire') ? (
              <AgentNetButton
                onClick={() => executeTransmittalSheet().then()}
                variant="outlined"
                color="primary"
                size="small"
                loading={executeTransmittalSheetStatus === 'pending'}
                disabled={executeTransmittalSheetStatus === 'pending'}
                startIcon={<PDFIcon htmlColor="primary" fontSize="large" />}
              >
                View Payment Transmittal
              </AgentNetButton>
            ) : null
          }
        />
      </div>
      {String(payFiles?.firmId) !== String(userFirm?.firmId) && (
        <Typography className={classes.firmText}>
          <b>Attention!</b> You&apos;re currently paying for files associated with{' '}
          <b>{getFirmNameByFirmId?.(payFiles.firmId)}</b>. Because of this, any ePay optiones you see in the dropdown
          menu will be associated with <b>{getFirmNameByFirmId?.(payFiles.firmId)}</b>, not{' '}
          <b>{getFirmNameByFirmId?.(userFirm?.firmId ?? '')}</b>.
        </Typography>
      )}
      <div className={classes.mainWrap}>
        <div className={classes.invoiceSummaryWrap}>
          <div className={classes.invoiceSummaryContent}>
            <InvoiceSummaryList
              orders={orderData?.orders}
              hasPayment={orderData?.payment}
              underwriter={Array.from(underwritersSet)?.[0] as string}
            />
          </div>
        </div>
        <div className={classes.paymentComponent}>
          <PaymentComponent
            totalNoOfFiles={totalNoOfFiles}
            underwriter={orderData?.orders?.[0]?.underwriter}
            amountDue={orderData?.payment?.amount ?? totalAmountDueOpenFiles}
            wireNumber={orderData?.payment?.wireNumber}
            checkNumber={orderData?.payment?.checkNumber}
            epayConfirmationNum={orderData?.payment?.ePayConfirmationNumber}
            isAmountPaid={isAmountPaid}
            email={
              orderData?.payment?.email ||
              (orderData?.payment?.paymentStatus === 'Closed' && !orderData?.payment?.paymentId
                ? ''
                : profile?.emailAddress)
            }
            memo={orderData?.payment?.memo}
            onSubmit={handleSubmitPayment}
            showValidation={showValidation}
            setShowValidation={setShowValidation}
            paymentType={orderData?.payment?.paymentMethod || paymentType}
            disableRadioOptions={disableRadioOptions}
            ePayAccounts={ePayAccounts}
            onChange={handleOnChangePaymentDetails}
            openPaymentDrawer={openPaymentDrawer}
            setOpenPaymentDrawer={setOpenPaymentDrawer}
            defaultEpayAccount={defaultEpayAccount}
            showLoader={executeSubmitPaymentDetailsStatus === 'pending'}
            disabled={disablePayment}
          />
        </div>
      </div>
      <FormDrawerComponent
        title="Add New Payment Method"
        width={386}
        open={openPaymentDrawer}
        disableEnforceFocus
        crossQAPrefix="PaymentMethodDrawer"
        isPaddingRightZero
        showDrawerActions={false}
        onDismissAction={closeDrawer}
      >
        <Grid item sm={12} className={classes.heading}>
          <AgentNetDivider typoVariant="h3" title={'Payment Method'} disablePadding />
        </Grid>
        <Grid item sm={12}>
          <RadioGroup
            disabled={paymentTypes?.length === 1}
            selected={selectedPaymentMode}
            options={paymentMode}
            onChange={(e) => handlePaymentMethod(e.target.value)}
          />
        </Grid>
        {executeframeDataStatus === 'pending' && <LoadingSpinner variant="circle" status={'pending'} />}
        {executeframeDataStatus === 'success' && (
          <>
            <Grid item sm={12}>
              <iframe
                src={`${frameDataResults.sessionTransferUrl}`}
                className={classes.iframe}
                name="myiFrame"
                scrolling="no"
                height="800px"
                width="370px"
              ></iframe>
            </Grid>
          </>
        )}
      </FormDrawerComponent>
    </div>
  );
};

export default React.memo(InvoiceSummary);
