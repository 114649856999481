import { IOutsideJurisdictionForFirms } from 'features/files/parties/PartyGroup/OutsideJurisdictionForFirms';
import { handleBusinessError, handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { IProfileResponse } from './interfaces';
import { parseProfile } from './profile-utilities';
import { Profile } from './types';

export const getProfile = async (token: string): Promise<Profile> => {
  const url = `${getApiHost()}user/user-profile`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  const profileResponse: IProfileResponse = json.result;
  return parseProfile(profileResponse);
};

export const getOutsideJurisdictionForFirms = async (
  firmId: string,
  token: string,
): Promise<IOutsideJurisdictionForFirms[]> => {
  const url = `${getApiHost()}firms/${firmId}/outside-jurisdictions`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  return json.result;
};

//Delete the serviceOrderEmailAddress
export async function deleteEmailNotification(userEmailNotificationId: number, token: string): Promise<boolean> {
  const url = `${getApiHost()}user/profile/service-order/notification/?userEmailNotificationId=${userEmailNotificationId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
