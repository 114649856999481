import { useAuth } from '@agentnet/auth';
import { extractRisks, FileOrderInsightsType, OrderInsightsError } from 'api/file/interfaces/get-file-order-insights';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { getFileInfo, getFileInfoProps, getFileOrderInsights } from '../api/file/file-api';
import { fileInfoType } from '../api/file/interfaces/get-file';
import { DefaultNewFile } from '../models/file-model';
import { fileSchema } from '../utilities/validation/schemas';
import { doValidate, FieldValidationError } from '../utilities/validation/validation';

export interface FileDataContextInterface {
  tabValue?: string;
  setTabValue?: (val: string) => void;
  activeTab?: string | null;
  setActiveTab?: (activeTab: string) => void;
  fileData?: fileInfoType | null;
  loadFileData?: (fileProps: getFileInfoProps, callback?: () => void) => void;
  setFileData?: (data: fileInfoType | null | any) => void;
  setCleanData?: (data: fileInfoType | null | undefined) => void;
  cleanData?: fileInfoType | null | undefined;
  fileOrderInsightsData?: FileOrderInsightsType | null;
  setFileOrderInsightsData?: (data: FileOrderInsightsType | null) => void;
  loadFileOrderInsightsData?: (fileId: string, immediate?: boolean) => void;
  isDiscoveringOrderInsights?: string;
  children?: React.ReactNode;
  validationErrors?: FieldValidationError[];
  setValidationErrors?: (errs: FieldValidationError[]) => void;
  openSaveModal?: boolean;
  setOpenSaveModal?: (val: boolean) => void;
  isFormDirty?: boolean;
  setIsFormDirty?: (val: boolean) => void;
  setLogoutAttempted?: (val: boolean) => void;
  logoutAttempted?: boolean;
  setRatesAndFeeDrawerClicked?: (val: boolean) => void;
  isRatesAndFeeDrawerClicked?: boolean;
  setOpenRatesAndFeeDrawer?: (val: boolean) => void;
  openRatesAndFeeDrawer?: boolean;
  openCreateDrawer?: boolean;
  setOpenCreateDrawer?: (val: boolean) => void;
  isCreateDrawerClicked?: boolean;
  setIsCreateDrawerClicked?: (val: boolean) => void;
  openPendingCpl?: number;
  setOpenPendingCpl?: (val: number) => void;
  resetCpl?: boolean;
  setResetCpl?: (val: boolean) => void;
  hasRemittableCpl?: boolean;
  setRemmittableCpl?: (val?: boolean) => void;
  fileDataUpdated?: boolean;
  setfileDataUpdated?: (val: boolean) => void;
  partyUpdated?: boolean;
  setPartyUpdated?: (val: boolean) => void;
  firmAnchorEl?: any;
  setFirmAnchorEl?: any;
  officeDisabled?: boolean;
  setOfficeDisabled?: (val: boolean) => void;
  underwriterDisabled?: boolean;
  setUnderwriterDisabled?: (val: boolean) => void;
  resetFileNoDisable?: boolean;
  setResetFileNoDisable?: (val: boolean) => void;
}
const FileDataContext = React.createContext<FileDataContextInterface | null>(null);
const FileDataProvider = (props: FileDataContextInterface): JSX.Element => {
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const [fileData, setFileData] = useState<fileInfoType>(DefaultNewFile);
  const [activeTab, setActiveTab] = useState<string>(sessionStorage.getItem('ActiveTab') ?? '');
  const [tabValue, setTabValue] = useState<string>(sessionStorage.getItem('tabValue') ?? '0');

  const [openPendingCpl, setOpenPendingCpl] = useState<number>();
  const [partyUpdated, setPartyUpdated] = useState<boolean>(false);
  const [resetCpl, setResetCpl] = useState<boolean>(false);
  const [isRatesAndFeeDrawerClicked, setRatesAndFeeDrawerClicked] = useState<boolean>(false);
  const [fileDataUpdated, setfileDataUpdated] = useState<boolean>(false);
  const [hasRemittableCpl, setRemmittableCpl] = useState<boolean>();
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  // The Edit and Nav Bar use these for handling the unsaved-changes modal
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [openCreateDrawer, setOpenCreateDrawer] = useState<boolean>(false);
  const [openRatesAndFeeDrawer, setOpenRatesAndFeeDrawer] = useState<boolean>(false);
  const [isCreateDrawerClicked, setIsCreateDrawerClicked] = useState<boolean>(false);

  const [logoutAttempted, setLogoutAttempted] = useState<boolean>(false);
  const [cleanData, handleSetCleanData] = useState<fileInfoType | null | undefined>();
  const [firmAnchorEl, setFirmAnchorEl] = React.useState<null | HTMLElement>(null);

  const [officeDisabled, setOfficeDisabled] = useState(true);
  const [underwriterDisabled, setUnderwriterDisabled] = useState(true);
  const [resetFileNoDisable, setResetFileNoDisable] = useState(false);

  const [fileOrderInsightsData, setFileOrderInsightsData] = useState<FileOrderInsightsType | null>(null);
  const [isDiscoveringOrderInsights, setDiscoveringOrderInsights] = React.useState<string>('');

  const setCleanData = (data: fileInfoType | null | undefined) => {
    /* It is very important to get a clean, deep clone here. */
    handleSetCleanData(_.cloneDeep(data));
  };

  const updateContext = async (fileInfo: fileInfoType | null) => {
    if (fileInfo) {
      /* format the closing date */
      fileInfo.closingDate = fileInfo.closingDate ? fileInfo.closingDate : null;

      setFileData(fileInfo);

      /* Do the clientside validation */
      const errs: FieldValidationError[] = await doValidate(fileInfo, fileSchema);
      setValidationErrors(errs);
    }
  };

  /** Use the fileId to get the file data */
  const loadFileData = async function (fileProps: getFileInfoProps, callback?: () => void) {
    const token = await getAccessToken();
    const fileApiData = await getFileInfo(fileProps?.fileId ?? '', token);
    setCleanData(fileApiData);
    await updateContext(fileApiData);
    callback?.();
  };

  /* load filedata when lost on refresh */
  useEffect(() => {
    if (!fileData.fileId) {
      const savedFileId = sessionStorage.getItem('fileId') ?? '';
      if (savedFileId) loadFileData && loadFileData({ fileId: savedFileId });
    }
  }, [fileData?.fileId]);

  useEffect(() => {
    window.sessionStorage.setItem('ActiveTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    window.sessionStorage.setItem('tabValue', tabValue);
  }, [tabValue]);

  //#region Order Insights
  //These functions work together to manage the process of retrieving
  //and updating order insights data for a file, handling both immediate
  //and delayed (polling) scenarios.

  //Fetches order insights data from an API using a file ID and token.
  const getOrderInsights = async function (fileId: string) {
    const token = await getAccessToken();
    return await getFileOrderInsights(fileId, token).catch(async () => {
      return OrderInsightsError;
    });
  };

  //Loads order insights data either immediately or by polling.
  const loadFileOrderInsightsData = async function (fileId: string, immediate = false) {
    setDiscoveringOrderInsights('pending');
    if (immediate) {
      const orderInsightsData = await getOrderInsights(fileId);
      setFileOrderInsightsData(orderInsightsData);
      if (orderInsightsData?.isGenerating === true) {
        if (isDiscoveringOrderInsights !== 'pending') {
          pollFileOrderInsightsData(fileId);
        }
      } else {
        setDiscoveringOrderInsights('');
      }
    } else {
      pollFileOrderInsightsData(fileId);
    }
  };

  //Polls for order insights data at incremented intervals with max 60 sec until the data is ready.
  const pollFileOrderInsightsData = function (fileId: string) {
    let waitTime = 10000; // Start with 10 seconds

    const intervalId = setInterval(async () => {
      const orderInsightsData = await getOrderInsights(fileId);
      if (orderInsightsData?.isGenerating === false) {
        if (fileData?.fileId === fileId) {
          setFileOrderInsightsData(orderInsightsData);
        }
        triggerOrderInsightsNotification(orderInsightsData);
        setDiscoveringOrderInsights('');
        clearInterval(intervalId);
      } else {
        if (waitTime < 60000) {
          waitTime = waitTime + 5000; // Increase wait time by 5 seconds, max 60 seconds
        } else {
          waitTime = 60000; // Cap at 60 seconds
        }
      }
    }, waitTime);
  };

  const triggerOrderInsightsNotification = (orderInsightsData: FileOrderInsightsType) => {
    if (orderInsightsData?.isGenerating === false) {
      const risks = [
        ...extractRisks(orderInsightsData?.parties || [], ['Alert']),
        ...extractRisks(orderInsightsData?.properties || [], ['Alert']),
      ];
      if (risks.length > 0) {
        addSnackbarMessage({
          title: `Potential Risk(s) Detected on File No: ${orderInsightsData?.fileNumber}`,
          html: true,
          message: 'View Order Insights to learn more.',
          type: 'warning',
          duration: 15000,
        });
      }
    }
  };

  //#endregion

  const ctx: FileDataContextInterface = {
    tabValue,
    setTabValue,
    activeTab,
    setActiveTab,
    openPendingCpl,
    setOpenPendingCpl,
    fileData,
    setFileData: updateContext,
    loadFileData,
    validationErrors,
    setValidationErrors,
    openSaveModal,
    setOpenSaveModal,
    isFormDirty,
    setIsFormDirty,
    cleanData,
    setCleanData,
    logoutAttempted,
    setLogoutAttempted,
    openCreateDrawer,
    setOpenCreateDrawer,
    isCreateDrawerClicked,
    setIsCreateDrawerClicked,
    setResetCpl,
    resetCpl,
    fileDataUpdated,
    setfileDataUpdated,
    hasRemittableCpl,
    setRemmittableCpl,
    partyUpdated,
    setPartyUpdated,
    firmAnchorEl,
    setFirmAnchorEl,
    officeDisabled,
    setOfficeDisabled,
    underwriterDisabled,
    setUnderwriterDisabled,
    resetFileNoDisable,
    setResetFileNoDisable,
    fileOrderInsightsData,
    loadFileOrderInsightsData,
    isDiscoveringOrderInsights,
    setRatesAndFeeDrawerClicked,
    isRatesAndFeeDrawerClicked,
    openRatesAndFeeDrawer,
    setOpenRatesAndFeeDrawer,
  };

  return <FileDataContext.Provider value={ctx}>{props.children}</FileDataContext.Provider>;
};

export { FileDataContext, FileDataProvider };
