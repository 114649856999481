import { useAuth } from '@agentnet/auth';
import { makeStyles } from '@material-ui/core/styles';
import { GetUserDetails, SaveUserDetails } from 'api/admin/idaas-api';
import { useUser } from 'hooks/SignUpContext';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
declare const window: any;

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1, flexDirection: 'column', display: 'flex', justifyContent: 'space-between' },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));
const IdaasSignUp = (): JSX.Element => {
  const classes = useStyles();
  const idaaSAuth = useAuth();
  const history = useHistory();
  const { setUserId, setPreviousPage, setLoginUserId } = useUser();
  const [userIdUrl, setUserIdUrl] = useState<string | null>(null);

  const executegetUserData = async (userId: string) => {
    return await GetUserDetails(userId);
  };
  useEffect(() => {
    try {
      const params = new URLSearchParams(location.search);
      const fetchedUserId = params.get('p');
      if (fetchedUserId) {
        setUserIdUrl(fetchedUserId);
      } else {
        setUserId(null);
        history.push('/authorization/userstatus/401');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  const clearWebStorage = () => {
    if (typeof window?.sessionStorage !== 'undefined') {
      window.sessionStorage.clear();
      window.localStorage.clear();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userIdUrl) {
        const userData: any = await executegetUserData(userIdUrl);
        if (userData?.result?.UserInfo) {
          if (
            userData.result.UserInfo.RoleTypeCdId == 100 &&
            userData.result.UserInfo.AzureObjectID == undefined &&
            userData.result.UserInfo.StatusTypeCdId == 8325
          ) {
            setUserId(userData.result.UserInfo.UserId);
            await ensureIdaaSAuthLoaded();
            const result: any = await idaaSAuth.signUp(userData);
            if (result) {
              clearWebStorage();
              const updatedDetails: any = {
                userId: userData.result.UserInfo.UserId,
                loginUserId: result.idTokenClaims.UserName,
                firstName: result.idTokenClaims.FirstName,
                middleName: result.idTokenClaims.MiddleName,
                lastName: result.idTokenClaims.LastName,
                email: result.idTokenClaims.Email,
                phoneNumber: result.idTokenClaims.PhoneAuthChallenge,
                azureId: result.idTokenClaims.AzureObjectID,
              };
              setLoginUserId(result.idTokenClaims.UserName);
              const isUserSaved = await SaveUserDetails(updatedDetails);
              setPreviousPage('/authentication/register');
              history.push('/authorization/eula');
            }
          } else {
            history.push('/authorization/userstatus/507');
          }
        } else {
          setUserId(null);
          history.push('/authorization/userstatus/401');
        }
      }
    };
    fetchData();
  }, [userIdUrl]);

  const ensureIdaaSAuthLoaded = async () => {
    return new Promise((resolve) => {
      const checkAuthLoaded = () => {
        if (idaaSAuth) {
          resolve(true);
        } else {
          setTimeout(checkAuthLoaded, 100);
        }
      };
      checkAuthLoaded();
    });
  };

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.loader}>
          <LoadingSpinner variant="circle" status={'pending'} />
        </div>
      </main>
    </div>
  );
};

export default IdaasSignUp;
