import { Box, Divider, IconButton, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Ellipsis from '@material-ui/icons/MoreVertOutlined';
import clsx from 'clsx';
import { FC, MouseEvent, ReactNode, useState } from 'react';

interface MenuItem {
  label: string;
  link?: string;
  onClick?: () => void;
}

interface PageHeaderProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  divider?: boolean;
  menuItems?: MenuItem[];
  id?: string;
  disableMargin?: 'x' | 'y' | 'both';
  className?: string;
  containerClass?: string;
  titleVariant?: 'h2' | 'h3' | 'h4';
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemBtn: {
      padding: '0 10px',
      '&:hover': {
        background: 'transparent',
      },
    },
    pageHeaderTitle: {
      textTransform: 'uppercase',
    },
    pageHeaderTitleVariant: {
      textTransform: 'none',
    },
    subtitle: {
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover, &:visited': {
          color: theme.palette.primary.dark,
        },
      },
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  contentLeft,
  contentRight,
  divider = true,
  menuItems = [],
  id,
  disableMargin,
  className,
  containerClass,
  titleVariant,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={containerClass && containerClass}>
      <Box
        mx={disableMargin && (disableMargin === 'x' || disableMargin === 'both') ? 0 : 3}
        my={disableMargin && (disableMargin === 'y' || disableMargin === 'both') ? 0 : 3}
        className={className && className}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={titleVariant ? 0 : 1}>
          <Box display="flex">
            {title && (
              <Typography
                variant={titleVariant ? titleVariant : 'h1'}
                component={titleVariant ? titleVariant : 'h1'}
                id={id}
                className={clsx(classes.pageHeaderTitle, { [classes.pageHeaderTitleVariant]: titleVariant })}
              >
                {title}
              </Typography>
            )}
            <Box display="flex">
              {menuItems.length > 0 && (
                <>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.menuItemBtn}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    color="secondary"
                  >
                    <Ellipsis fontSize="large" />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  >
                    {menuItems.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={item.onClick ? item.onClick : () => window.open(item.link, '_blank')}
                      >
                        <Typography variant="body1">{item.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              {contentLeft && <Box className={classes.flexCenter}>{contentLeft}</Box>}
            </Box>
          </Box>
          {contentRight && <Box>{contentRight}</Box>}
        </Box>
        {subtitle && (
          <Typography variant="body1" color="textSecondary" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {divider && (
        <Box marginX={disableMargin === 'x' || disableMargin === 'both' ? 0 : 3} marginBottom={3}>
          <Divider />
        </Box>
      )}
    </div>
  );
};

export default PageHeader;
