import { AccountInfoType, SelectedFilesAmountType } from 'features/orderManagement/types';
import React, { createContext, useContext, useState } from 'react';

interface OrderInfoContextType {
  selectedFilesAmount: SelectedFilesAmountType;
  setSelectedFilesAmount: React.Dispatch<React.SetStateAction<SelectedFilesAmountType>>;
  accountInfoList: Array<AccountInfoType>;
  setAccountInfoList: React.Dispatch<React.SetStateAction<Array<AccountInfoType>>>;
}

const defaultSelectedFilesAmount: SelectedFilesAmountType = {
  calculatedGross: 0,
  calculatedNet: 0,
  netDifference: 0,
};

const OrderInfoContext = createContext<OrderInfoContextType | null>(null);

export const OrderInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedFilesAmount, setSelectedFilesAmount] = useState<SelectedFilesAmountType>(defaultSelectedFilesAmount);
  const [accountInfoList, setAccountInfoList] = useState<Array<AccountInfoType>>([]);

  return (
    <OrderInfoContext.Provider
      value={{ selectedFilesAmount, setSelectedFilesAmount, accountInfoList, setAccountInfoList }}
    >
      {children}
    </OrderInfoContext.Provider>
  );
};

export const useOrderInfo = (): OrderInfoContextType => {
  const context = useContext(OrderInfoContext);
  if (!context) {
    throw new Error('useOrderInfo must be used within an OrderInfoProvider');
  }
  return context;
};
